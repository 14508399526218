import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { RouterOutlet } from '@angular/router';
import config from 'devextreme/core/config';
import { environment } from 'src/environments/environment.development';
import { ConfigService } from './core/services/config/config.service';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent {
  constructor(
    private titleService: Title,
    private configService: ConfigService
  ) {
    this.titleService.setTitle(environment.title);

    // DevExpress license configuration
    config({
      licenseKey: this.configService.config.devExpressConfiguration.licenseKey
    });
  }
}

import { Color } from 'src/app/core/models/colors';

export const providerResourceColors: Color[] = [
  {
    name: 'Blue',
    hexCode: '#3B2883',
    id: 1
  },
  {
    name: 'Black',
    hexCode: '#000000',
    id: 2
  },
  {
    name: 'Brown',
    hexCode: '#553109',
    id: 3
  },
  {
    name: 'Gray',
    hexCode: '#4F4E54',
    id: 4
  },
  {
    name: 'Green',
    hexCode: '#01602F',
    id: 5
  },
  {
    name: 'Magenta',
    hexCode: '#A8005A',
    id: 6
  },
  {
    name: 'Mauve',
    hexCode: '#78244C',
    id: 7
  },
  {
    name: 'Navy Blue',
    hexCode: '#152A56',
    id: 8
  },
  {
    name: 'Orange',
    hexCode: '#86481B',
    id: 9
  },
  {
    name: 'Purple',
    hexCode: '#57116A',
    id: 10
  },
  {
    name: 'Red',
    hexCode: '#A41034',
    id: 11
  },
  {
    name: 'Teal',
    hexCode: '#005E85',
    id: 12
  }
];

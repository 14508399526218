import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SortDirection } from 'src/app/core/enums/sort-direction';
import { DataResponse } from 'src/app/core/models/data-response';
import { DataSort } from 'src/app/core/models/data-sort';
import { SearchParameters } from 'src/app/core/models/search-parameters';
import { UtilityService } from 'src/app/core/services/utility/utility.service';
import { User } from 'src/app/user/models/user';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UsersService {
  constructor(private utilityService: UtilityService) {}

  endpoint = environment.usersApiUrl + '/Users';

  postSearchUsers(
    pageNumber: number,
    sortDirection: SortDirection,
    sortBy: DataSort,
    pageSize: number | null = null,
    searchTerm: string | null,
    isActive: boolean
  ): Observable<DataResponse<User[]>> {
    const url = this.endpoint + '/Search';
    const searchParams: any = new SearchParameters(pageNumber, sortDirection, sortBy, pageSize, searchTerm, isActive);
    return this.utilityService.postRequest<DataResponse<User[]>>(url, searchParams, false, false, true);
  }
}

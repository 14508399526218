import { HTTP_INTERCEPTORS, HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { APP_INITIALIZER, ApplicationConfig, importProvidersFrom, isDevMode } from '@angular/core';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { provideRouter } from '@angular/router';
import { provideEffects } from '@ngrx/effects';
import { provideRouterStore, routerReducer } from '@ngrx/router-store';
import { provideState, provideStore } from '@ngrx/store';
import { provideStoreDevtools } from '@ngrx/store-devtools';
import { LoggerModule, NgxLoggerLevel } from 'ngx-logger';
import { provideToastr } from 'ngx-toastr';
import { routes } from './app.routes';
import { AppointmentTypeEffects } from './appointment-types/store/effects/appointment-types.effects';
import { appointmentTypeReducer } from './appointment-types/store/reducer/appointment-types.reducer';
import { CustomRouterStateSerializer } from './core/router/router-state-serializer';
import { ConfigService } from './core/services/config/config.service';
import { ErrorInterceptor } from './core/services/http-interceptor/http-interceptor.service';
import { AppEffects } from './core/store/effects/app.effects';
import { appReducer } from './core/store/reducer/app.reducer';
import { LocationsEffects } from './locations/store/effects/locations.effects';
import { locationsReducer } from './locations/store/reducer/locations.reducer';
import { NonProviderResourcesEffects } from './non-provider-resources/store/effects/non-provider-resources.effects';
import { nonProviderResourcesReducer } from './non-provider-resources/store/reducer/non-provider-resources.reducer';
import { PersonsEffects } from './persons/store/effects/persons.effects';
import { personsReducer } from './persons/store/reducer/persons.reducer';
import { ProvidersEffects } from './provider-resources/store/effects/provider-resources.effects';
import { providerResourcesReducer } from './provider-resources/store/reducer/provider-resources.reducer';
import { SharedEffects } from './shared/store/effects/shared.effects';
import { sharedReducer } from './shared/store/reducer/shared.reducer';
import { userReducer } from './user/store/reducer/user.reducer';
import { overrideTemplatesReducer } from './override-templates/store/reducer/override-templates.reducer';
import { OverrideTemplatesEffects } from './override-templates/store/effects/override-templates.effects';
import { usersReducer } from './users/store/reducer/users.reducer';
import { UsersEffects } from './users/store/effects/users.effects';

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes),
    importProvidersFrom(LoggerModule.forRoot({ level: NgxLoggerLevel.DEBUG })),
    provideHttpClient(withInterceptorsFromDi()),
    provideStore({ router: routerReducer }),
    provideRouterStore({ serializer: CustomRouterStateSerializer }),
    provideState({ name: 'app', reducer: appReducer }),
    provideState({ name: 'appointment-types', reducer: appointmentTypeReducer }),
    provideState({ name: 'locations', reducer: locationsReducer }),
    provideState({ name: 'non-provider-resources', reducer: nonProviderResourcesReducer }),
    provideState({ name: 'shared', reducer: sharedReducer }),
    provideState({ name: 'user', reducer: userReducer }),
    provideState({ name: 'provider-resources', reducer: providerResourcesReducer }),
    provideState({ name: 'persons', reducer: personsReducer }),
    provideState({ name: 'override-templates', reducer: overrideTemplatesReducer }),
    provideState({ name: 'users', reducer: usersReducer }),
    provideEffects([
      AppEffects,
      AppointmentTypeEffects,
      LocationsEffects,
      NonProviderResourcesEffects,
      SharedEffects,
      ProvidersEffects,
      PersonsEffects,
      OverrideTemplatesEffects,
      UsersEffects
    ]),
    provideStoreDevtools({
      maxAge: 25, // Retains last 25 states
      logOnly: !isDevMode(), // Restrict extension to log-only mode
      autoPause: true, // Pauses recording actions and state changes when the extension window is not open
      trace: false, //  If set to true, will include stack trace for every dispatched action, so you can see it in trace tab jumping directly to that part of code
      traceLimit: 75, // maximum stack trace frames to be stored (in case trace option was provided as true)
      connectInZone: true // If set to true, the connection is established within the Angular zone
    }),
    provideAnimationsAsync(),
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    {
      provide: APP_INITIALIZER,
      multi: true,
      deps: [ConfigService, HttpClient],
      useFactory: (configService: ConfigService) => () => configService.loadConfig()
    },
    provideToastr({
      timeOut: 2500,
      positionClass: 'toast-bottom-center'
    })
  ]
};

<div class="title">
  <mat-icon fontIcon="warning" color="warn" class="material-symbols-outlined icon"></mat-icon>
  <h2 class="p-0">Warning</h2>
</div>
<mat-dialog-content>
  <div id="areYouSure">Are you sure you want to inactivate this {{ data.type }}?</div>
  <ul id="validationMessages" *ngFor="let msg of data.validationMessages">
    <li id="msg">{{ msg }}</li>
  </ul>
</mat-dialog-content>
<mat-dialog-actions align="end" class="mb-20 mr-20">
  <button id="btnCancel" mat-stroked-button color="warn" class="btn warn-button mr-0" [mat-dialog-close]="false">
    Cancel
  </button>
  <button id="btnConfirm" mat-raised-button color="primary" class="btn primary-solid-button" [mat-dialog-close]="true">
    Confirm
  </button>
</mat-dialog-actions>

<ng-container>
  <app-progress-spinner
    id="spinnerAddProviderResource"
    *ngIf="providers === null || locations === null || modifiedByUserId === null; else addProviderResourceForm">
  </app-progress-spinner>

  <ng-template #addProviderResourceForm>
    <div id="form" class="form-container">
      <form [formGroup]="form" (submit)="save()" class="mt-30">
        @if (error !== null && isSaving === false) {
          <div id="saveError" class="ml-20 mr-20 mb-20 mt-15 flex justify-center">
            <p>{{ error[0] }}</p>
          </div>
        }
        <div class="mr-20 ml-20 mt-20 flex gap-20" id="form-container">
          <div class="flex flex-col gap-20 flex-2">
            <mat-form-field class="flex" appearance="outline" floatLabel="always">
              <mat-label>Provider</mat-label>
              <mat-select formControlName="provider" data-test="selProviders">
                @for (provider of providers; track provider) {
                  <mat-option [value]="provider.personId">{{ provider.displayName }}</mat-option>
                }
              </mat-select>
              @if (form.controls['provider'].hasError('required')) {
                <mat-error id="providerRequired">Provider required</mat-error>
              }
            </mat-form-field>

            <mat-form-field class="flex" appearance="outline" floatLabel="always">
              <mat-label>Short Name</mat-label>
              <input matInput id="shortName" formControlName="shortName" data-test="iptShortName" />
              @if (form.controls['shortName'].hasError('required')) {
                <mat-error id="shortNameRequired">Short name required</mat-error>
              } @else if (form.controls['shortName'].hasError('maxlength')) {
                <mat-error id="shortNameMax">Short name has a character limit of {{ maxShortNameLength }}</mat-error>
              } @else if (form.controls['shortName'].hasError('duplicate')) {
                <mat-error id="shortNameDuplicateError">Short name already exists</mat-error>
              }
            </mat-form-field>

            <div class="flex flex-col">
              <app-multiselect-autocomplete
                [multiSelectLabel]="'Locations'"
                [isMultiSelectRequired]="true"
                key="options"
                [data]="locations"
                [selectedData]="selectedLocations"
                (multiSelectResult)="onMultiSelectChange($event)">
              </app-multiselect-autocomplete>
              @if (form.controls['location'].touched && form.controls['location'].hasError('required')) {
                <mat-error id="locationRequired" class="fs-14">Location required</mat-error>
              }
            </div>
          </div>

          <div class="flex flex-col gap-20 flex-1">
            <mat-form-field class="flex" appearance="outline" floatLabel="always">
              <mat-label>Color</mat-label>
              <mat-select hideSingleSelectionIndicator formControlName="color" data-test="HexColorCode">
                <mat-select-trigger>
                  <div class="flex justify-between">
                    {{ form.controls['color'].value?.name }}
                    <div
                      class="color-pill ml-15 mr-10"
                      [style]="'background-color: ' + form.controls['color'].value?.hexCode"></div>
                  </div>
                </mat-select-trigger>
                @for (color of providerResourceColors; track color) {
                  <mat-option [value]="color">
                    <div class="flex justify-start">
                      <div class="w-20x h-20x mr-8" [style]="'background-color: ' + color.hexCode"></div>
                      {{ color.name }}
                    </div>
                  </mat-option>
                }
              </mat-select>
              @if (form.controls['color'].hasError('required')) {
                <mat-error id="colorRequired">Color required</mat-error>
              }
            </mat-form-field>

            <div formGroupName="activeDates">
              <mat-form-field class="flex date-input" appearance="outline" floatLabel="always">
                <mat-label>Active Date</mat-label>
                <input
                  matInput
                  [matDatepicker]="picker"
                  id="activeDate"
                  formControlName="startDate"
                  data-test="dteActivateOn"
                  [min]="today" />
                <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
                @if (form.controls['activeDates'].get('startDate')?.hasError('required')) {
                  <mat-error id="activeRequiredError">Active date is required</mat-error>
                } @else if (form.controls['activeDates'].get('startDate')?.hasError('matDatepickerMin')) {
                  <mat-error id="activeDateInvalid">Date cannot be in the past</mat-error>
                }
              </mat-form-field>
            </div>

            <div formGroupName="activeDates">
              <mat-form-field class="flex date-input" appearance="outline" floatLabel="always">
                <mat-label>Inactive Date</mat-label>
                <input
                  matInput
                  [matDatepicker]="inactivePicker"
                  #inactiveDate
                  id="inactiveDate"
                  formControlName="endDate"
                  data-test="dteDeactivateOn"
                  [min]="minInactiveDate()" />
                <mat-datepicker-toggle matIconSuffix [for]="inactivePicker"></mat-datepicker-toggle>
                <mat-datepicker #inactivePicker></mat-datepicker>
                @if (form.controls['activeDates'].get('endDate')?.hasError('matDatepickerMin')) {
                  <mat-error id="invalidInactiveDateError"
                    >Date must be on or after {{ minInactiveDate() | date: 'M/d/yyyy' }}</mat-error
                  >
                }
              </mat-form-field>
            </div>

            <div class="btn-container flex justify-end align-center">
              <div>
                <button
                  class="btn warn-button"
                  id="btnCancel"
                  color="warn"
                  mat-stroked-button
                  type="button"
                  data-test="btnCancel"
                  (click)="cancelAddProviderResourceEvent.emit()">
                  Cancel
                </button>
              </div>

              <div class="ml-10">
                <button
                  class="btn primary-solid-button"
                  id="btnSubmit"
                  color="primary"
                  mat-raised-button
                  type="submit"
                  data-test="btnSave"
                  [disabled]="isSaving">
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>

    <app-progress-spinner-overlay
      id="spinnerOverlayAddProviderResource"
      [displayProgressSpinner]="isSaving"
      data-test="spinnerAddProviderResource"></app-progress-spinner-overlay>
  </ng-template>
</ng-container>

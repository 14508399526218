import { createAction, props } from '@ngrx/store';
import { DataSort } from 'src/app/core/models/data-sort';
import { User } from 'src/app/user/models/user';

export const getUsers = createAction('[Users] Get Users');

export const getUsersSuccess = createAction(
  '[Users] Get Users Success',
  props<{
    users: User[];
    pageNumber: number;
    isAtEndOfData: boolean;
    totalRecordCount: number;
  }>()
);

export const getUsersFailure = createAction('[Users] Get Users Failure', props<{ error: string[] }>());

export const getUsersSearch = createAction('[Users] Get Users Search', props<{ searchTerm: any }>());

export const setPageNumber = createAction('[Users] Set Page Number', props<{ pageNumber: number }>());

export const setPageSize = createAction('[Users] Set Page Size', props<{ pageSize: number }>());

export const setSortDirection = createAction('[Users] Set Sort Direction', props<{ sortDirection: number }>());

export const setSortBy = createAction('[Users] Set Sort By', props<{ sortBy: DataSort }>());

export const setIsActive = createAction('[Users] Set Is Active', props<{ isActive: boolean }>());

export const navigateToAddUser = createAction('[Users] Navigate to Add User');

export const navigateToUsersGrid = createAction('[Users] Navigate to Users Grid');

export const resetFilters = createAction('[Users] Reset Filters');

<ng-container
  *ngrxLet="{
    locations: locations$,
    modifiedByUserId: modifiedByUserId$,
    nonProviderResourceTypes: nonProviderResourceTypes$,
    error: saveError$,
    selectedNonProviderResource: selectedNonProviderResource$,
    isLoading: isLoading$
  } as vm">
  <app-progress-spinner
    *ngIf="
      vm.locations === [] ||
        vm.nonProviderResourceTypes === null ||
        vm.modifiedByUserId === null ||
        vm.selectedNonProviderResource === null ||
        vm.selectedNonProviderResource === undefined;
      else updateNonProviderResourceForm
    "></app-progress-spinner>

  <ng-template #updateNonProviderResourceForm>
    <div class="page-title">
      <h3 class="text-center flex-2">Edit {{ vm.selectedNonProviderResource.name }}</h3>
    </div>

    <app-edit-resource-form
      [saveError]="vm.error"
      [nonProviderResourceTypes]="vm.nonProviderResourceTypes"
      [locations]="vm.locations"
      [modifiedByUserId]="vm.modifiedByUserId"
      [nonProviderResourceToUpdate]="vm.selectedNonProviderResource"
      [isLoading]="vm.isLoading"
      (closeEditNonProviderResourceEvent)="cancel()"
      (saveNonProviderResourceEvent)="updateNonProviderResource($event)"
      (checkInactivationEvent)="checkInactivation($event)"></app-edit-resource-form>
  </ng-template>
</ng-container>

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SortDirection } from 'src/app/core/enums/sort-direction';
import { DataResponse } from 'src/app/core/models/data-response';
import { DataSort } from 'src/app/core/models/data-sort';
import { SearchParameters } from 'src/app/core/models/search-parameters';
import { UtilityService } from 'src/app/core/services/utility/utility.service';
import { environment } from 'src/environments/environment';
import { NonProviderResource } from '../../models/non-provider-resource';
import { NonProviderResourceInactivationCheck } from '../../models/non-provider-resource.inactivation-check';

@Injectable({
  providedIn: 'root'
})
export class NonProviderResourcesService {
  constructor(private utilityService: UtilityService) {}

  nonProviderResourceEndpoint = environment.scheduleApiUrl + '/NonProviderResources';

  postSearchNonProviderResources(
    pageNumber: number,
    sortDirection: SortDirection,
    sortBy: DataSort,
    pageSize: number | null = null,
    searchTerm: string | null,
    isActive: boolean
  ): Observable<DataResponse<NonProviderResource[]>> {
    const url = this.nonProviderResourceEndpoint + '/Search';
    const searchParams: any = new SearchParameters(pageNumber, sortDirection, sortBy, pageSize, searchTerm, isActive);
    return this.utilityService.postRequest<DataResponse<NonProviderResource[]>>(url, searchParams, false, false, true);
  }

  addNonProviderResource(resource: NonProviderResource): Observable<NonProviderResource> {
    return this.utilityService.postRequest<NonProviderResource>(this.nonProviderResourceEndpoint, resource);
  }

  updateNonProviderResource(resource: NonProviderResource): Observable<NonProviderResource> {
    const url = this.nonProviderResourceEndpoint + '/' + resource.nonProviderResourceId;
    return this.utilityService.putRequest<NonProviderResource>(url, resource);
  }

  getNonProviderResourceById(nonProviderResourceId: number): Observable<NonProviderResource> {
    const url = this.nonProviderResourceEndpoint + '/' + nonProviderResourceId;
    return this.utilityService.getRequest<NonProviderResource>(url);
  }

  getInactivationCheck(id: number, deactivateOn: string): Observable<NonProviderResourceInactivationCheck> {
    const url = this.nonProviderResourceEndpoint + '/GetInactivationCheck';
    const params = { nonProviderResourceId: id, deactivateOn: deactivateOn };
    return this.utilityService.postRequest<NonProviderResourceInactivationCheck>(url, params);
  }
}

import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import * as baseState from 'src/app/core/models/base-state';
import { Person } from 'src/app/provider-resources/models/person';
import * as personsActions from '../actions/persons.actions';

export interface PersonsState extends EntityState<Person> {
  baseState: baseState.BaseState;
}

export const personsAdapter = createEntityAdapter<Person>({
  selectId: person => person.personId
});

export const initialState = personsAdapter.getInitialState({
  baseState: new baseState.BaseState(null)
});

export const personsFeatureKey = 'persons';

export const personsReducer = createReducer(
  initialState,

  on(personsActions.getPersons, state => {
    return {
      ...state,
      baseState: baseState.setGet(state.baseState, false)
    };
  }),

  on(personsActions.getPersons, state => personsAdapter.removeAll(state)),

  on(personsActions.getPersonsSuccess, (state, { persons }) => personsAdapter.addMany(persons, state)),

  on(personsActions.getPersonsSuccess, state => {
    return {
      ...state,
      baseState: baseState.setIsLoading(state.baseState, false)
    };
  }),

  on(personsActions.getPersonsFailure, (state, { error }) => {
    return {
      ...state,
      baseState: baseState.setError(state.baseState, error)
    };
  })
);

import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Sort } from '@angular/material/sort';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { SortDirection } from 'src/app/core/enums/sort-direction';
import { DataSort } from 'src/app/core/models/data-sort';
import { nonProviderResourceSort } from '../../data/non-provider-resource-sort';
import { NonProviderResource } from '../../models/non-provider-resource';
import {
  getNonProviderResources,
  getNonProviderResourcesSearch,
  navigateToAddNonProviderResource,
  navigateToUpdateNonProviderResource,
  resetFilters,
  setIsActive,
  setPageNumber,
  setPageSize,
  setSortBy,
  setSortDirection
} from '../../store/actions/non-provider-resources.actions';
import * as selectors from '../../store/selectors/non-provider-resources.selectors';
import { NonProviderResourcesFilterBarComponent } from '../../ui/non-provider-resources-filter-bar/non-provider-resources-filter-bar.component';
import { NonProviderResourcesGridComponent } from '../../ui/non-provider-resources-grid/non-provider-resources-grid.component';

@Component({
  selector: 'app-non-provider-resources',
  standalone: true,
  imports: [NonProviderResourcesGridComponent, NonProviderResourcesFilterBarComponent, CommonModule],
  templateUrl: './non-provider-resources.component.html',
  styleUrl: './non-provider-resources.component.scss'
})
export class NonProviderResourcesComponent implements OnDestroy, OnInit {
  nonProviderResources$: Observable<NonProviderResource[] | null>;
  isLoading$: Observable<boolean>;
  error$: Observable<any>;
  isAtEndOfData$: Observable<boolean>;
  sortDirection$: Observable<SortDirection>;
  sortBy$: Observable<DataSort>;
  totalRecordCount$: Observable<number>;
  currentPage$: Observable<number>;
  pageSize$: Observable<number>;
  searchTerm$: Observable<string>;
  isActive$: Observable<boolean | null>;
  isEditing: boolean = false;

  constructor(private store: Store) {
    this.nonProviderResources$ = this.store.select(selectors.selectNonProviderResources);
    this.isLoading$ = this.store.select(selectors.selectIsLoading);
    this.error$ = this.store.select(selectors.selectError);
    this.isAtEndOfData$ = this.store.select(selectors.selectIsAtEndOfData);
    this.sortDirection$ = this.store.select(selectors.selectSortDirection);
    this.sortBy$ = this.store.select(selectors.selectSortBy);
    this.totalRecordCount$ = this.store.select(selectors.selectTotalRecordCount);
    this.currentPage$ = this.store.select(selectors.selectPageNumber);
    this.pageSize$ = this.store.select(selectors.selectPageSize);
    this.searchTerm$ = this.store.select(selectors.selectSearchTerm);
    this.isActive$ = this.store.select(selectors.selectIsActive);
  }

  ngOnInit(): void {
    this.store.dispatch(getNonProviderResources());
  }

  onPage(pageNumber: number): void {
    this.store.dispatch(setPageNumber({ pageNumber }));
  }

  onPageSize = (pageSize: number) => this.store.dispatch(setPageSize({ pageSize }));

  onSearch(searchTerm: string | null): void {
    this.store.dispatch(getNonProviderResourcesSearch({ searchTerm }));
  }

  onActiveFiltersChange(isActive: boolean): void {
    this.store.dispatch(setIsActive({ isActive }));
  }

  onSort(sort: Sort): void {
    this.store.dispatch(
      setSortDirection({
        sortDirection: sort.direction === 'desc' ? SortDirection.Descending : SortDirection.Ascending
      })
    );

    let dataSort: DataSort | undefined = nonProviderResourceSort.find(f => f.value === sort.active);
    if (dataSort === undefined) {
      dataSort = nonProviderResourceSort.find(f => f.isDefault);
      this.store.dispatch(setSortBy({ sortBy: dataSort! }));
    } else {
      this.store.dispatch(setSortBy({ sortBy: dataSort }));
    }
  }

  addNonProviderResource() {
    this.isEditing = true;
    this.store.dispatch(navigateToAddNonProviderResource());
  }

  updateNonProviderResource(nonProviderResourceId: number) {
    this.isEditing = true;
    this.store.dispatch(navigateToUpdateNonProviderResource({ nonProviderResourceId }));
  }

  ngOnDestroy(): void {
    if (!this.isEditing) this.store.dispatch(resetFilters());
  }
}

import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { LetDirective } from '@ngrx/component';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { getAppointmentTypes } from 'src/app/appointment-types/store/actions/appointment-types.actions';
import { selectAppointmentTypeQuickPicks } from 'src/app/appointment-types/store/selector/appointment-types.selector';
import { ProgressSpinnerComponent } from 'src/app/shared/component/progress-spinner/progress-spinner.component';
import { MultiSelectAutoCompleteItem } from 'src/app/shared/models/multiselect-autocomplete-item';
import { UsState } from 'src/app/shared/models/state';
import { getUsStates } from 'src/app/shared/store/actions/shared.actions';
import { selectUsStates } from 'src/app/shared/store/selector/shared.selector';
import { selectUserId } from 'src/app/user/store/selectors/user.selectors';
import { ApptLocation } from '../../models/location';
import { addLocation, navigateToLocationsGrid } from '../../store/actions/locations.actions';
import { selectError } from '../../store/selectors/locations.selectors';
import { AddLocationFormComponent } from '../../ui/add-location-form/add-location-form.component';

@Component({
  selector: 'app-add-location',
  standalone: true,
  imports: [AddLocationFormComponent, CommonModule, ProgressSpinnerComponent, LetDirective],
  templateUrl: './add-location.component.html',
  styleUrl: './add-location.component.scss'
})
export class AddLocationComponent implements OnInit {
  error$: Observable<string[] | null>;
  usStates$: Observable<UsState[] | null>;
  appointmentTypeQuickPicks$: Observable<MultiSelectAutoCompleteItem[]>;
  modifiedByUserId$: Observable<number | null>;

  constructor(private store: Store) {
    this.error$ = this.store.select(selectError);
    this.usStates$ = this.store.select(selectUsStates);
    this.appointmentTypeQuickPicks$ = this.store.select(selectAppointmentTypeQuickPicks);
    this.modifiedByUserId$ = this.store.select(selectUserId);
  }

  ngOnInit(): void {
    this.store.dispatch(getUsStates());
    this.store.dispatch(getAppointmentTypes());
  }

  addLocation(location: ApptLocation) {
    this.store.dispatch(addLocation({ location }));
  }

  cancel() {
    this.store.dispatch(navigateToLocationsGrid());
  }
}

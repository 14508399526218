import { NgxLoggerLevel } from 'ngx-logger';

export const environment = {
  title: 'Patient Intake Administrator Portal',
  production: false,
  ngxLogger: {
    serverLoggingLevel: NgxLoggerLevel.DEBUG,
    serverLoggingUrl: 'api/log',
    disableConsoleLogging: false,
    consoleLogLevel: NgxLoggerLevel.INFO
  },
  adminPortalApi: 'https://medone-patientintake-administratorportalapi-eastus-dev.azurewebsites.net/api/v1',
  loginApiUrl: 'https://medone-patientintake-loginapi-eastus-dev.azurewebsites.net/api/v1',
  loginUrl: 'https://patientintake-login-dev.medonesystems.com/login',
  scheduleApiUrl: 'https://medone-patientintake-scheduleapi-eastus-dev.azurewebsites.net/api/v1',
  usersApiUrl: 'https://medone-patientintake-userapi-eastus-dev.azurewebsites.net/api/v1',
  pageSize: 10,
  errorMessage: 'Oops something went wrong!',
  noDataMessage: 'No results found',
  noDataFiltersMessage: 'No results found matching the filters'
};

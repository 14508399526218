import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UtilityService } from 'src/app/core/services/utility/utility.service';
import { environment } from 'src/environments/environment';
import { ProviderResourceLocation } from '../../models/provider-resource-location';
import { ProviderResourceLocationRequest } from '../../models/provider-resource-location-request';

@Injectable({
  providedIn: 'root'
})
export class ProviderResourceLocationsService {
  endpoint = environment.scheduleApiUrl + '/ProviderResourceLocations';

  constructor(private utilityService: UtilityService) {}

  postProviderResourceLocations(request: ProviderResourceLocationRequest): Observable<ProviderResourceLocation[]> {
    return this.utilityService.postRequest<ProviderResourceLocation[]>(this.endpoint, request);
  }

  getProviderResourceLocationsByProviderResourceId(providerResourceId: number): Observable<ProviderResourceLocation[]> {
    const url = `${this.endpoint}?providerResourceId=${providerResourceId}`;
    return this.utilityService.getRequest<ProviderResourceLocation[]>(url);
  }
}

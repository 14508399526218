import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import moment from 'moment';

export const timeRangeValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
  control.get('startTime')?.setErrors(null);
  control.get('endTime')?.setErrors(null);

  const startTime = control.get('startTime')?.value;
  const endTime = control.get('endTime')?.value;

  const start = startTime === null ? null : moment(startTime).format('HH:mm');
  const end = endTime === null ? null : moment(endTime).format('HH:mm');

  if (startTime === null && endTime !== null) {
    control.get('startTime')?.setErrors({ required: true });
    return null;
  } else if (endTime === null && startTime !== null) {
    control.get('endTime')?.setErrors({ required: true });
    return null;
  } else if ((start === null && end === null) || (start === '00:00' && end === '00:00') || start < end) {
    return null;
  } else {
    control.get('startTime')?.setErrors({ invalid: true });
    control.get('endTime')?.setErrors({ invalid: true });

    return { invalidRange: true };
  }
};

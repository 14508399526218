import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import * as baseState from 'src/app/core/models/base-state';
import { overrideTemplateSort } from '../../data/override-templates-sort';
import { OverrideTemplate } from '../../models/override-template';
import { Resource } from '../../models/resource';
import * as overrideTemplatesActions from '../actions/override-templates.actions';

export interface OverrideTemplatesState extends EntityState<OverrideTemplate> {
  baseState: baseState.BaseState;
  selectedId: number | null;
  selectedOverrideTemplateTypeId: number | null;
  resources: Resource[] | null;
  allOverrideTemplates: OverrideTemplate[] | null;
}

export const overrideTemplatesAdapter = createEntityAdapter<OverrideTemplate>({
  selectId: overrideTemplate => overrideTemplate.overrideTemplateId
});

export const initialState = overrideTemplatesAdapter.getInitialState({
  baseState: new baseState.BaseState(overrideTemplateSort[0]),
  selectedId: null,
  selectedOverrideTemplateTypeId: null,
  resources: null,
  allOverrideTemplates: null
});

export const overrideTemplatesFeatureKey = 'override-templates';

export const overrideTemplatesReducer = createReducer(
  initialState,

  on(overrideTemplatesActions.getOverrideTemplates, state => {
    return {
      ...state,
      baseState: baseState.setGet(state.baseState, false)
    };
  }),

  on(overrideTemplatesActions.getOverrideTemplatesSuccess, (state, { overrideTemplates }) =>
    overrideTemplatesAdapter.addMany(overrideTemplates, state)
  ),

  on(overrideTemplatesActions.getOverrideTemplatesSuccess, (state, { pageNumber, isAtEndOfData, totalRecordCount }) => {
    return {
      ...state,
      baseState: baseState.setGetSuccess(state.baseState, pageNumber, isAtEndOfData, totalRecordCount)
    };
  }),

  on(overrideTemplatesActions.getOverrideTemplatesFailure, (state, { error }) => {
    return {
      ...state,
      baseState: baseState.setError(state.baseState, error)
    };
  }),

  on(
    overrideTemplatesActions.getOverrideTemplates,
    overrideTemplatesActions.setPageSize,
    overrideTemplatesActions.setPageNumber,
    overrideTemplatesActions.getOverrideTemplatesSearch,
    overrideTemplatesActions.setSortBy,
    overrideTemplatesActions.setSortDirection,
    overrideTemplatesActions.setIsActive,
    state => overrideTemplatesAdapter.removeAll(state)
  ),

  on(overrideTemplatesActions.setPageNumber, (state, { pageNumber }) => {
    return {
      ...state,
      baseState: baseState.setPageNumber(state.baseState, pageNumber)
    };
  }),

  on(overrideTemplatesActions.setPageSize, (state, { pageSize }) => {
    return {
      ...state,
      baseState: baseState.setPageSize(state.baseState, pageSize)
    };
  }),

  on(overrideTemplatesActions.getOverrideTemplatesSearch, (state, { searchTerm }) => {
    return {
      ...state,
      baseState: baseState.setSearch(state.baseState, searchTerm)
    };
  }),

  on(overrideTemplatesActions.setSortDirection, (state, { sortDirection }) => {
    return {
      ...state,
      baseState: baseState.setSortDirection(state.baseState, sortDirection)
    };
  }),

  on(overrideTemplatesActions.setSortBy, (state, { sortBy }) => {
    return {
      ...state,
      baseState: baseState.setSortBy(state.baseState, sortBy)
    };
  }),

  on(overrideTemplatesActions.setIsActive, (state, { isActive }) => {
    return {
      ...state,
      baseState: baseState.setIsActive(state.baseState, isActive)
    };
  }),

  on(overrideTemplatesActions.resetFilters, state => {
    return {
      ...state,
      baseState: baseState.resetFilters(state.baseState, overrideTemplateSort[0])
    };
  }),

  on(overrideTemplatesActions.selectNewTemplate, (state, { selectedId, overrideTemplateTypeId }) => {
    return {
      ...state,
      selectedId,
      selectedOverrideTemplateTypeId: overrideTemplateTypeId
    };
  }),

  on(overrideTemplatesActions.getResources, state => {
    return {
      ...state,
      baseState: baseState.setIsLoading(state.baseState, true)
    };
  }),

  on(overrideTemplatesActions.getResourcesSuccess, (state, { resources }) => {
    return {
      ...state,
      resources,
      baseState: baseState.setIsLoading(state.baseState, false)
    };
  }),

  on(overrideTemplatesActions.getResourcesFailure, state => {
    return {
      ...state,
      baseState: baseState.setIsLoading(state.baseState, false)
    };
  }),

  on(overrideTemplatesActions.getAllOverrideTemplates, state => {
    return {
      ...state,
      baseState: baseState.setGet(state.baseState, false)
    };
  }),

  on(overrideTemplatesActions.getAllOverrideTemplatesSuccess, (state, { overrideTemplates }) => {
    return {
      ...state,
      allOverrideTemplates: overrideTemplates
    };
  }),

  on(overrideTemplatesActions.getAllOverrideTemplatesFailure, (state, { error }) => {
    return {
      ...state,
      baseState: baseState.setError(state.baseState, error)
    };
  })
);

import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { Resource } from '../../models/resource';
import {
  getAllOverrideTemplates,
  getResources,
  selectNewTemplate
} from '../../store/actions/override-templates.actions';
import * as selectors from '../../store/selectors/override-templates.selectors';
import { AddOverrideTemplateFormComponent } from '../../ui/add-override-template-form/add-override-template-form.component';

@Component({
  selector: 'app-add-override-template-dialog',
  standalone: true,
  imports: [AddOverrideTemplateFormComponent, CommonModule],
  templateUrl: './add-override-template-dialog.component.html',
  styleUrl: './add-override-template-dialog.component.scss'
})
export class AddOverrideTemplateDialogComponent implements OnInit {
  resources$: Observable<Resource[] | null>;
  isLoading$: Observable<boolean>;
  templateExists$: Observable<boolean>;

  constructor(
    private store: Store,
    private router: Router,
    public dialogRef: MatDialogRef<AddOverrideTemplateDialogComponent>
  ) {
    this.resources$ = this.store.select(selectors.selectResources);
    this.isLoading$ = this.store.select(selectors.selectIsLoading);
    this.templateExists$ = this.store.select(selectors.selectCheckIfTemplateExists);
  }

  ngOnInit(): void {
    this.store.dispatch(selectNewTemplate({ selectedId: null, overrideTemplateTypeId: null }));
  }

  onCategoryChange(overrideTemplateId: number) {
    this.store.dispatch(getAllOverrideTemplates());
    this.store.dispatch(getResources({ overrideTemplateTypeId: overrideTemplateId }));
  }

  navigateToManageOverrides(resourceName: string) {
    this.router.navigate(['overrides/manage-overrides', resourceName]);
  }

  onSearchChange(ids: number[]) {
    this.store.dispatch(selectNewTemplate({ selectedId: ids[0], overrideTemplateTypeId: ids[1] }));
  }

  onClose() {
    this.dialogRef.close();
  }
}

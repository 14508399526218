import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UtilityService } from 'src/app/core/services/utility/utility.service';
import { environment } from 'src/environments/environment';
import { Availability } from '../../../shared/models/availability';

@Injectable({
  providedIn: 'root'
})
export class LocationAvailabilitiesService {
  constructor(private utilityService: UtilityService) {}

  endpoint = environment.scheduleApiUrl + '/LocationAvailabilities';

  postLocationAvailabilities(locationId: number, dayOfWeekAvailabilities: Availability[]): Observable<Availability[]> {
    const body = {
      locationId,
      dayOfWeekAvailabilities,
      modifiedByUserId: 1
    };

    return this.utilityService.postRequest<Availability[]>(this.endpoint, body);
  }

  getLocationAvailabilitiesByLocationId(locationId: number): Observable<Availability[]> {
    const url = this.endpoint + '?locationId=' + locationId;
    return this.utilityService.getRequest<Availability[]>(url);
  }
}

import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { LetDirective } from '@ngrx/component';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { ApptLocation } from 'src/app/locations/models/location';
import * as locationActions from 'src/app/locations/store/actions/locations.actions';
import { selectLocations } from 'src/app/locations/store/selectors/locations.selectors';
import { ProgressSpinnerComponent } from 'src/app/shared/component/progress-spinner/progress-spinner.component';
import { selectUserId } from 'src/app/user/store/selectors/user.selectors';
import { NonProviderResource } from '../../models/non-provider-resource';
import { NonProviderResourceType } from '../../models/non-provider-resource-type';
import * as nonProviderResourceActions from '../../store/actions/non-provider-resources.actions';
import { selectError, selectNonProviderResourceTypes } from '../../store/selectors/non-provider-resources.selectors';
import { AddNonProviderResourceFormComponent } from '../../ui/add-non-provider-resource-form/add-non-provider-resource-form.component';

@Component({
  selector: 'app-add-non-provider-resource',
  standalone: true,
  imports: [AddNonProviderResourceFormComponent, CommonModule, LetDirective, ProgressSpinnerComponent],
  templateUrl: './add-non-provider-resource.component.html',
  styleUrl: './add-non-provider-resource.component.scss'
})
export class AddNonProviderResourceComponent implements OnInit, OnDestroy {
  saveError$: Observable<string[] | null>;
  nonProviderResourceTypes$: Observable<NonProviderResourceType[] | null>;
  locations$: Observable<ApptLocation[] | null>;
  modifiedByUserId$: Observable<number | null>;

  constructor(private store: Store) {
    this.saveError$ = this.store.select(selectError);
    this.nonProviderResourceTypes$ = this.store.select(selectNonProviderResourceTypes);
    this.locations$ = this.store.select(selectLocations);
    this.modifiedByUserId$ = this.store.select(selectUserId);
  }

  ngOnInit(): void {
    this.store.dispatch(locationActions.setPageSize({ pageSize: 100 }));
    this.store.dispatch(nonProviderResourceActions.getNonProviderResourceTypes());
  }

  addNonProviderResource(nonProviderResourceToAdd: NonProviderResource) {
    this.store.dispatch(
      nonProviderResourceActions.addNonProviderResource({ nonProviderResource: nonProviderResourceToAdd })
    );
  }

  cancel() {
    this.store.dispatch(nonProviderResourceActions.navigateToNonProviderResourcesGrid());
  }

  ngOnDestroy(): void {
    this.store.dispatch(locationActions.resetFilters());
  }
}

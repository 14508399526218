import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { LetDirective } from '@ngrx/component';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { setPageSize } from 'src/app/locations/store/actions/locations.actions';
import { selectLocationMultiSelectList } from 'src/app/locations/store/selectors/locations.selectors';
import { ProgressSpinnerComponent } from 'src/app/shared/component/progress-spinner/progress-spinner.component';
import { MultiSelectAutoCompleteItem } from 'src/app/shared/models/multiselect-autocomplete-item';
import { selectUserId } from 'src/app/user/store/selectors/user.selectors';
import { ProviderResource } from '../../models/provider-resource';
import { ProviderResourceLocationRequest } from '../../models/provider-resource-location-request';
import {
  getProviderResourceById,
  navigateToProviderResourcesGrid,
  openAlertDialog,
  updateProviderResource,
  updateProviderResourceLocations
} from '../../store/actions/provider-resources.actions';
import { selectError, selectProviderResource } from '../../store/selectors/provider-resources.selectors';
import { EditProviderResourceFormComponent } from '../../ui/edit-provider-resource-form/edit-provider-resource-form.component';
import { ProviderResourceLocationAvailabilitiesGridComponent } from '../../ui/provider-resource-location-availabilities-grid/provider-resource-location-availabilities-grid.component';

@Component({
  selector: 'app-edit-provider-resource',
  standalone: true,
  imports: [
    CommonModule,
    EditProviderResourceFormComponent,
    LetDirective,
    ProgressSpinnerComponent,
    ProviderResourceLocationAvailabilitiesGridComponent
  ],
  templateUrl: './edit-provider-resource.component.html',
  styleUrl: './edit-provider-resource.component.scss'
})
export class EditProviderResourceComponent implements OnInit {
  locations$: Observable<MultiSelectAutoCompleteItem[] | null>;
  error$: Observable<string[] | null>;
  modifiedByUserId$: Observable<number | null>;
  selectedProviderResource$: Observable<ProviderResource | null>;

  constructor(private store: Store) {
    this.locations$ = this.store.select(selectLocationMultiSelectList);
    this.error$ = this.store.select(selectError);
    this.modifiedByUserId$ = this.store.select(selectUserId);
    this.selectedProviderResource$ = this.store.select(selectProviderResource);
  }

  ngOnInit(): void {
    this.store.dispatch(setPageSize({ pageSize: 100 }));
    this.store.dispatch(getProviderResourceById());
  }

  editProviderResource(providerResource: ProviderResource) {
    this.store.dispatch(updateProviderResource({ providerResource }));
  }

  editProviderResourceLocations(providerResourceLocationRequest: ProviderResourceLocationRequest) {
    this.store.dispatch(updateProviderResourceLocations({ providerResourceLocationRequest }));
  }

  showAlertDialog(validationMessage: string) {
    this.store.dispatch(openAlertDialog({ validationMessage }));
  }

  cancel() {
    this.store.dispatch(navigateToProviderResourcesGrid());
  }
}

import { createEntityAdapter, EntityState, Update } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import * as baseState from 'src/app/core/models/base-state';
import { providerResourceSort } from '../../data/provider-resource-sort';
import { ProviderResource } from '../../models/provider-resource';
import * as providerResourceActions from '../actions/provider-resources.actions';

export interface ProviderResourcesState extends EntityState<ProviderResource> {
  baseState: baseState.BaseState;
}

export const providerResourcesAdapter = createEntityAdapter<ProviderResource>({
  selectId: providerResource => providerResource.providerResourceId
});

export const initialState = providerResourcesAdapter.getInitialState({
  baseState: new baseState.BaseState(providerResourceSort[0])
});

export const providerResourcesFeatureKey = 'provider-resources';

export const providerResourcesReducer = createReducer(
  initialState,

  on(providerResourceActions.getProviderResources, state => {
    return {
      ...state,
      baseState: baseState.setGet(state.baseState, false)
    };
  }),

  on(providerResourceActions.getProviderResourcesSuccess, (state, { providerResources }) =>
    providerResourcesAdapter.addMany(providerResources, state)
  ),

  on(providerResourceActions.getProviderResourcesSuccess, (state, { pageNumber, isAtEndOfData, totalRecordCount }) => {
    return {
      ...state,
      baseState: baseState.setGetSuccess(state.baseState, pageNumber, isAtEndOfData, totalRecordCount)
    };
  }),

  on(providerResourceActions.getProviderResourcesFailure, (state, { error }) => {
    return {
      ...state,
      baseState: baseState.setError(state.baseState, error)
    };
  }),

  on(
    providerResourceActions.getProviderResources,
    providerResourceActions.setPageSize,
    providerResourceActions.setPageNumber,
    providerResourceActions.getProviderResourcesSearch,
    providerResourceActions.setSortBy,
    providerResourceActions.setSortDirection,
    providerResourceActions.setIsActive,
    state => providerResourcesAdapter.removeAll(state)
  ),

  on(providerResourceActions.setPageNumber, (state, { pageNumber }) => {
    return {
      ...state,
      baseState: baseState.setPageNumber(state.baseState, pageNumber)
    };
  }),

  on(providerResourceActions.setPageSize, (state, { pageSize }) => {
    return {
      ...state,
      baseState: baseState.setPageSize(state.baseState, pageSize)
    };
  }),

  on(providerResourceActions.getProviderResourcesSearch, (state, { searchTerm }) => {
    return {
      ...state,
      baseState: baseState.setSearch(state.baseState, searchTerm)
    };
  }),

  on(providerResourceActions.setSortDirection, (state, { sortDirection }) => {
    return {
      ...state,
      baseState: baseState.setSortDirection(state.baseState, sortDirection)
    };
  }),

  on(providerResourceActions.setSortBy, (state, { sortBy }) => {
    return {
      ...state,
      baseState: baseState.setSortBy(state.baseState, sortBy)
    };
  }),

  on(providerResourceActions.setIsActive, (state, { isActive }) => {
    return {
      ...state,
      baseState: baseState.setIsActive(state.baseState, isActive)
    };
  }),

  on(providerResourceActions.resetFilters, state => {
    return {
      ...state,
      baseState: baseState.resetFilters(state.baseState, providerResourceSort[0])
    };
  }),

  on(providerResourceActions.addProviderResourceFailure, (state, { error }) => {
    return {
      ...state,
      baseState: baseState.setError(state.baseState, error)
    };
  }),

  on(providerResourceActions.getProviderResourceByIdSuccess, (state, { providerResource }) => {
    const i = state.ids.findIndex((fi: any) => fi == providerResource.providerResourceId);
    if (i < 0) return providerResourcesAdapter.addOne(providerResource, state);

    const updatedProviderResource: Update<ProviderResource> = {
      id: providerResource.providerResourceId,
      changes: {
        ...providerResource
      }
    };

    return providerResourcesAdapter.updateOne(updatedProviderResource, state);
  }),

  on(providerResourceActions.getProviderResourceByIdSuccess, state => {
    return {
      ...state,
      baseState: baseState.setError(state.baseState, null)
    };
  }),

  on(providerResourceActions.getProviderResourceByIdFailure, (state, { error }) => {
    return {
      ...state,
      baseState: baseState.setError(state.baseState, error)
    };
  })
);

import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import moment from 'moment';

export function minTimeValidator(minTimeValue: string): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const timeValue = control.value;
    const minTime = moment(minTimeValue, 'h:mm:a').format('HH:mm');
    const time = timeValue === null ? null : moment(timeValue).format('HH:mm');

    if (time === null) {
      return null;
    } else if (time < minTime) {
      return { minTime: true };
    } else {
      return null;
    }
  };
}

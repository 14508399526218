import { NavListItem } from '../models/nav-list-item';

export const navList: NavListItem[] = [
  {
    icon: 'book_online',
    name: 'Appointment Types',
    route: 'appointment-types'
  },
  {
    icon: 'location_on',
    name: 'Locations',
    route: 'locations'
  },
  {
    icon: 'table_chart',
    name: 'Non-Provider Resources',
    route: 'non-provider-resources'
  },
  {
    icon: 'layers',
    name: 'Override Templates',
    route: 'overrides'
  },
  {
    icon: 'person_pin',
    name: 'Provider Resources',
    route: 'provider-resources'
  },
  {
    icon: 'group',
    name: 'Users',
    route: 'users'
  }
];

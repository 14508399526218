import { DayOfWeekType } from '../models/day-of-week';

export const daysOfWeek: DayOfWeekType[] = [
  {
    dayOfWeekId: 1,
    name: 'Monday',
    description: 'Monday',
    abbreviation: 'Mon'
  },
  {
    dayOfWeekId: 2,
    name: 'Tuesday',
    description: 'Tuesday',
    abbreviation: 'Tue'
  },
  {
    dayOfWeekId: 3,
    name: 'Wednesday',
    description: 'Wednesday',
    abbreviation: 'Wed'
  },
  {
    dayOfWeekId: 4,
    name: 'Thursday',
    description: 'Thursday',
    abbreviation: 'Thu'
  },
  {
    dayOfWeekId: 5,
    name: 'Friday',
    description: 'Friday',
    abbreviation: 'Fri'
  },
  {
    dayOfWeekId: 6,
    name: 'Saturday',
    description: 'Saturday',
    abbreviation: 'Sat'
  },
  {
    dayOfWeekId: 7,
    name: 'Sunday',
    description: 'Sunday',
    abbreviation: 'Sun'
  }
];

import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import { NGXLogger } from 'ngx-logger';
import { catchError, exhaustMap, map, mergeMap, of, startWith, switchMap, tap } from 'rxjs';
import { selectParams } from 'src/app/core/router/store/router.selectors';
import { WarningModalComponent } from 'src/app/shared/component/warning-modal/warning-modal.component';
import { NonProviderResourceAvailabilitiesService } from '../../services/non-provider-resource-availabilities/non-provider-resource-availabilities.service';
import { NonProviderResourceTypesService } from '../../services/non-provider-resource-types/non-provider-resource-types.service';
import { NonProviderResourcesService } from '../../services/non-provider-resources/non-provider-resources.service';
import * as nonProviderResourcesActions from '../actions/non-provider-resources.actions';
import * as nonProviderResourcesSelector from '../selectors/non-provider-resources.selectors';

@Injectable()
export class NonProviderResourcesEffects {
  constructor(
    private actions$: Actions,
    private nonProviderResourcesService: NonProviderResourcesService,
    private nonProviderResourceTypesService: NonProviderResourceTypesService,
    private nonProviderResourceAvailabilitiesService: NonProviderResourceAvailabilitiesService,
    private store: Store,
    private logger: NGXLogger,
    private router: Router,
    private dialog: MatDialog
  ) {}

  getNonProviderResources$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        nonProviderResourcesActions.getNonProviderResources,
        nonProviderResourcesActions.setPageNumber,
        nonProviderResourcesActions.setPageSize,
        nonProviderResourcesActions.getNonProviderResourcesSearch,
        nonProviderResourcesActions.setSortBy,
        nonProviderResourcesActions.setIsActive
      ),
      concatLatestFrom(() => [
        this.store.select(nonProviderResourcesSelector.selectPageNumber),
        this.store.select(nonProviderResourcesSelector.selectSortDirection),
        this.store.select(nonProviderResourcesSelector.selectSortBy),
        this.store.select(nonProviderResourcesSelector.selectPageSize),
        this.store.select(nonProviderResourcesSelector.selectSearchTerm),
        this.store.select(nonProviderResourcesSelector.selectIsActive)
      ]),
      mergeMap(([, pageNumber, sortDirection, sortBy, pageSize, searchTerm, isActive]) => {
        return this.nonProviderResourcesService
          .postSearchNonProviderResources(pageNumber, sortDirection, sortBy, pageSize, searchTerm, isActive)
          .pipe(
            map(response => {
              let isAtEndOfData = false;
              const totalRecordCount = response.totalRecordCount;
              let currentPage = pageNumber;
              let nonProviderResources = response.result;

              if (nonProviderResources == null || nonProviderResources.length == 0) {
                nonProviderResources = [];
                currentPage--;
                isAtEndOfData = true;
              }

              return nonProviderResourcesActions.getNonProviderResourcesSuccess({
                nonProviderResources,
                pageNumber: currentPage <= 0 ? 1 : currentPage,
                isAtEndOfData,
                totalRecordCount
              });
            }),
            catchError(err => {
              this.logger.error(err);
              return of(nonProviderResourcesActions.getNonProviderResourcesFailure({ error: err }));
            })
          );
      })
    );
  });

  navigateToAddNonProviderResource$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(nonProviderResourcesActions.navigateToAddNonProviderResource),
        tap(() => {
          this.router.navigate(['non-provider-resources/add-non-provider-resource']);
        })
      ),
    { dispatch: false }
  );

  navigateToNonProviderResourcesGrid$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          nonProviderResourcesActions.navigateToNonProviderResourcesGrid,
          nonProviderResourcesActions.addNonProviderResourceSuccess,
          nonProviderResourcesActions.updateNonProviderResourceSuccess
        ),
        tap(() => {
          this.router.navigate(['non-provider-resources']);
        })
      ),
    { dispatch: false }
  );

  addNonProviderResource$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(nonProviderResourcesActions.addNonProviderResource),
      map(action => action.nonProviderResource),
      switchMap(nonProviderResourceProfile => {
        return this.nonProviderResourcesService
          .addNonProviderResource(nonProviderResourceProfile)
          .pipe(map(nonProviderResource => ({ nonProviderResourceProfile, nonProviderResource })));
      }),
      switchMap(payload => {
        if (payload.nonProviderResourceProfile.nonProviderResourceAvailabilities.isDirty) {
          return this.nonProviderResourceAvailabilitiesService
            .postNonProviderResourceAvailabilities(
              payload.nonProviderResource.nonProviderResourceId,
              payload.nonProviderResourceProfile.nonProviderResourceAvailabilities.dayOfWeekAvailabilities
            )
            .pipe(map(payload => payload));
        } else {
          return of(payload);
        }
      }),
      map(() => {
        return nonProviderResourcesActions.addNonProviderResourceSuccess();
      }),
      catchError((err, source) => {
        return source.pipe(
          startWith(nonProviderResourcesActions.addNonProviderResourceFailure({ error: err.error?.errors }))
        );
      })
    );
  });

  getNonProviderResourceTypes$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(nonProviderResourcesActions.getNonProviderResourceTypes),
      mergeMap(() => {
        return this.nonProviderResourceTypesService.getNonProviderResourceTypes().pipe(
          map(nonProviderResourceTypes => {
            return nonProviderResourcesActions.getNonProviderResourceTypesSuccess({
              nonProviderResourceTypes
            });
          }),
          catchError(error => {
            this.logger.error(error);
            return of(nonProviderResourcesActions.getNonProviderResourceTypesFailure({ error }));
          })
        );
      })
    );
  });

  navigateToUpdateNonProviderResource$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(nonProviderResourcesActions.navigateToUpdateNonProviderResource),
        tap(action => {
          this.router.navigate(['non-provider-resources/update-non-provider-resource', action.nonProviderResourceId]);
        })
      ),
    { dispatch: false }
  );

  getNonProviderResourceById$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(nonProviderResourcesActions.getNonProviderResourceById),
      concatLatestFrom(() => this.store.select(selectParams)),
      mergeMap(([, { nonProviderResourceId }]) => {
        return this.nonProviderResourcesService.getNonProviderResourceById(nonProviderResourceId).pipe(
          map(nonProviderResource => {
            return nonProviderResourcesActions.getNonProviderResourceByIdSuccess({ nonProviderResource });
          }),
          catchError(error => {
            this.logger.error(error);
            return of(nonProviderResourcesActions.getNonProviderResourceByIdFailure({ error }));
          })
        );
      })
    );
  });

  updateNonProviderResource$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(nonProviderResourcesActions.updateNonProviderResource),
      mergeMap(action => {
        return this.nonProviderResourcesService.updateNonProviderResource(action.nonProviderResourceToUpdate).pipe(
          map(() => {
            return nonProviderResourcesActions.updateNonProviderResourceSuccess();
          }),
          catchError(err => {
            this.logger.error(err);
            return of(nonProviderResourcesActions.updateNonProviderResourceFailure({ error: err.error?.errors }));
          })
        );
      })
    );
  });

  updateNonProviderResourceAvailabilities$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(nonProviderResourcesActions.updateNonProviderResourceAvailabilities),
      mergeMap(action => {
        if (!action.nonProviderResourceAvailabilities.isDirty)
          return of(nonProviderResourcesActions.updateNonProviderResourceAvailabilitiesSuccess());

        return this.nonProviderResourceAvailabilitiesService
          .postNonProviderResourceAvailabilities(
            action.nonProviderResourceId,
            action.nonProviderResourceAvailabilities.dayOfWeekAvailabilities
          )
          .pipe(
            map(() => {
              return nonProviderResourcesActions.updateNonProviderResourceAvailabilitiesSuccess();
            }),
            catchError(err => {
              this.logger.error(err);
              return of(
                nonProviderResourcesActions.updateNonProviderResourceAvailabilitiesFailure({ error: err.error?.errors })
              );
            })
          );
      })
    );
  });

  getNonProviderResourceAvailabilitiesByNonProviderResourceId$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(nonProviderResourcesActions.getNonProviderResourceByIdSuccess),
      mergeMap(action => {
        return this.nonProviderResourceAvailabilitiesService
          .getNonProviderResourceAvailabilitiesByNonProviderResourceId(action.nonProviderResource.nonProviderResourceId)
          .pipe(
            map(nonProviderResourceAvailabilities => {
              return nonProviderResourcesActions.getNonProviderResourceAvailabilitiesByNonProviderResourceIdSuccess({
                nonProviderResourceId: action.nonProviderResource.nonProviderResourceId,
                nonProviderResourceAvailabilities
              });
            }),
            catchError(err => {
              this.logger.error(err);
              return of(
                nonProviderResourcesActions.getNonProviderResourceAvailabilitiesByNonProviderResourceIdFailure({
                  error: err.error?.errors
                })
              );
            })
          );
      })
    );
  });

  getInactivationCheck$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(nonProviderResourcesActions.getInactivationCheck),
      mergeMap(action => {
        return this.nonProviderResourcesService
          .getInactivationCheck(
            action.nonProviderResource.nonProviderResourceId,
            action.nonProviderResource.deactivateOn
          )
          .pipe(
            map(nonProviderResourceInactivationCheck => {
              if (nonProviderResourceInactivationCheck.isValid) {
                return nonProviderResourcesActions.updateNonProviderResource({
                  nonProviderResourceToUpdate: action.nonProviderResource
                });
              }

              return nonProviderResourcesActions.getInactivationCheckSuccess({
                nonProviderResourceInactivationCheck,
                nonProviderResource: action.nonProviderResource
              });
            }),
            catchError(err => {
              this.logger.error(err);
              return of(nonProviderResourcesActions.getInactivationCheckFailure({ error: err.error?.errors }));
            })
          );
      })
    );
  });

  openWarningDialog$ = createEffect(() =>
    this.actions$.pipe(
      ofType(nonProviderResourcesActions.getInactivationCheckSuccess),
      exhaustMap(action => {
        const dialogRef = this.dialog.open(WarningModalComponent, {
          id: 'warning-dialog',
          data: {
            validationMessages: action.nonProviderResourceInactivationCheck.validationMessages,
            type: 'non-provider resource'
          },
          disableClose: true,
          autoFocus: false,
          width: '500px',
          minHeight: '250px'
        });

        return dialogRef.afterClosed().pipe(
          map(result => {
            if (result) {
              return nonProviderResourcesActions.updateNonProviderResource({
                nonProviderResourceToUpdate: action.nonProviderResource
              });
            } else {
              return nonProviderResourcesActions.cancelUpdate();
            }
          })
        );
      })
    )
  );
}

import { DataSort } from 'src/app/core/models/data-sort';

export const userSort: DataSort[] = [
  {
    id: 1,
    value: 'firstName',
    display: 'Name',
    isDefault: true
  },
  {
    id: 2,
    value: 'hostMnemonic',
    display: 'Mnemonic',
    isDefault: false
  }
];

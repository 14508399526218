import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { provideNativeDateAdapter } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { Color } from 'src/app/core/models/colors';
import { ProgressSpinnerOverlayComponent } from 'src/app/shared/component/progress-spinner-overlay/progress-spinner-overlay.component';
import { NumbersOnlyDirective } from 'src/app/shared/numbers-only/numbers-only.directive';
import { appointmentTypeColors } from '../../data/category-colors';
import { AppointmentType } from '../../models/appointment-type';
import { AppointmentTypeCategory } from '../../models/appointment-type-category';

@Component({
  selector: 'app-upsert-appointment-type-form',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    NumbersOnlyDirective,
    MatFormFieldModule,
    MatSelectModule,
    MatDatepickerModule,
    MatInputModule,
    MatButtonModule,
    ProgressSpinnerOverlayComponent
  ],
  providers: [provideNativeDateAdapter()],
  templateUrl: './upsert-appointment-type-form.component.html',
  styleUrl: './upsert-appointment-type-form.component.scss'
})
export class UpsertAppointmentTypeFormComponent {
  form = new FormGroup({
    appointmentTypeNameControl: new FormControl<string | null>(null, Validators.required),
    durationControl: new FormControl<number | null>(null, [
      Validators.required,
      Validators.max(480),
      Validators.min(5)
    ]),
    categoryControl: new FormControl<number | null>(null, Validators.required),
    colorControl: new FormControl<Color | null>(null, Validators.required),
    instructionsControl: new FormControl<string | null>(null),
    inactiveDateControl: new FormControl<Date | null>(null)
  });
  maxTypeNameLength = 50;
  maxInstructionsLength = 200;
  minDuration = 5;
  maxDuration = 480;
  appointmentType: AppointmentType | null = null;
  categoryColors = appointmentTypeColors;
  today = new Date();
  error: string[] = null;

  @Input() categories: AppointmentTypeCategory[] | null = null;
  @Input() set selectedAppointmentType(value: AppointmentType | null) {
    if (value !== null && value !== undefined) {
      this.appointmentType = value;
      const chosenColor = this.categoryColors.find(el => el.hexCode === this.appointmentType.hexColorCode);
      this.form.controls['appointmentTypeNameControl'].patchValue(this.appointmentType.name);
      this.form.controls['durationControl'].patchValue(this.appointmentType.durationInMinutes);
      this.form.controls['categoryControl'].patchValue(this.appointmentType.appointmentTypeCategoryLookupId);
      this.form.controls['colorControl'].patchValue(chosenColor);
      this.form.controls['instructionsControl'].patchValue(this.appointmentType.instructions);
      if (this.appointmentType.deactivateOn !== null && this.appointmentType.deactivateOn !== undefined) {
        const strDate = this.appointmentType.deactivateOn + ' 12:00:00Z';
        this.form.controls['inactiveDateControl'].patchValue(new Date(strDate));
      }

      if (this.appointmentType.isActive === false) this.form.disable();
    }
  }
  @Input() set saveError(value: any) {
    if (value !== null) {
      this.isSaving = false;
      this.error = value;
    }
  }
  @Input() isSaving: boolean | null = false;
  @Input() modifiedByUserId: number;
  @Output() closeAddAppointmentEvent = new EventEmitter();
  @Output() addAppointmentTypeEvent = new EventEmitter<AppointmentType>();
  @Output() updateAppointmentTypeEvent = new EventEmitter<AppointmentType>();
  @Output() checkInactivationEvent = new EventEmitter<AppointmentType>();

  constructor() {}

  save() {
    if (this.form.valid) {
      const inactiveDate = new Date(this.form.controls['inactiveDateControl'].value);
      const inactiveDateStr = this.form.controls['inactiveDateControl'].value
        ? inactiveDate.toJSON().substring(0, 10)
        : null;

      const apptType: AppointmentType = {
        appointmentTypeId: this.appointmentType === null ? null : this.appointmentType.appointmentTypeId,
        name: this.form.controls['appointmentTypeNameControl'].value,
        durationInMinutes: this.form.controls['durationControl'].value,
        hexColorCode: this.form.controls['colorControl'].value.hexCode,
        instructions: this.form.controls['instructionsControl'].value,
        deactivateOn: inactiveDateStr === '' ? null : inactiveDateStr,
        appointmentTypeCategoryLookupId: this.form.controls['categoryControl'].value,
        modifiedByUserId: '1',
        version: this.appointmentType === null ? null : this.appointmentType.version
      };

      if (this.appointmentType !== null) {
        if (
          ((this.appointmentType.deactivateOn === null || this.appointmentType.deactivateOn === '') &&
            apptType.deactivateOn !== null) ||
          new Date(this.appointmentType.deactivateOn) > inactiveDate
        ) {
          this.checkInactivationEvent.emit(apptType);
        } else {
          this.updateAppointmentTypeEvent.emit(apptType);
        }
      } else {
        this.addAppointmentTypeEvent.emit(apptType);
      }
    }
  }
}

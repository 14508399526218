import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocomplete, MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { map, Observable, startWith } from 'rxjs';
import { MultiSelectAutoCompleteItem } from '../../models/multiselect-autocomplete-item';

@Component({
  selector: 'app-multiselect-autocomplete',
  standalone: true,
  imports: [
    CommonModule,
    MatCardModule,
    MatChipsModule,
    MatCheckboxModule,
    MatIconModule,
    MatAutocompleteModule,
    MatFormFieldModule,
    MatInputModule,
    MatChipsModule,
    MatButtonModule,
    FormsModule,
    ReactiveFormsModule
  ],
  templateUrl: './multiselect-autocomplete.component.html',
  styleUrl: './multiselect-autocomplete.component.scss'
})
export class MultiselectAutocompleteComponent {
  @Input() placeholder: string = 'Select Data';
  @Input() data: MultiSelectAutoCompleteItem[] = [];
  @Input() set selectedData(value: MultiSelectAutoCompleteItem[] | null) {
    if (value !== null && value.length > 0) {
      this.setData(value);
    } else {
      this.setData([]);
    }
  }
  @Input() key: string = '';
  @Input() multiSelectLabel: string = '';
  @Input() isMultiSelectRequired: boolean = false;
  @Input() set disabled(value: boolean) {
    if (value) {
      this.selectControl.disable();
    } else {
      this.selectControl.enable();
    }
  }
  @Input() hasAutoSave: boolean = false;
  @Output() multiSelectResult = new EventEmitter<{ key: string; data: MultiSelectAutoCompleteItem[] }>();
  @Output() saveEvent = new EventEmitter();
  @Output() removeChipEvent = new EventEmitter();

  selectControl = new FormControl();
  selectedItems: MultiSelectAutoCompleteItem[] = [];
  filteredData: Observable<MultiSelectAutoCompleteItem[]>;
  filterString: string = '';
  isClosed: boolean = true;

  @ViewChild('auto') autocomplete!: MatAutocomplete;

  constructor() {
    this.filteredData = this.selectControl.valueChanges.pipe(
      startWith<string>(''),
      map(value => (typeof value === 'string' ? value : this.filterString)),
      map(filter => this.filter(filter))
    );
  }

  filter = (filter: string): Array<MultiSelectAutoCompleteItem> => {
    this.filterString = filter;
    if (filter.length > 0) {
      return this.data.filter(option => {
        return option.name.toLowerCase().indexOf(filter.toLowerCase()) >= 0;
      });
    } else {
      return this.data;
    }
  };

  displayFn = (): string => this.filterString;

  setData = (data: MultiSelectAutoCompleteItem[]): void => {
    this.data.forEach(fe => (fe.selected = false));
    this.selectedItems = [];
    data.forEach(fe => {
      fe.selected = true;
      return fe;
    });
    this.selectedItems = data;
    this.selectControl.setValue(this.selectedItems);
    this.emitAdjustedData();
  };

  toggleSelection = (data: MultiSelectAutoCompleteItem): void => {
    data.selected = !data.selected;
    if (data.selected === true) {
      this.selectedItems.push(data);
    } else {
      const i = this.selectedItems.findIndex(value => value.name === data.name);
      this.selectedItems.splice(i, 1);
    }

    this.selectControl.setValue(this.selectedItems);
    this.emitAdjustedData();
  };

  removeChip = (data: MultiSelectAutoCompleteItem): void => {
    if (this.hasAutoSave) {
      this.removeChipEvent.emit(data);
    } else {
      this.toggleSelection(data);
    }
  };

  onEnter() {
    const t = this.data.filter(option => {
      return option.name.toLowerCase().indexOf(this.filterString.toLowerCase()) >= 0;
    });
    if (t.length === 1) {
      this.toggleSelection(t[0]);
      this.selectControl.setValue('');
    }
  }

  emitAdjustedData = (): void => {
    const results: MultiSelectAutoCompleteItem[] = [];
    this.selectedItems.forEach((data: MultiSelectAutoCompleteItem) => {
      results.push(data);
    });
    this.multiSelectResult.emit({ key: this.key, data: results });
  };

  onArrowClick(isClosed: boolean, event: Event) {
    if (this.selectControl.enabled) {
      this.isClosed = isClosed;
      this.autocomplete.showPanel = false;
      if (isClosed) event.stopPropagation();
    }
  }

  toggleArrow(isClosed: boolean) {
    this.isClosed = isClosed;
  }

  close() {
    this.toggleArrow(true);
    this.saveEvent.emit();
  }
}

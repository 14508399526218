import { createAction, props } from '@ngrx/store';
import { DataSort } from 'src/app/core/models/data-sort';
import { ProviderResource } from '../../models/provider-resource';
import { ProviderResourceLocation } from '../../models/provider-resource-location';
import { ProviderResourceLocationRequest } from '../../models/provider-resource-location-request';

export const getProviderResources = createAction('[Provider Resources] Get Provider Resources');

export const getProviderResourcesSuccess = createAction(
  '[Provider Resources] Get Provider Resources Success',
  props<{
    providerResources: ProviderResource[];
    pageNumber: number;
    isAtEndOfData: boolean;
    totalRecordCount: number;
  }>()
);

export const getProviderResourcesFailure = createAction(
  '[Provider Resources] Get Provider Resources Failure',
  props<{ error: string[] }>()
);

export const getProviderResourcesSearch = createAction(
  '[Provider Resources] Get Provider Resources Search',
  props<{ searchTerm: any }>()
);

export const setPageNumber = createAction('[Provider Resources] Set Page Number', props<{ pageNumber: number }>());

export const setPageSize = createAction('[Provider Resources] Set Page Size', props<{ pageSize: number }>());

export const setSortDirection = createAction(
  '[Provider Resources] Set Sort Direction',
  props<{ sortDirection: number }>()
);

export const setSortBy = createAction('[Provider Resources] Set Sort By', props<{ sortBy: DataSort }>());

export const setIsActive = createAction('[Provider Resources] Set Is Active', props<{ isActive: boolean }>());

export const resetFilters = createAction('[Provider Resources] Reset Filters');

export const navigateToAddProviderResource = createAction('[Provider Resources] Navigate to Add Provider Resource');

export const navigateToProviderResourcesGrid = createAction('[Provider Resources] Navigate to Provider Resources Grid');

export const addProviderResource = createAction(
  '[Provider Resources] Add New Provider Resource',
  props<{ providerResource: ProviderResource; providerResourceLocationRequest: ProviderResourceLocationRequest }>()
);

export const addProviderResourceSuccess = createAction(
  '[Provider Resources] Add New Provider Resource Success',
  props<{ providerResourceId: number }>()
);

export const addProviderResourceFailure = createAction(
  '[Provider Resources] Add New Provider Resource Failure',
  props<{ error: string[] }>()
);

export const getProviderResourceById = createAction('[Provider Resources] Get Provider Resource By Id');

export const getProviderResourceByIdSuccess = createAction(
  '[Provider Resources] Get Provider Resource By Id Success',
  props<{ providerResource: ProviderResource }>()
);

export const getProviderResourceByIdFailure = createAction(
  '[Provider Resources] Get Provider Resource By Id Failure',
  props<{ error: string[] }>()
);

export const updateProviderResource = createAction(
  '[Provider Resources] Update Provider Resource',
  props<{ providerResource: ProviderResource }>()
);

export const updateProviderResourceSuccess = createAction(
  '[Provider Resources] Update Provider Resource Success',
  props<{ providerResource: ProviderResource }>()
);

export const updateProviderResourceFailure = createAction(
  '[Provider Resources] Update Provider Resource Failure',
  props<{ error: string[] }>()
);

export const updateProviderResourceLocations = createAction(
  '[Provider Resources] Update Provider Resource Locations',
  props<{ providerResourceLocationRequest: ProviderResourceLocationRequest }>()
);

export const updateProviderResourceLocationsSuccess = createAction(
  '[Provider Resources] Update Provider Resource Locations Success',
  props<{ providerResourceLocations: ProviderResourceLocation[] }>()
);

export const updateProviderResourceLocationsFailure = createAction(
  '[Provider Resources] Update Provider Resource Locations Failure',
  props<{ error: string[] }>()
);

export const navigateToUpdateProviderResources = createAction(
  '[Provider Resources] Navigate to Update Provider Resource',
  props<{ providerResourceId: number }>()
);

export const openAlertDialog = createAction(
  '[Provider Resources] Open Alert Dialog',
  props<{ validationMessage: string }>()
);

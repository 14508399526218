<ng-container
  *ngrxLet="{
    locations: locations$,
    modifiedByUserId: modifiedByUserId$,
    nonProviderResourceTypes: nonProviderResourceTypes$,
    error: saveError$
  } as vm">
  <app-progress-spinner
    *ngIf="
      vm.locations === [] || vm.nonProviderResourceTypes === null || vm.modifiedByUserId === null;
      else addNonProviderResourceForm
    "></app-progress-spinner>

  <ng-template #addNonProviderResourceForm>
    <div class="page-title">
      <h3 class="text-center flex-2">Add New Non-Provider Resource</h3>
    </div>

    <app-add-non-provider-resource-form
      [saveError]="vm.error"
      [nonProviderResourceTypes]="vm.nonProviderResourceTypes"
      [locations]="vm.locations"
      [modifiedByUserId]="vm.modifiedByUserId"
      (closeAddNonProviderResourceEvent)="cancel()"
      (saveNonProviderResourceEvent)="addNonProviderResource($event)"></app-add-non-provider-resource-form>
  </ng-template>
</ng-container>

import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { LetDirective } from '@ngrx/component';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { setPageSize } from 'src/app/locations/store/actions/locations.actions';
import { selectLocationMultiSelectList } from 'src/app/locations/store/selectors/locations.selectors';
import { getPersons } from 'src/app/persons/store/actions/persons.actions';
import { selectPersons } from 'src/app/persons/store/selector/persons.selector';
import { ProgressSpinnerComponent } from 'src/app/shared/component/progress-spinner/progress-spinner.component';
import { MultiSelectAutoCompleteItem } from 'src/app/shared/models/multiselect-autocomplete-item';
import { selectUserId } from 'src/app/user/store/selectors/user.selectors';
import { Person } from '../../models/person';
import { ProviderResource } from '../../models/provider-resource';
import { ProviderResourceLocationRequest } from '../../models/provider-resource-location-request';
import { addProviderResource, navigateToProviderResourcesGrid } from '../../store/actions/provider-resources.actions';
import { selectError } from '../../store/selectors/provider-resources.selectors';
import { AddProviderResourceFormComponent } from '../../ui/add-provider-resource-form/add-provider-resource-form.component';

@Component({
  selector: 'app-add-provider-resource',
  standalone: true,
  imports: [AddProviderResourceFormComponent, CommonModule, LetDirective, ProgressSpinnerComponent],
  templateUrl: './add-provider-resource.component.html',
  styleUrl: './add-provider-resource.component.scss'
})
export class AddProviderResourceComponent implements OnInit {
  persons$: Observable<Person[] | null>;
  locations$: Observable<MultiSelectAutoCompleteItem[] | null>;
  error$: Observable<string[] | null>;
  modifiedByUserId$: Observable<number | null>;

  constructor(private store: Store) {
    this.persons$ = this.store.select(selectPersons);
    this.locations$ = this.store.select(selectLocationMultiSelectList);
    this.error$ = this.store.select(selectError);
    this.modifiedByUserId$ = this.store.select(selectUserId);
  }

  ngOnInit(): void {
    this.store.dispatch(getPersons());
    this.store.dispatch(setPageSize({ pageSize: 100 }));
  }

  addProviderResource(event: {
    providerResource: ProviderResource;
    providerResourceLocationRequest: ProviderResourceLocationRequest;
  }) {
    this.store.dispatch(
      addProviderResource({
        providerResource: event.providerResource,
        providerResourceLocationRequest: event.providerResourceLocationRequest
      })
    );
  }

  cancel() {
    this.store.dispatch(navigateToProviderResourcesGrid());
  }
}

import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LetDirective } from '@ngrx/component';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { ProgressSpinnerComponent } from 'src/app/shared/component/progress-spinner/progress-spinner.component';
import { AppointmentType } from '../../models/appointment-type';
import { AppointmentTypeCategory } from '../../models/appointment-type-category';
import {
  getAppointmentTypeById,
  getAppointmentTypeCategories,
  getInactivationCheck,
  updateAppointmentType
} from '../../store/actions/appointment-types.actions';
import {
  selectAppointmentType,
  selectAppointmentTypeCategories,
  selectError,
  selectIsLoading
} from '../../store/selector/appointment-types.selector';
import { UpsertAppointmentTypeFormComponent } from '../../ui/upsert-appointment-type-form/upsert-appointment-type-form.component';

@Component({
  selector: 'app-update-appointment-type',
  standalone: true,
  imports: [CommonModule, LetDirective, ProgressSpinnerComponent, UpsertAppointmentTypeFormComponent],
  templateUrl: './update-appointment-type.component.html',
  styleUrl: './update-appointment-type.component.scss'
})
export class UpdateAppointmentTypeComponent implements OnInit {
  isLoading$: Observable<boolean | null>;
  error$: Observable<string[] | null>;
  appointmentTypeCategories$: Observable<AppointmentTypeCategory[]>;
  selectedAppointmentType$: Observable<AppointmentType>;

  constructor(
    private store: Store,
    private router: Router
  ) {
    this.error$ = this.store.select(selectError);
    this.appointmentTypeCategories$ = this.store.select(selectAppointmentTypeCategories);
    this.selectedAppointmentType$ = this.store.select(selectAppointmentType);
    this.isLoading$ = this.store.select(selectIsLoading);
  }

  ngOnInit(): void {
    this.store.dispatch(getAppointmentTypeCategories());
    this.store.dispatch(getAppointmentTypeById());
  }

  closeAppointmentType() {
    this.router.navigate(['appointment-types']);
  }

  updateAppointmentType(appointmentType: AppointmentType) {
    this.store.dispatch(updateAppointmentType({ appointmentType }));
  }

  checkInactivation(appointmentType: AppointmentType) {
    this.store.dispatch(getInactivationCheck({ appointmentType }));
  }
}

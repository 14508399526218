<ng-container
  *ngrxLet="{
    appointmentTypeCategories: appointmentTypeCategories$,
    selectedAppointmentType: selectedAppointmentType$,
    error: error$,
    isSaving: isLoading$
  } as vm">
  <app-progress-spinner
    *ngIf="
      vm.appointmentTypeCategories === null || vm.selectedAppointmentType === undefined;
      else updateAppointmentTypeForm
    "></app-progress-spinner>

  <ng-template #updateAppointmentTypeForm>
    <div class="page-title">
      <h3 id="pageTitle" class="text-center flex-2">
        {{ vm.selectedAppointmentType.isActive ? 'Edit' : '' }} {{ vm.selectedAppointmentType.name }}
      </h3>
    </div>

    <app-upsert-appointment-type-form
      [saveError]="vm.error"
      [categories]="vm.appointmentTypeCategories"
      [selectedAppointmentType]="vm.selectedAppointmentType"
      [isSaving]="vm.isSaving"
      (closeAddAppointmentEvent)="closeAppointmentType()"
      (updateAppointmentTypeEvent)="updateAppointmentType($event)"
      (checkInactivationEvent)="checkInactivation($event)"></app-upsert-appointment-type-form>
  </ng-template>
</ng-container>

import { Pipe, PipeTransform } from '@angular/core';
import { ProviderResourceLocationAvailabilityDayOfWeek } from 'src/app/provider-resources/models/provider-resource-location-availability';

@Pipe({ name: 'daysOfWeek', standalone: true })
export class DaysOfWeekPipe implements PipeTransform {
  transform(daysOfWeek: ProviderResourceLocationAvailabilityDayOfWeek[]): string {
    let dayOfWeekString = '';
    daysOfWeek.forEach(dayOfWeek => {
      dayOfWeekString += dayOfWeek.dayOfWeek.substring(0, 3) + ', ';
    });

    return dayOfWeekString.substring(0, dayOfWeekString.length - 2);
  }
}

<ng-container
  *ngrxLet="{
    locations: locations$,
    error: error$,
    modifiedByUserId: modifiedByUserId$,
    selectedProviderResource: selectedProviderResource$
  } as vm">
  <app-progress-spinner
    *ngIf="
      vm.locations === null ||
        vm.modifiedByUserId === null ||
        vm.selectedProviderResource === null ||
        vm.selectedProviderResource === undefined;
      else editProviderResourceForm
    "></app-progress-spinner>

  <ng-template #editProviderResourceForm>
    <div class="page-title">
      <h3 class="text-center flex-2">Edit {{ vm.selectedProviderResource.shortName }}</h3>
    </div>

    <div class="flex gap-20">
      <app-edit-provider-resource-form
        [saveError]="vm.error"
        [locations]="vm.locations"
        [modifiedByUserId]="vm.modifiedByUserId"
        [selectedProviderResource]="vm.selectedProviderResource"
        (editProviderResourceEvent)="editProviderResource($event)"
        (editProviderResourceLocationsEvent)="editProviderResourceLocations($event)"
        (showWarningDialogEvent)="showAlertDialog($event)"
        (cancelEditProviderResourceEvent)="cancel()"></app-edit-provider-resource-form>

      <app-provider-resource-location-availabilities-grid
        [data]="
          vm.selectedProviderResource.providerResourceLocationAvailabilities
        "></app-provider-resource-location-availabilities-grid>
    </div>
  </ng-template>
</ng-container>

import { AbstractControl, FormControl, FormGroup, ValidationErrors } from '@angular/forms';
import moment from 'moment';
import { daysOfWeek } from '../data/days-of-week';
import { Availability } from '../models/availability';
import { validateLocationAvailabilities } from './validators/daily-availabilities.validator';
import { timeRangeValidator } from './validators/time-range.validator';

export function createAvailabilityFormGroup(isAdd: boolean): FormGroup {
  const defaultStartTime: Date = moment(new Date()).hour(7).minutes(0).toDate();
  const defaultEndTime: Date = moment(new Date()).hour(17).minutes(0).toDate();

  return new FormGroup(
    {
      monday: new FormGroup(
        {
          startTime: new FormControl<Date | string | null>(isAdd ? defaultStartTime : null),
          endTime: new FormControl<Date | string | null>(isAdd ? defaultEndTime : null)
        },
        { validators: timeRangeValidator }
      ),
      tuesday: new FormGroup(
        {
          startTime: new FormControl<Date | string | null>(isAdd ? defaultStartTime : null),
          endTime: new FormControl<Date | string | null>(isAdd ? defaultEndTime : null)
        },
        { validators: timeRangeValidator }
      ),
      wednesday: new FormGroup(
        {
          startTime: new FormControl<Date | string | null>(isAdd ? defaultStartTime : null),
          endTime: new FormControl<Date | string | null>(isAdd ? defaultEndTime : null)
        },
        { validators: timeRangeValidator }
      ),
      thursday: new FormGroup(
        {
          startTime: new FormControl<Date | string | null>(isAdd ? defaultStartTime : null),
          endTime: new FormControl<Date | string | null>(isAdd ? defaultEndTime : null)
        },
        { validators: timeRangeValidator }
      ),
      friday: new FormGroup(
        {
          startTime: new FormControl<Date | string | null>(isAdd ? defaultStartTime : null),
          endTime: new FormControl<Date | string | null>(isAdd ? defaultEndTime : null)
        },
        { validators: timeRangeValidator }
      ),
      saturday: new FormGroup(
        {
          startTime: new FormControl<Date | string | null>(null),
          endTime: new FormControl<Date | string | null>(null)
        },
        { validators: timeRangeValidator }
      ),
      sunday: new FormGroup(
        {
          startTime: new FormControl<Date | string | null>(null),
          endTime: new FormControl<Date | string | null>(null)
        },
        { validators: timeRangeValidator }
      )
    },
    { validators: validateLocationAvailabilities }
  );
}

export function isTimeValid(errors: ValidationErrors): boolean {
  return errors !== null;
}

export function getAvailabilities(control: AbstractControl): Availability[] | null {
  const form = control as FormGroup;
  const dayOfWeekAvailabilities: Availability[] = [];

  Object.keys(form.controls).forEach((key: string) => {
    const abstract = form.controls[key];

    if (abstract instanceof FormGroup) {
      if (abstract.controls['startTime'].value !== null) {
        const dayOfWeekAvailability: Availability = {
          dayOfWeekTypeId: daysOfWeek.filter(f => f.description.toLocaleLowerCase() === key)[0].dayOfWeekId,
          startTimeLocal: new Date(abstract.controls['startTime'].value).toLocaleTimeString('en-US', {
            hour: 'numeric',
            minute: 'numeric',
            hour12: true
          }),
          endTimeLocal: new Date(abstract.controls['endTime'].value).toLocaleTimeString('en-US', {
            hour: 'numeric',
            minute: 'numeric',
            hour12: true
          })
        };

        dayOfWeekAvailabilities.push(dayOfWeekAvailability);
      }
    }
  });

  return dayOfWeekAvailabilities.length === 0 ? null : dayOfWeekAvailabilities;
}

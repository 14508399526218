<ng-container
  *ngrxLet="{
    appointmentTypeQuickPicks: appointmentTypeQuickPicks$,
    error: error$,
    usStates: usStates$,
    modifiedByUserId: modifiedByUserId$,
    selectedLocation: selectedLocation$
  } as vm">
  <app-progress-spinner
    *ngIf="
      vm.usStates === null || vm.appointmentTypeQuickPicks.length === 0 || vm.selectedLocation === undefined;
      else updateLocationForm
    ">
  </app-progress-spinner>

  <ng-template #updateLocationForm>
    <div class="page-title">
      <h3 class="text-center flex-2">Edit {{ vm.selectedLocation.name }}</h3>
    </div>

    <app-edit-location-form
      [appointmentTypeQuickPicks]="vm.appointmentTypeQuickPicks"
      [usStates]="vm.usStates"
      [saveError]="vm.error"
      [modifiedByUserId]="vm.modifiedByUserId"
      [selectedLocation]="vm.selectedLocation"
      (updateLocationEvent)="updateLocation($event)"
      (closeLocationEvent)="cancel()"
      (checkInactivationEvent)="checkInactivation($event)">
    </app-edit-location-form>
  </ng-template>
</ng-container>

import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { NGXLogger } from 'ngx-logger';
import { catchError, map, mergeMap, of } from 'rxjs';
import { PersonsService } from '../../services/persons/persons.service';
import * as personsActions from '../actions/persons.actions';

@Injectable()
export class PersonsEffects {
  constructor(
    private actions$: Actions,
    private personsService: PersonsService,
    private logger: NGXLogger
  ) {}

  getPersons$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(personsActions.getPersons),
      mergeMap(() => {
        return this.personsService.getNonProviderResources().pipe(
          map(persons => {
            return personsActions.getPersonsSuccess({ persons });
          }),
          catchError(err => {
            this.logger.error(err);
            return of(personsActions.getPersonsFailure({ error: err.error?.errors }));
          })
        );
      })
    );
  });
}

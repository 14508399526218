<ng-container
  *ngrxLet="{ providers: persons$, locations: locations$, error: error$, modifiedByUserId: modifiedByUserId$ } as vm">
  <app-progress-spinner
    *ngIf="
      vm.providers === null || vm.locations === null || vm.modifiedByUserId === null;
      else addProviderResourceForm
    "></app-progress-spinner>

  <ng-template #addProviderResourceForm>
    <div class="page-title">
      <h3 class="text-center flex-2">Add Provider Resource</h3>
    </div>

    <app-add-provider-resource-form
      [saveError]="vm.error"
      [providers]="vm.providers"
      [locations]="vm.locations"
      [modifiedByUserId]="vm.modifiedByUserId"
      (addProviderResourceEvent)="addProviderResource($event)"
      (cancelAddProviderResourceEvent)="cancel()"></app-add-provider-resource-form>
  </ng-template>
</ng-container>

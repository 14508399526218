import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromPersons from '../reducer/persons.reducer';

export const personsFeatureSelector = createFeatureSelector<fromPersons.PersonsState>(fromPersons.personsFeatureKey);

const { selectEntities, selectAll } = fromPersons.personsAdapter.getSelectors();

export const selectPersonsEntities = createSelector(personsFeatureSelector, selectEntities);

export const selectPersons = createSelector(personsFeatureSelector, selectAll);

export const selectError = createSelector(personsFeatureSelector, state => state.baseState.error);

export const selectIsLoading = createSelector(personsFeatureSelector, state => state.baseState.isLoading);

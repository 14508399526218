import { createFeatureSelector, createSelector } from '@ngrx/store';
import { selectParams } from 'src/app/core/router/store/router.selectors';
import * as fromUsers from '../reducer/users.reducer';

export const usersFeatureSelector = createFeatureSelector<fromUsers.UsersState>(fromUsers.usersFeatureKey);

const { selectEntities, selectAll } = fromUsers.usersAdapter.getSelectors();

export const selectUsersEntities = createSelector(usersFeatureSelector, selectEntities);

export const selectUsers = createSelector(usersFeatureSelector, selectAll);

export const selectError = createSelector(usersFeatureSelector, state => state.baseState.error);

export const selectIsLoading = createSelector(usersFeatureSelector, state => state.baseState.isLoading);

export const selectPageNumber = createSelector(usersFeatureSelector, state => state.baseState.pageNumber);

export const selectIsAtEndOfData = createSelector(usersFeatureSelector, state => state.baseState.isAtEndOfData);

export const selectSortDirection = createSelector(usersFeatureSelector, state => state.baseState.sortDirection);

export const selectSortBy = createSelector(usersFeatureSelector, state => state.baseState.sortBy);

export const selectPageSize = createSelector(usersFeatureSelector, state => state.baseState.pageSize);

export const selectTotalRecordCount = createSelector(usersFeatureSelector, state => state.baseState.totalRecordCount);

export const selectSearchTerm = createSelector(usersFeatureSelector, state => state.baseState.searchTerm);

export const selectIsActive = createSelector(usersFeatureSelector, state => state.baseState.isActive);

export const selectUser = createSelector(selectUsersEntities, selectParams, (users, { userId }) => users[userId]);

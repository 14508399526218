<ng-container *ngIf="data.length > 0">
  <mat-card class="card-wrapper" [ngClass]="{ 'card-wrapper-no-border': selectedItems.length === 0 }">
    <div class="chip-list-wrapper">
      <mat-chip-grid #chipGrid aria-label="Chip selections">
        @for (selectedItem of selectedItems; track $index) {
          <mat-chip-row (removed)="removeChip(selectedItem)">
            {{ selectedItem.name }}
            <button matChipRemove [attr.aria-label]="'remove ' + selectedItem">
              <mat-icon>cancel</mat-icon>
            </button>
          </mat-chip-row>
        }
      </mat-chip-grid>
    </div>

    <mat-form-field
      appearance="outline"
      subscriptSizing="dynamic"
      floatLabel="always"
      [ngClass]="{ 'mt-10': selectedItems.length > 0 }">
      <mat-label>{{ multiSelectLabel }}</mat-label>
      <input
        [required]="isMultiSelectRequired"
        matInput
        #input
        type="text"
        (keydown.enter)="onEnter()"
        [matAutocomplete]="auto"
        [formControl]="selectControl"
        [matChipInputFor]="chipGrid" />
      <button type="button" matSuffix mat-icon-button aria-label="Clear" (click)="onArrowClick(!isClosed, $event)">
        @if (isClosed) {
          <mat-icon>keyboard_arrow_down</mat-icon>
        } @else {
          <mat-icon>keyboard_arrow_up</mat-icon>
        }
      </button>
    </mat-form-field>
    <mat-autocomplete
      #auto="matAutocomplete"
      [displayWith]="displayFn"
      (opened)="toggleArrow(false)"
      (closed)="close()">
      <mat-option *ngFor="let data of filteredData | async" style="position: relative" [disabled]="data.isDisabled">
        <span
          style="position: absolute; top: 0; left: 0; bottom: 0; right: 0"
          (click)="toggleSelection(data); $event.stopPropagation()"
          (keypress)="toggleSelection(data); $event.stopPropagation()"
          tabindex="0">
          <mat-checkbox [checked]="data.selected" color="primary">
            <span>{{ data.name }}</span>
          </mat-checkbox>
        </span>
      </mat-option>
    </mat-autocomplete>
  </mat-card>
</ng-container>

import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import { NGXLogger } from 'ngx-logger';
import { catchError, map, mergeMap, of, tap } from 'rxjs';
import { SortDirection } from 'src/app/core/enums/sort-direction';
import { nonProviderResourceSort } from 'src/app/non-provider-resources/data/non-provider-resource-sort';
import { NonProviderResourcesService } from 'src/app/non-provider-resources/services/non-provider-resources/non-provider-resources.service';
import { providerResourceSort } from 'src/app/provider-resources/data/provider-resource-sort';
import { ProviderResourcesService } from 'src/app/provider-resources/services/provider-resource/provider-resource.service';
import { overrideTemplateSort } from '../../data/override-templates-sort';
import { AddOverrideTemplateDialogComponent } from '../../feature/add-override-template-dialog/add-override-template-dialog.component';
import { Resource } from '../../models/resource';
import { OverrideTemplatesService } from '../../service/override-templates/override-templates.service';
import * as overrideTemplatesActions from '../actions/override-templates.actions';
import * as overrideTemplatesSelector from '../selectors/override-templates.selectors';

@Injectable()
export class OverrideTemplatesEffects {
  constructor(
    private actions$: Actions,
    private overrideTemplatesService: OverrideTemplatesService,
    private nonProviderResourcesService: NonProviderResourcesService,
    private providerResourcesService: ProviderResourcesService,
    private store: Store,
    private logger: NGXLogger,
    private router: Router,
    private dialog: MatDialog
  ) {}

  pageSize = 200;

  getOverrideTemplates$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        overrideTemplatesActions.getOverrideTemplates,
        overrideTemplatesActions.setPageNumber,
        overrideTemplatesActions.setPageSize,
        overrideTemplatesActions.getOverrideTemplatesSearch,
        overrideTemplatesActions.setSortBy,
        overrideTemplatesActions.setIsActive
      ),
      concatLatestFrom(() => [
        this.store.select(overrideTemplatesSelector.selectPageNumber),
        this.store.select(overrideTemplatesSelector.selectSortDirection),
        this.store.select(overrideTemplatesSelector.selectSortBy),
        this.store.select(overrideTemplatesSelector.selectPageSize),
        this.store.select(overrideTemplatesSelector.selectSearchTerm),
        this.store.select(overrideTemplatesSelector.selectIsActive)
      ]),
      mergeMap(([, pageNumber, sortDirection, sortBy, pageSize, searchTerm, isActive]) => {
        return this.overrideTemplatesService
          .postSearchOverrideTemplates(pageNumber, sortDirection, sortBy, pageSize, searchTerm, isActive)
          .pipe(
            map(response => {
              let isAtEndOfData = false;
              const totalRecordCount = response.totalRecordCount;
              let currentPage = pageNumber;
              let overrideTemplates = response.result;

              if (overrideTemplates == null || overrideTemplates.length == 0) {
                overrideTemplates = [];
                currentPage--;
                isAtEndOfData = true;
              }

              return overrideTemplatesActions.getOverrideTemplatesSuccess({
                overrideTemplates,
                pageNumber: currentPage <= 0 ? 1 : currentPage,
                isAtEndOfData,
                totalRecordCount
              });
            }),
            catchError((err: HttpErrorResponse) => {
              this.logger.error(err);
              return of(overrideTemplatesActions.getOverrideTemplatesFailure({ error: err.error?.errors }));
            })
          );
      })
    );
  });

  getAllOverrideTemplates$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(overrideTemplatesActions.getAllOverrideTemplates),
      mergeMap(() => {
        return this.overrideTemplatesService
          .postSearchOverrideTemplates(1, SortDirection.Ascending, overrideTemplateSort[0], this.pageSize, null, true)
          .pipe(
            map(response => {
              let overrideTemplates = response.result;

              if (overrideTemplates == null || overrideTemplates.length == 0) {
                overrideTemplates = [];
              }

              return overrideTemplatesActions.getAllOverrideTemplatesSuccess({
                overrideTemplates
              });
            }),
            catchError((err: HttpErrorResponse) => {
              this.logger.error(err);
              return of(overrideTemplatesActions.getAllOverrideTemplatesFailure({ error: err.error?.errors }));
            })
          );
      })
    );
  });

  navigateToOverrideTemplatesGrid$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(overrideTemplatesActions.navigateToOverrideTemplatesGrid),
        tap(() => {
          this.router.navigate(['overrides']);
        })
      ),
    { dispatch: false }
  );

  openDeactivateTrendDialog$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(overrideTemplatesActions.openAddOverrideTemplateDialog),
        tap(() => {
          this.dialog.open(AddOverrideTemplateDialogComponent, {
            id: 'add-override-template-dialog',
            disableClose: true,
            autoFocus: false,
            minWidth: '600px'
          });
        })
      ),
    { dispatch: false }
  );

  getResources$ = createEffect(() =>
    this.actions$.pipe(
      ofType(overrideTemplatesActions.getResources),
      mergeMap(action => {
        const overrideTemplateTypeId = action.overrideTemplateTypeId;
        if (overrideTemplateTypeId === 2) {
          return this.nonProviderResourcesService
            .postSearchNonProviderResources(1, 1, nonProviderResourceSort[0], this.pageSize, null, true)
            .pipe(
              map(response => {
                const resources: Resource[] = [];
                response.result.map(resource => {
                  resources.push({
                    id: resource.nonProviderResourceId,
                    name: resource.name
                  });
                });
                return overrideTemplatesActions.getResourcesSuccess({ resources });
              }),
              catchError(err => {
                this.logger.error(err);
                return of(overrideTemplatesActions.getResourcesFailure({ error: err }));
              })
            );
        } else {
          return this.providerResourcesService
            .postSearchProviders(1, SortDirection.Ascending, providerResourceSort[0], this.pageSize, null, true)
            .pipe(
              map(response => {
                const resources: Resource[] = [];
                response.result.map(resource => {
                  resources.push({
                    id: resource.providerResourceId,
                    name: resource.name
                  });
                });
                return overrideTemplatesActions.getResourcesSuccess({ resources });
              }),
              catchError(err => {
                this.logger.error(err);
                return of(overrideTemplatesActions.getResourcesFailure({ error: err }));
              })
            );
        }
      })
    )
  );
}

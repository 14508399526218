<ng-container>
  <app-progress-spinner
    *ngIf="nonProviderResourceTypes === null || locations === null || modifiedByUserId === null; else addForm">
  </app-progress-spinner>

  <ng-template #addForm>
    <div class="form-container">
      <form [formGroup]="form" (submit)="save()" class="ml-30 mt-50">
        @if (error !== null && isSaving === false) {
          <div id="saveError" class="ml-20 mr-20 mb-20 mt-15 flex justify-center">
            <p>Error! Please try again later.</p>
          </div>
        }

        <div class="flex gap-60">
          <div class="flex flex-col gap-20 flex-1" formGroupName="profile">
            <mat-form-field id="name" appearance="outline" subscriptSizing="dynamic" floatLabel="always">
              <mat-label>Non-Provider Resource Name</mat-label>
              <input matInput #resourceName id="resourceName" formControlName="name" data-test="iptName" />
              @if (form.controls['profile'].controls['name'].hasError('required')) {
                <mat-error id="nameRequired">Name required</mat-error>
              } @else if (form.controls['profile'].controls['name'].hasError('maxlength')) {
                <mat-error id="nameMaxLengthError"
                  >Must not exceed {{ fieldValidators.maxLengthName }} characters</mat-error
                >
              } @else if (form.controls['profile'].controls['name'].hasError('duplicate')) {
                <mat-error id="nameDuplicateError">Resource name already exists for this location</mat-error>
              }
            </mat-form-field>

            <mat-form-field id="color" appearance="outline" subscriptSizing="dynamic" floatLabel="always">
              <mat-label>Color</mat-label>
              <mat-select hideSingleSelectionIndicator formControlName="color" data-test="selHexColorCode">
                <mat-select-trigger>
                  <div class="flex justify-between">
                    {{ form.controls['profile'].controls['color'].value?.name }}
                    <div
                      class="color-pill mr-10"
                      [style]="'background-color: ' + form.controls['profile'].controls['color'].value?.hexCode"></div>
                  </div>
                </mat-select-trigger>
                @for (color of colors; track color) {
                  <mat-option [value]="color">
                    <div class="flex justify-start w-100p">
                      <div class="w-20x h-20x mr-8" [style]="'background-color: ' + color.hexCode"></div>
                      {{ color.name }}
                    </div>
                  </mat-option>
                }
              </mat-select>
              @if (form.controls['profile'].controls['color'].hasError('required')) {
                <mat-error id="colorRequired">Color required</mat-error>
              }
            </mat-form-field>

            <mat-form-field id="type" appearance="outline" subscriptSizing="dynamic" floatLabel="always">
              <mat-label>Non-Provider Resource Type</mat-label>
              <mat-select formControlName="type" class="type-select" data-test="selResourceType">
                @for (type of nonProviderResourceTypes; track type) {
                  <mat-option [value]="type.nonProviderResourceTypeLookupId">
                    {{ type.description }}
                  </mat-option>
                }
              </mat-select>
              @if (form.controls['profile'].controls['type'].hasError('required')) {
                <mat-error id="typeRequired">Resource type required</mat-error>
              }
            </mat-form-field>

            <mat-form-field id="location" appearance="outline" subscriptSizing="dynamic" floatLabel="always">
              <mat-label>Location</mat-label>
              <mat-select formControlName="location" class="type-select" data-test="selLocation">
                @for (location of locations; track location) {
                  <mat-option [value]="location.locationId">
                    {{ location.name }}
                  </mat-option>
                }
              </mat-select>
              @if (form.controls['profile'].controls['location'].hasError('required')) {
                <mat-error id="locationRequired">Location required</mat-error>
              } @else if (form.controls['profile'].controls['location'].hasError('invalidLocation')) {
                <mat-error id="invalidLocation"
                  >The location associated with this resource is inactive. Please mark the inactivation date as today or
                  change the location.</mat-error
                >
              }
            </mat-form-field>

            <div formGroupName="activeDates" class="flex flex-col gap-20 flex-1">
              <mat-form-field id="date" appearance="outline" subscriptSizing="dynamic" floatLabel="always">
                <mat-label>Active Date</mat-label>
                <input
                  matInput
                  [matDatepicker]="activatePicker"
                  id="activeDate"
                  formControlName="startDate"
                  data-test="dteActivateOn"
                  [min]="minDateAllowed" />
                <mat-datepicker-toggle matIconSuffix [for]="activatePicker"></mat-datepicker-toggle>
                <mat-datepicker #activatePicker></mat-datepicker>
                @if (form.controls['profile'].controls['activeDates'].get('startDate')?.hasError('matDatepickerMin')) {
                  <mat-error id="activeDateInvalid">Date cannot be in the past</mat-error>
                }
                @if (form.controls['profile'].controls['activeDates'].get('startDate')?.hasError('required')) {
                  <mat-error id="activeDateRequired">Active date required</mat-error>
                }
              </mat-form-field>

              <mat-form-field id="date" appearance="outline" subscriptSizing="dynamic" floatLabel="always">
                <mat-label>Inactive Date</mat-label>
                <input
                  matInput
                  [matDatepicker]="deactivatePicker"
                  id="inactiveDate"
                  formControlName="endDate"
                  data-test="dteDeactivateOn"
                  [min]="minInactiveDate()" />
                <mat-datepicker-toggle matIconSuffix [for]="deactivatePicker"></mat-datepicker-toggle>
                <mat-datepicker #deactivatePicker></mat-datepicker>
                @if (form.controls['profile'].controls['activeDates'].get('endDate')?.hasError('matDatepickerMin')) {
                  <mat-error id="afterActiveError"
                    >Date must be on or after {{ minInactiveDate() | date: 'M/d/yyyy' }}</mat-error
                  >
                }
              </mat-form-field>
            </div>
          </div>

          <div class="flex flex-1 gap-60 flex-col">
            <div class="resource-availability" formGroupName="availability">
              <mat-label class="fw-600">Availability</mat-label>
              @for (dayOfWeek of resourceSettings.daysOfWeek; track dayOfWeek) {
                <div formGroupName="{{ dayOfWeek.description | lowercase }}" class="day-of-the-week-div">
                  <div class="day-of-the-week">{{ dayOfWeek.description }}</div>
                  <div class="time-range-with-error-div">
                    <div class="time-range-div">
                      <div class="time-box-div">
                        <dx-date-box
                          formControlName="startTime"
                          type="time"
                          label="Start Time"
                          labelMode="static"
                          [interval]="resourceSettings.interval"
                          [inputAttr]="{ 'aria-label': 'Start Time' }"
                          [useMaskBehavior]="true"
                          class="time-box"
                          [ngClass]="{
                            'time-invalid': isValid(
                              this.form.controls['availability']
                                .get(dayOfWeek.description.toLocaleLowerCase())
                                ?.get('startTime')?.errors
                            )
                          }">
                        </dx-date-box>
                        @if (
                          form.controls['availability'].get(dayOfWeek.description | lowercase)?.get('startTime')
                            ?.errors?.['required']
                        ) {
                          <div id="{{ dayOfWeek.name }}StartRequired" class="error-label">Required</div>
                        } @else if (
                          form.controls['availability'].get(dayOfWeek.description | lowercase)?.get('startTime')
                            ?.errors?.['invalid']
                        ) {
                          <div id="{{ dayOfWeek.name }}StartInvalid" class="error-label">Invalid time range</div>
                        }
                      </div>

                      <div class="time-box-div">
                        <dx-date-box
                          formControlName="endTime"
                          type="time"
                          label="End Time"
                          labelMode="static"
                          [interval]="resourceSettings.interval"
                          [useMaskBehavior]="true"
                          [inputAttr]="{ 'aria-label': 'End Time' }"
                          class="time-box"
                          [ngClass]="{
                            'time-invalid': isValid(
                              this.form.controls['availability']
                                .get(dayOfWeek.description.toLocaleLowerCase())
                                ?.get('endTime')?.errors
                            )
                          }">
                        </dx-date-box>
                        @if (
                          form.controls['availability'].get(dayOfWeek.description | lowercase)?.get('endTime')
                            ?.errors?.['required']
                        ) {
                          <div id="{{ dayOfWeek.name }}EndRequired" class="error-label">Required</div>
                        } @else if (
                          form.controls['availability'].get(dayOfWeek.description | lowercase)?.get('endTime')
                            ?.errors?.['invalid']
                        ) {
                          <div id="{{ dayOfWeek.name }}EndInvalid" class="error-label">Invalid time range</div>
                        }
                      </div>
                    </div>
                  </div>
                </div>
              }
              @if (form.controls['availability'].hasError('required')) {
                <mat-error id="availabilityRequired">Availability Required</mat-error>
              }
            </div>

            <div class="btn-container flex justify-end align-center mb-20">
              <div>
                <button
                  class="btn warn-button"
                  id="btnCancel"
                  color="warn"
                  mat-stroked-button
                  type="button"
                  data-test="btnCancel"
                  (click)="closeAddNonProviderResourceEvent.emit()">
                  Cancel
                </button>
              </div>

              <div class="ml-10">
                <button
                  class="btn primary-solid-button"
                  id="btnSubmit"
                  color="primary"
                  mat-raised-button
                  type="submit"
                  data-test="btnSave">
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>

    <app-progress-spinner-overlay
      [displayProgressSpinner]="isSaving"
      data-test="spinnerAddResource"></app-progress-spinner-overlay>
  </ng-template>
</ng-container>

import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, ValidationErrors, Validators } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { MatNativeDateModule, MatOption, provideNativeDateAdapter } from '@angular/material/core';
import { MatDatepicker, MatDatepickerInput, MatDatepickerToggle } from '@angular/material/datepicker';
import { MatError, MatFormField, MatLabel } from '@angular/material/form-field';
import { MatInput, MatInputModule } from '@angular/material/input';
import { MatSelect, MatSelectTrigger } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { DxDateBoxModule, DxValidatorModule } from 'devextreme-angular';
import moment from 'moment';
import { Color } from 'src/app/core/models/colors';
import { locationColors } from 'src/app/locations/data/location-colors';
import { ApptLocation } from 'src/app/locations/models/location';
import { ProgressSpinnerOverlayComponent } from 'src/app/shared/component/progress-spinner-overlay/progress-spinner-overlay.component';
import { ProgressSpinnerComponent } from 'src/app/shared/component/progress-spinner/progress-spinner.component';
import { daysOfWeek } from 'src/app/shared/data/days-of-week';
import {
  createAvailabilityFormGroup,
  getAvailabilities,
  isTimeValid
} from 'src/app/shared/functions/availability.functions';
import { formatDateWithUtcTimeOffset } from 'src/app/shared/functions/date.functions';
import { dateRangeValidator } from 'src/app/shared/functions/validators/date-range.validator';
import { maxTimeValidator } from 'src/app/shared/functions/validators/max-time.validator';
import { minTimeValidator } from 'src/app/shared/functions/validators/min-time.validator';
import { timeRangeValidator } from 'src/app/shared/functions/validators/time-range.validator';
import {
  nonProviderResourceLengthValidators,
  nonProviderResourceSettings
} from '../../data/non-provider-resource-form';
import { NonProviderResource } from '../../models/non-provider-resource';
import { NonProviderResourceType } from '../../models/non-provider-resource-type';

@Component({
  selector: 'app-edit-resource-form',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    ProgressSpinnerOverlayComponent,
    MatError,
    MatLabel,
    MatFormField,
    MatDatepicker,
    MatDatepickerInput,
    MatDatepickerToggle,
    MatOption,
    MatSelectTrigger,
    MatNativeDateModule,
    FormsModule,
    MatInputModule,
    MatInput,
    MatButton,
    MatSelect,
    DxDateBoxModule,
    DxValidatorModule,
    ProgressSpinnerComponent,
    MatTooltipModule
  ],
  providers: [provideNativeDateAdapter()],
  templateUrl: './edit-non-provider-resource-form.component.html',
  styleUrl: './edit-non-provider-resource-form.component.scss'
})
export class EditNonProviderResourceFormComponent {
  maxLengthName = 50;
  nonProviderResourceSettings = nonProviderResourceSettings;
  fieldValidators = nonProviderResourceLengthValidators;
  minDateAllowed = new Date();

  form = new FormGroup({
    profile: new FormGroup({
      name: new FormControl<string | null>(null, [Validators.required, Validators.maxLength(this.maxLengthName)]),
      color: new FormControl<Color | null>(null, Validators.required),
      type: new FormControl<number | null>(null, Validators.required),
      location: new FormControl<number | null>(null, Validators.required),
      activeDates: new FormGroup(
        {
          endDate: new FormControl<Date | null>(null),
          startDate: new FormControl<Date | null>(null)
        },
        { validators: dateRangeValidator }
      )
    }),
    availability: createAvailabilityFormGroup(false)
  });

  today = new Date();
  colors = locationColors;
  deactivatedLocation = false;
  nonProviderResource: NonProviderResource;
  locationsDropdown: ApptLocation[] = [];
  isSaving: boolean = false;
  error: string[] | null = null;

  @Input() set nonProviderResourceToUpdate(value: NonProviderResource | null) {
    const profile = this.form.controls['profile'];
    if (value !== null && value !== undefined && value.nonProviderResourceAvailabilities !== undefined) {
      const chosenColor = this.colors.find(el => el.hexCode === value.hexColorCode);
      profile.controls['location'].disable();
      profile.controls['name'].patchValue(value.name);
      profile.controls['color'].patchValue(chosenColor);
      profile.controls['type'].patchValue(value.nonProviderResourceTypeLookupId);
      profile.controls['location'].patchValue(value.locationId);
      if (value.deactivateOn !== null && value.deactivateOn !== undefined) {
        const strDate = value.deactivateOn + ' 12:00:00Z';
        profile.controls['activeDates'].get('endDate')?.patchValue(new Date(strDate));
      }
      if (value.activateOn !== null && value.activateOn !== undefined) {
        const strDate = value.activateOn + ' 12:00:00Z';
        profile.controls['activeDates'].get('startDate')?.patchValue(new Date(strDate));
        if (new Date(strDate) < this.today) {
          profile.controls['activeDates'].get('startDate')?.disable();
        }
      }

      if (value.activateOn === null) {
        profile.controls['activeDates'].get('startDate')?.disable();
      }
      this.nonProviderResource = value;

      daysOfWeek.forEach(dow => {
        if (
          this.nonProviderResource.nonProviderResourceAvailabilities.dayOfWeekAvailabilities !== null &&
          this.nonProviderResource.nonProviderResourceAvailabilities.dayOfWeekAvailabilities.length > 0 &&
          this.nonProviderResource.nonProviderResourceAvailabilities.dayOfWeekAvailabilities.filter(
            f => f.dayOfWeekTypeId === dow.dayOfWeekId
          ).length > 0
        ) {
          const dayAvailability =
            this.nonProviderResource.nonProviderResourceAvailabilities.dayOfWeekAvailabilities.find(
              f => f.dayOfWeekTypeId === dow.dayOfWeekId
            );
          this.form.controls['availability'].controls[dow.name.toLocaleLowerCase()]
            .get('startTime')
            ?.patchValue(moment(dayAvailability.startTimeLocal, 'h:mm a'));
          this.form.controls['availability'].controls[dow.name.toLocaleLowerCase()]
            .get('endTime')
            ?.patchValue(moment(dayAvailability.endTimeLocal, 'h:mm a'));
          this.form.controls['availability'].controls[dow.name.toLocaleLowerCase()]
            .get('startTime')
            .addValidators([Validators.required, maxTimeValidator(dayAvailability.startTimeLocal)]);
          this.form.controls['availability'].controls[dow.name.toLocaleLowerCase()]
            .get('endTime')
            .addValidators([Validators.required, minTimeValidator(dayAvailability.endTimeLocal)]);
          this.form.controls['availability'].controls[dow.name.toLocaleLowerCase()].removeValidators(
            timeRangeValidator
          );
          this.form.controls['availability'].controls[dow.name.toLocaleLowerCase()]
            .get('startTime')
            .updateValueAndValidity();
          this.form.controls['availability'].controls[dow.name.toLocaleLowerCase()]
            .get('endTime')
            .updateValueAndValidity();
        }
      });
      this.locations.map(location => {
        if (location.isActive) {
          this.locationsDropdown.push(location);
        } else if (value !== null && location?.locationId === value?.locationId) {
          const tempLoc: ApptLocation = {
            ...location,
            name: location.name + ' (Inactivated)'
          };
          this.locationsDropdown.push(tempLoc);
          profile.controls['location'].enable();
        }
      });
      if (this.nonProviderResource.isActive === false) {
        this.form.disable();
      }
    }
  }

  @Input() set saveError(value: any) {
    if (value !== null && value !== undefined) {
      this.isSaving = false;

      if (value.filter((f: string) => f.toLowerCase().includes('resource name')).length > 0) {
        this.form.controls['profile'].controls['name'].setErrors({ duplicate: true });
      } else {
        this.error = value;
      }
    }
  }
  @Input() nonProviderResourceTypes: NonProviderResourceType[] | null;
  @Input() locations: ApptLocation[] | null;
  @Input() modifiedByUserId: number;
  @Input() isLoading: boolean | null;
  @Output() closeEditNonProviderResourceEvent = new EventEmitter();
  @Output() saveNonProviderResourceEvent = new EventEmitter<NonProviderResource>();
  @Output() checkInactivationEvent = new EventEmitter<NonProviderResource>();

  constructor() {
    this.form.controls['profile'].controls['location'].valueChanges.subscribe(() => {
      this.form.controls['profile'].controls['name'].setErrors({ duplicate: null });
      this.form.controls['profile'].controls['name'].updateValueAndValidity({ emitEvent: false });
    });
  }

  locationValidator() {
    if (this.form !== undefined && this.form !== null) {
      const selectedLocationId = this.form.controls['profile'].controls['location'].value;
      const location = this.locations.find(location => {
        return location.locationId === selectedLocationId;
      });
      if (location !== undefined) {
        if (!location.isActive) {
          this.form.controls['profile'].controls['location'].setErrors({ invalidLocation: true });
        } else {
          this.form.controls['profile'].controls['location'].setErrors(null);
        }
      }
    }
  }

  isStartTimeValid(e: string) {
    return this.form.controls['availability'].get(e.toLocaleLowerCase())?.get('startTime')?.errors !== null;
  }

  isEndTimeValid(e: string) {
    return this.form.controls['availability'].get(e.toLocaleLowerCase())?.get('endTime')?.errors !== null;
  }

  isValid = (errors: ValidationErrors): boolean => isTimeValid(errors);

  minInactiveDate() {
    const startDate = this.form.controls['profile'].controls['activeDates'].get('startDate')?.value;

    if (startDate !== null) {
      return moment(startDate).add(1, 'days').toDate();
    } else {
      return this.today;
    }
  }

  save() {
    this.isSaving = true;

    this.locationValidator();

    if (this.form.valid) {
      const nonProviderResource: NonProviderResource = {
        name: this.form.controls['profile'].controls['name'].value,
        hexColorCode: this.form.controls['profile'].controls['color'].value.hexCode,
        nonProviderResourceTypeLookupId: this.form.controls['profile'].controls['type'].value,
        locationId: this.form.controls['profile'].controls['location'].value,
        deactivateOn: formatDateWithUtcTimeOffset(
          this.form.controls['profile'].controls['activeDates'].get('endDate')?.value
        ),
        activateOn: formatDateWithUtcTimeOffset(
          this.form.controls['profile'].controls['activeDates'].get('startDate')?.value
        ),
        nonProviderResourceId: this.nonProviderResource?.nonProviderResourceId,
        version: this.nonProviderResource?.version,
        modifiedByUserId: this.modifiedByUserId,
        nonProviderResourceAvailabilities: {
          dayOfWeekAvailabilities: getAvailabilities(this.form.controls['availability']),
          isDirty: this.form.controls['availability'].dirty
        }
      };

      const deactivateOn = this.form.controls['profile'].controls['activeDates'].get('endDate')?.value;
      if (
        deactivateOn !== null &&
        (this.nonProviderResource.deactivateOn === null ||
          this.nonProviderResource.deactivateOn === '' ||
          new Date(this.nonProviderResource.deactivateOn) > new Date(deactivateOn))
      ) {
        this.checkInactivationEvent.emit(nonProviderResource);
        this.isSaving = false;
      } else {
        this.saveNonProviderResourceEvent.emit(nonProviderResource);
      }
    } else {
      this.isSaving = false;
    }
  }
}

import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromOverrideTemplates from '../reducer/override-templates.reducer';

export const overrideTemplatesFeatureSelector = createFeatureSelector<fromOverrideTemplates.OverrideTemplatesState>(
  fromOverrideTemplates.overrideTemplatesFeatureKey
);

const { selectEntities, selectAll } = fromOverrideTemplates.overrideTemplatesAdapter.getSelectors();

export const selectOverrideTemplatesEntities = createSelector(overrideTemplatesFeatureSelector, selectEntities);

export const selectOverrideTemplates = createSelector(overrideTemplatesFeatureSelector, selectAll);

export const selectError = createSelector(overrideTemplatesFeatureSelector, state => state.baseState.error);

export const selectIsLoading = createSelector(overrideTemplatesFeatureSelector, state => state.baseState.isLoading);

export const selectPageNumber = createSelector(overrideTemplatesFeatureSelector, state => state.baseState.pageNumber);

export const selectIsAtEndOfData = createSelector(
  overrideTemplatesFeatureSelector,
  state => state.baseState.isAtEndOfData
);

export const selectSortDirection = createSelector(
  overrideTemplatesFeatureSelector,
  state => state.baseState.sortDirection
);

export const selectSortBy = createSelector(overrideTemplatesFeatureSelector, state => state.baseState.sortBy);

export const selectPageSize = createSelector(overrideTemplatesFeatureSelector, state => state.baseState.pageSize);

export const selectTotalRecordCount = createSelector(
  overrideTemplatesFeatureSelector,
  state => state.baseState.totalRecordCount
);

export const selectSearchTerm = createSelector(overrideTemplatesFeatureSelector, state => state.baseState.searchTerm);

export const selectIsActive = createSelector(overrideTemplatesFeatureSelector, state => state.baseState.isActive);

export const selectId = createSelector(overrideTemplatesFeatureSelector, state => state.selectedId);

export const selectOverrideTemplateTypeId = createSelector(
  overrideTemplatesFeatureSelector,
  state => state.selectedOverrideTemplateTypeId
);

export const selectResources = createSelector(overrideTemplatesFeatureSelector, state => state.resources);

export const selectAllOverrideTemplates = createSelector(
  overrideTemplatesFeatureSelector,
  state => state.allOverrideTemplates
);

export const selectCheckIfTemplateExists = createSelector(
  selectAllOverrideTemplates,
  selectId,
  selectOverrideTemplateTypeId,
  (overrides, selectedId, category) => {
    const overridesAry = overrides ? Object.entries(overrides).map(([key, value]) => ({ key, value })) : [];
    const templateExists =
      overridesAry.filter(
        f => f.value.overrideTemplateTypeId === category && f.value.templateId === selectedId && f.value.isActive
      ).length > 0;
    if (templateExists) {
      return true;
    } else {
      return false;
    }
  }
);

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDivider } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenu, MatMenuTrigger } from '@angular/material/menu';

@Component({
  selector: 'app-non-provider-resources-filter-bar',
  standalone: true,
  imports: [
    FormsModule,
    MatFormFieldModule,
    MatIconModule,
    MatCheckboxModule,
    MatInputModule,
    MatButtonModule,
    MatMenu,
    MatMenuTrigger,
    MatDivider
  ],
  templateUrl: './non-provider-resources-filter-bar.component.html',
  styleUrl: './non-provider-resources-filter-bar.component.scss'
})
export class NonProviderResourcesFilterBarComponent implements OnInit {
  @Input() searchTerm: string | null = null;
  @Input() isActive!: boolean | null;
  @Output() searchEvent = new EventEmitter<string | null>();
  @Output() activeFiltersEvent = new EventEmitter<boolean>();
  @Output() addNonProviderResourceEvent = new EventEmitter();
  timeout: any;
  filterCount = 1;

  activeFilterOptions = [
    { name: 'Active', selected: true, id: 1 },
    { name: 'Inactive', selected: false, id: 2 }
  ];
  ngOnInit() {
    if (this.isActive === null) {
      this.filterCount = 2;
      this.activeFilterOptions = [
        { name: 'Active', selected: true, id: 1 },
        { name: 'Inactive', selected: true, id: 2 }
      ];
    } else if (this.isActive === false) {
      this.activeFilterOptions = [
        { name: 'Active', selected: false, id: 1 },
        { name: 'Inactive', selected: true, id: 2 }
      ];
    }
  }

  textChange(input: string) {
    this.searchTerm = input;
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      if (this.searchTerm.length >= 3 || this.searchTerm.length === 0) {
        this.searchEvent.emit(this.searchTerm);
      }
    }, 1000);
  }

  clear() {
    this.searchTerm = '';
    this.searchEvent.emit(null);
  }

  resetFilters() {
    this.activeFilterOptions = [
      { name: 'Active', selected: true, id: 1 },
      { name: 'Inactive', selected: false, id: 2 }
    ];
    this.filterCount = 1;
    this.activeFiltersEvent.emit(true);
  }
  filterChange(selected: any, name: string) {
    const index = this.activeFilterOptions.findIndex(filter => filter.name === name);
    this.activeFilterOptions[index].selected = selected;
    const active = this.activeFilterOptions[0].selected;
    const inactive = this.activeFilterOptions[1].selected;
    if (selected) {
      this.filterCount++;
    } else {
      this.filterCount--;
    }
    if (active && inactive) {
      this.activeFiltersEvent.emit(null);
    } else if (active && !inactive) {
      this.activeFiltersEvent.emit(true);
    } else if (!active && inactive) {
      this.activeFiltersEvent.emit(false);
    } else {
      this.activeFiltersEvent.emit(null);
    }
  }
}

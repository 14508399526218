import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UtilityService } from 'src/app/core/services/utility/utility.service';
import { environment } from 'src/environments/environment';
import { NonProviderResourceType } from '../../models/non-provider-resource-type';

@Injectable({
  providedIn: 'root'
})
export class NonProviderResourceTypesService {
  constructor(private utilityService: UtilityService) {}

  endpoint = environment.scheduleApiUrl + '/NonProviderResourceTypeLookups';

  getNonProviderResourceTypes(): Observable<NonProviderResourceType[]> {
    return this.utilityService.getRequest<NonProviderResourceType[]>(this.endpoint);
  }
}

import { createFeatureSelector, createSelector } from '@ngrx/store';
import { selectParams } from 'src/app/core/router/store/router.selectors';
import { firstBy } from 'thenby';
import * as fromProviders from '../reducer/provider-resources.reducer';

export const providerResourcesFeatureSelector = createFeatureSelector<fromProviders.ProviderResourcesState>(
  fromProviders.providerResourcesFeatureKey
);

const { selectEntities, selectAll } = fromProviders.providerResourcesAdapter.getSelectors();

export const selectProviderResourcesEntities = createSelector(providerResourcesFeatureSelector, selectEntities);

export const selectProviderResources = createSelector(providerResourcesFeatureSelector, selectAll);

export const selectError = createSelector(providerResourcesFeatureSelector, state => state.baseState.error);

export const selectIsLoading = createSelector(providerResourcesFeatureSelector, state => state.baseState.isLoading);

export const selectPageNumber = createSelector(providerResourcesFeatureSelector, state => state.baseState.pageNumber);

export const selectIsAtEndOfData = createSelector(
  providerResourcesFeatureSelector,
  state => state.baseState.isAtEndOfData
);

export const selectSortDirection = createSelector(
  providerResourcesFeatureSelector,
  state => state.baseState.sortDirection
);

export const selectSortBy = createSelector(providerResourcesFeatureSelector, state => state.baseState.sortBy);

export const selectPageSize = createSelector(providerResourcesFeatureSelector, state => state.baseState.pageSize);

export const selectTotalRecordCount = createSelector(
  providerResourcesFeatureSelector,
  state => state.baseState.totalRecordCount
);

export const selectSearchTerm = createSelector(providerResourcesFeatureSelector, state => state.baseState.searchTerm);

export const selectIsActive = createSelector(providerResourcesFeatureSelector, state => state.baseState.isActive);

export const selectProviderResource = createSelector(
  selectProviderResourcesEntities,
  selectParams,
  (providerResources, { providerResourceId }) => {
    const providerResource = providerResources[providerResourceId];
    if (
      providerResource !== undefined &&
      providerResource.providerResourceLocationAvailabilities !== undefined &&
      providerResource.providerResourceLocationAvailabilities !== null &&
      providerResource.providerResourceLocationAvailabilities.length > 0
    ) {
      const clonedProviderResource = structuredClone(providerResource);
      const clonedAvailabilities = [...providerResource.providerResourceLocationAvailabilities];
      clonedAvailabilities.sort(firstBy('locationName', 'asc').thenBy('startDate', 'asc'));
      clonedProviderResource.providerResourceLocationAvailabilities = clonedAvailabilities;

      return clonedProviderResource;
    }

    return providerResource;
  }
);

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {
  constructor(private http: HttpClient) {}
  private appConfig: any;

  public async loadConfig() {
    await lastValueFrom(this.http.get('/assets/appconfig.json')).then(config => {
      this.appConfig = config;
    });
  }

  public get config(): any {
    return this.appConfig;
  }
}

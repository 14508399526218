import { RouterReducerState } from '@ngrx/router-store';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { RouterState } from '../router-state';

export const selectRouterState = createFeatureSelector<RouterReducerState<RouterState>>('router');

export const selectParams = createSelector(selectRouterState, router => {
  return router?.state?.params;
});

export const selectQueryParams = createSelector(selectRouterState, router => router?.state?.queryParams);

export const selectUrl = createSelector(selectRouterState, router => router?.state?.url);

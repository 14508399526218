import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { LetDirective } from '@ngrx/component';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { ApptLocation } from 'src/app/locations/models/location';
import { resetFilters, setIsActive, setPageSize } from 'src/app/locations/store/actions/locations.actions';
import { selectLocations } from 'src/app/locations/store/selectors/locations.selectors';
import { ProgressSpinnerComponent } from 'src/app/shared/component/progress-spinner/progress-spinner.component';
import { selectUserId } from 'src/app/user/store/selectors/user.selectors';
import { NonProviderResource } from '../../models/non-provider-resource';
import { NonProviderResourceType } from '../../models/non-provider-resource-type';
import {
  getInactivationCheck,
  getNonProviderResourceById,
  getNonProviderResourceTypes,
  navigateToNonProviderResourcesGrid,
  updateNonProviderResource,
  updateNonProviderResourceAvailabilities
} from '../../store/actions/non-provider-resources.actions';
import {
  selectError,
  selectIsLoading,
  selectNonProviderResource,
  selectNonProviderResourceTypes
} from '../../store/selectors/non-provider-resources.selectors';
import { EditNonProviderResourceFormComponent } from '../../ui/edit-non-provider-resource-form/edit-non-provider-resource-form.component';

@Component({
  selector: 'app-update-resource',
  standalone: true,
  imports: [EditNonProviderResourceFormComponent, CommonModule, ProgressSpinnerComponent, LetDirective],
  templateUrl: './update-non-provider-resource.component.html',
  styleUrl: './update-non-provider-resource.component.scss'
})
export class UpdateNonProviderResourceComponent implements OnInit, OnDestroy {
  saveError$: Observable<string[] | null>;
  nonProviderResourceTypes$: Observable<NonProviderResourceType[] | null>;
  locations$: Observable<ApptLocation[] | null>;
  selectedNonProviderResource$: Observable<NonProviderResource | null>;
  modifiedByUserId$: Observable<number | null>;
  isLoading$: Observable<boolean | null>;

  constructor(private store: Store) {
    this.saveError$ = this.store.select(selectError);
    this.nonProviderResourceTypes$ = this.store.select(selectNonProviderResourceTypes);
    this.locations$ = this.store.select(selectLocations);
    this.modifiedByUserId$ = this.store.select(selectUserId);
    this.selectedNonProviderResource$ = this.store.select(selectNonProviderResource);
    this.isLoading$ = this.store.select(selectIsLoading);
  }

  ngOnInit(): void {
    this.store.dispatch(setPageSize({ pageSize: 100 }));
    this.store.dispatch(setIsActive({ isActive: null }));
    this.store.dispatch(getNonProviderResourceTypes());
    this.store.dispatch(getNonProviderResourceById());
  }

  updateNonProviderResource(nonProviderResourceToUpdate: NonProviderResource) {
    this.store.dispatch(updateNonProviderResource({ nonProviderResourceToUpdate }));
    this.store.dispatch(
      updateNonProviderResourceAvailabilities({
        nonProviderResourceId: nonProviderResourceToUpdate.nonProviderResourceId,
        nonProviderResourceAvailabilities: nonProviderResourceToUpdate.nonProviderResourceAvailabilities
      })
    );
  }

  checkInactivation(nonProviderResource: NonProviderResource) {
    this.store.dispatch(getInactivationCheck({ nonProviderResource }));
  }

  cancel() {
    this.store.dispatch(navigateToNonProviderResourcesGrid());
  }

  ngOnDestroy(): void {
    this.store.dispatch(resetFilters()); // reset location filters
  }
}

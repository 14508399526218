import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import { NGXLogger } from 'ngx-logger';
import { ToastrService } from 'ngx-toastr';
import { catchError, map, mergeMap, of, startWith, switchMap, tap } from 'rxjs';
import { selectParams } from 'src/app/core/router/store/router.selectors';
import { AlertModalComponent } from 'src/app/shared/component/alert-modal/alert-modal.component';
import { ProviderResourceLocationAvailabilitiesService } from '../../services/provider-resource-location-availabilities/provider-resource-location-availabilities.service';
import { ProviderResourceLocationsService } from '../../services/provider-resource-locations/provider-resource-locations.service';
import { ProviderResourcesService } from '../../services/provider-resource/provider-resource.service';
import * as providerResourceActions from '../actions/provider-resources.actions';
import * as providerResourceSelectors from '../selectors/provider-resources.selectors';

@Injectable()
export class ProvidersEffects {
  constructor(
    private actions$: Actions,
    private providerResourcesService: ProviderResourcesService,
    private providerResourceLocationsService: ProviderResourceLocationsService,
    private providerResourceLocationAvailabilitiesService: ProviderResourceLocationAvailabilitiesService,
    private store: Store,
    private logger: NGXLogger,
    private router: Router,
    private toastr: ToastrService,
    private dialog: MatDialog
  ) {}

  getProviderResources$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        providerResourceActions.getProviderResources,
        providerResourceActions.setPageNumber,
        providerResourceActions.setPageSize,
        providerResourceActions.getProviderResourcesSearch,
        providerResourceActions.setSortBy,
        providerResourceActions.setIsActive
      ),
      concatLatestFrom(() => [
        this.store.select(providerResourceSelectors.selectPageNumber),
        this.store.select(providerResourceSelectors.selectSortDirection),
        this.store.select(providerResourceSelectors.selectSortBy),
        this.store.select(providerResourceSelectors.selectPageSize),
        this.store.select(providerResourceSelectors.selectSearchTerm),
        this.store.select(providerResourceSelectors.selectIsActive)
      ]),
      mergeMap(([, pageNumber, sortDirection, sortBy, pageSize, searchTerm, isActive]) => {
        return this.providerResourcesService
          .postSearchProviders(pageNumber, sortDirection, sortBy, pageSize, searchTerm, isActive)
          .pipe(
            map(response => {
              let isAtEndOfData = false;
              const totalRecordCount = response.totalRecordCount;
              let currentPage = pageNumber;
              let providerResources = response.result;

              if (providerResources == null || providerResources.length == 0) {
                providerResources = [];
                currentPage--;
                isAtEndOfData = true;
              }

              return providerResourceActions.getProviderResourcesSuccess({
                providerResources,
                pageNumber: currentPage <= 0 ? 1 : currentPage,
                isAtEndOfData,
                totalRecordCount
              });
            }),
            catchError(err => {
              this.logger.error(err);
              return of(providerResourceActions.getProviderResourcesFailure({ error: err.error?.errors }));
            })
          );
      })
    );
  });

  navigateToAddProviderResource$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(providerResourceActions.navigateToAddProviderResource),
        tap(() => {
          this.router.navigate(['provider-resources/add-provider-resource']);
        })
      ),
    { dispatch: false }
  );

  navigateToProviderResourcesGrid$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(providerResourceActions.navigateToProviderResourcesGrid),
        tap(() => {
          this.router.navigate(['provider-resources']);
        })
      ),
    { dispatch: false }
  );

  addProviderResource$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(providerResourceActions.addProviderResource),
      map(action => ({
        providerResource: action.providerResource,
        providerResourceLocationRequest: action.providerResourceLocationRequest
      })),
      switchMap(providerResourceRequest => {
        return this.providerResourcesService
          .postProviderResource(providerResourceRequest.providerResource)
          .pipe(map(providerResource => ({ providerResourceRequest, providerResource })));
      }),
      switchMap(payload => {
        const clone = structuredClone(payload.providerResourceRequest.providerResourceLocationRequest);
        clone.providerResourceId = payload.providerResource.providerResourceId;

        return this.providerResourceLocationsService
          .postProviderResourceLocations(clone)
          .pipe(map(() => payload.providerResource.providerResourceId));
      }),
      map(providerResourceId => {
        return providerResourceActions.addProviderResourceSuccess({ providerResourceId });
      }),
      catchError((err, source) => {
        return source.pipe(startWith(providerResourceActions.addProviderResourceFailure({ error: err.error?.errors })));
      })
    );
  });

  navigateToUpdateProviderResources$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          providerResourceActions.addProviderResourceSuccess,
          providerResourceActions.navigateToUpdateProviderResources
        ),
        tap(action => {
          this.router.navigate(['provider-resources/edit-provider-resource', action.providerResourceId]);
        })
      ),
    { dispatch: false }
  );

  getProviderResourceById$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        providerResourceActions.getProviderResourceById,
        providerResourceActions.updateProviderResourceSuccess,
        providerResourceActions.updateProviderResourceLocationsSuccess
      ),
      concatLatestFrom(() => [this.store.select(selectParams)]),
      switchMap(payload => {
        const params = payload[1];
        return this.providerResourcesService
          .getProviderResourceById(params['providerResourceId'])
          .pipe(map(providerResource => ({ providerResource })));
      }),
      switchMap(payload => {
        return this.providerResourceLocationsService
          .getProviderResourceLocationsByProviderResourceId(payload.providerResource.providerResourceId)
          .pipe(
            map(providerResourceLocations => {
              const providerResourceClone = structuredClone(payload.providerResource);
              providerResourceClone.providerResourceLocations = providerResourceLocations;

              return providerResourceClone;
            })
          );
      }),
      switchMap(payload => {
        return this.providerResourceLocationAvailabilitiesService
          .getProviderResourceLocationAvailabilitiesByProviderResourceId(payload.providerResourceId)
          .pipe(
            map(providerResourceLocationAvailabilities => {
              const providerResourceClone = structuredClone(payload);
              providerResourceClone.providerResourceLocationAvailabilities = providerResourceLocationAvailabilities;

              return providerResourceClone;
            })
          );
      }),
      map(providerResource => {
        return providerResourceActions.getProviderResourceByIdSuccess({ providerResource });
      }),
      catchError((err, source) => {
        return source.pipe(
          startWith(providerResourceActions.getProviderResourceByIdFailure({ error: err.error?.errors }))
        );
      })
    );
  });

  updateProviderResource$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(providerResourceActions.updateProviderResource),
      mergeMap(action => {
        return this.providerResourcesService.putProviderResource(action.providerResource).pipe(
          map(providerResource => {
            return providerResourceActions.updateProviderResourceSuccess({ providerResource });
          }),
          catchError(err => {
            this.logger.error(err);
            return of(providerResourceActions.updateProviderResourceFailure({ error: err.error?.errors }));
          })
        );
      })
    );
  });

  updateProviderResourceLocations$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(providerResourceActions.updateProviderResourceLocations),
      mergeMap(action => {
        return this.providerResourceLocationsService
          .postProviderResourceLocations(action.providerResourceLocationRequest)
          .pipe(
            map(providerResourceLocations => {
              return providerResourceActions.updateProviderResourceLocationsSuccess({ providerResourceLocations });
            }),
            catchError(err => {
              this.logger.error(err);
              return of(providerResourceActions.updateProviderResourceLocationsFailure({ error: err.error?.errors }));
            })
          );
      })
    );
  });

  displaySuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          providerResourceActions.updateProviderResourceSuccess,
          providerResourceActions.updateProviderResourceLocationsSuccess
        ),
        tap(() => {
          this.toastr.success('Changes saved successfully!');
        })
      ),
    { dispatch: false }
  );

  openAlertDialog$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(providerResourceActions.openAlertDialog),
        tap(action => {
          this.dialog.open(AlertModalComponent, {
            id: 'alert-dialog',
            data: {
              validationMessage: action.validationMessage
            },
            disableClose: true,
            autoFocus: false,
            width: '500px',
            minHeight: '250px'
          });
        })
      ),
    { dispatch: false }
  );
}

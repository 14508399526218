import { CommonModule } from '@angular/common';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { Categories } from 'src/app/appointment-types/data/categories';
import { Resource } from '../../models/resource';
@Component({
  selector: 'app-add-override-template-form',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    MatFormFieldModule,
    MatSelectModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatInputModule,
    MatAutocompleteModule,
    MatIconModule
  ],
  templateUrl: './add-override-template-form.component.html',
  styleUrl: './add-override-template-form.component.scss'
})
export class AddOverrideTemplateFormComponent implements OnInit {
  resources: Resource[];
  loading: boolean;
  filteredOptions: Resource[];
  selectedId: number | null = null;

  @Input() set isLoading(value: boolean) {
    this.loading = value;
    if (value) {
      this.form.get('search').disable();
    } else {
      this.form.get('search').enable();
    }
  }

  @Input() set data(value: Resource[] | null) {
    if (value !== null) {
      this.resources = value;
    }
  }

  @Input() templateExists: boolean | null = false;
  @Input() searchTerm: string | null = null;
  @Output() categoryChangeEvent = new EventEmitter<number>();
  @Output() saveTemplateEvent = new EventEmitter<string>();
  @Output() searchChangeEvent = new EventEmitter<[number, number]>();
  @Output() closeDialogEvent = new EventEmitter();

  @ViewChild('input') input: ElementRef<HTMLInputElement>;

  constructor() {}

  categories = Categories;

  form = new FormGroup({
    category: new FormControl<string | null>(null),
    search: new FormControl<string | null>(null)
  });

  ngOnInit() {
    this.form.get('search').disable();
    this.form.get('category').valueChanges.subscribe(value => {
      this.clearSearchField();
      if (value !== null) {
        this.form.get('search').enable();
        this.categoryChangeEvent.emit(Number(value));
      } else {
        this.form.get('search').disable();
      }
    });

    this.form.get('search').valueChanges.subscribe(value => {
      if (this.filteredOptions !== undefined) {
        const resource = this.filteredOptions.find(f => {
          return f.name === value;
        });
        this.selectionChange(resource?.id);
      }
    });
  }

  filter(): void {
    const filterValue = this.input.nativeElement.value.toLowerCase();
    this.filteredOptions = this.resources.filter(o => o.name.toLowerCase().includes(filterValue));
  }

  selectionChange(id: number | null) {
    this.selectedId = id;
    this.searchChangeEvent.emit([this.selectedId, Number(this.form.get('category').value)]);
  }

  close() {
    this.closeDialogEvent.emit();
  }

  clearSearchField() {
    this.selectionChange(null);
    this.form.get('search').setValue(null);
  }

  save() {
    this.closeDialogEvent.emit();
    this.saveTemplateEvent.emit(this.form.get('search').value);
  }
}

import { createFeatureSelector, createSelector } from '@ngrx/store';
import { selectParams } from 'src/app/core/router/store/router.selectors';
import * as fromAppointmentTypes from '../../store/reducer/appointment-types.reducer';

export const appointmentTypesFeatureSelector = createFeatureSelector<fromAppointmentTypes.AppointmentTypeState>(
  fromAppointmentTypes.appointmentTypeFeatureKey
);

const { selectEntities, selectAll } = fromAppointmentTypes.appointmentTypeAdapter.getSelectors();

export const selectAppointmentTypesEntities = createSelector(appointmentTypesFeatureSelector, selectEntities);

export const selectAppointmentTypes = createSelector(appointmentTypesFeatureSelector, selectAll);

export const selectError = createSelector(appointmentTypesFeatureSelector, state => state.baseState.error);

export const selectIsLoading = createSelector(appointmentTypesFeatureSelector, state => state.baseState.isLoading);

export const selectPageNumber = createSelector(appointmentTypesFeatureSelector, state => state.baseState.pageNumber);

export const selectIsAtEndOfData = createSelector(
  appointmentTypesFeatureSelector,
  state => state.baseState.isAtEndOfData
);

export const selectSortDirection = createSelector(
  appointmentTypesFeatureSelector,
  state => state.baseState.sortDirection
);

export const selectSortBy = createSelector(appointmentTypesFeatureSelector, state => state.baseState.sortBy);

export const selectPageSize = createSelector(appointmentTypesFeatureSelector, state => state.baseState.pageSize);

export const selectTotalRecordCount = createSelector(
  appointmentTypesFeatureSelector,
  state => state.baseState.totalRecordCount
);

export const selectSearchTerm = createSelector(appointmentTypesFeatureSelector, state => state.baseState.searchTerm);

export const selectIsActive = createSelector(appointmentTypesFeatureSelector, state => state.baseState.isActive);

export const selectAppointmentTypeCategories = createSelector(
  appointmentTypesFeatureSelector,
  state => state.appointmentTypeCategories
);

export const selectAppointmentTypeById = (appointmentTypeId: string) =>
  createSelector(appointmentTypesFeatureSelector, state => state?.entities[appointmentTypeId]);

export const selectAppointmentType = createSelector(
  selectAppointmentTypesEntities,
  selectParams,
  (appointmentTypes, { appointmentTypeId }) => appointmentTypes[appointmentTypeId]
);

export const selectAppointmentTypeQuickPicks = createSelector(selectAppointmentTypes, appointmentTypes =>
  appointmentTypes.map(s => ({ id: s.appointmentTypeId, name: s.name, selected: false, isDisabled: false }))
);

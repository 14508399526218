import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SortDirection } from 'src/app/core/enums/sort-direction';
import { DataResponse } from 'src/app/core/models/data-response';
import { DataSort } from 'src/app/core/models/data-sort';
import { SearchParameters } from 'src/app/core/models/search-parameters';
import { UtilityService } from 'src/app/core/services/utility/utility.service';
import { environment } from 'src/environments/environment';
import { OverrideTemplate } from '../../models/override-template';

@Injectable({
  providedIn: 'root'
})
export class OverrideTemplatesService {
  constructor(private utilityService: UtilityService) {}

  endpoint = environment.scheduleApiUrl + '/OverrideTemplates';

  postSearchOverrideTemplates(
    pageNumber: number,
    sortDirection: SortDirection,
    sortBy: DataSort,
    pageSize: number | null = null,
    searchTerm: string | null,
    isActive: boolean
  ): Observable<DataResponse<OverrideTemplate[]>> {
    const url = this.endpoint + '/Search';
    const searchParams: any = new SearchParameters(pageNumber, sortDirection, sortBy, pageSize, searchTerm, isActive);
    return this.utilityService.postRequest<DataResponse<OverrideTemplate[]>>(url, searchParams, false, false, true);
  }
}

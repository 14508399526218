<div class="table-border">
  <div class="table-header">
    <div class="flex flex-2 justify-center align-center">
      <h5>Location Availabilities</h5>
    </div>
    <button mat-raised-button class="btn save-button" color="primary">Add Availability</button>
  </div>
  <div class="table-pager-container">
    <table
      mat-table
      [dataSource]="dataSource"
      matSort
      matSortDisableClear
      matSortActive="locationName"
      matSortDirection="asc"
      data-test="grdProviderLocationAvailabilities">
      <ng-container matColumnDef="locationName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="fw-600">Location</th>
        <td mat-cell *matCellDef="let data" tabindex="0">
          <div class="flex">
            {{ data.locationName }}
          </div>
        </td>
      </ng-container>
      <ng-container matColumnDef="daysOfWeek">
        <th mat-header-cell *matHeaderCellDef class="fw-600">Days of Week</th>
        <td mat-cell *matCellDef="let data" tabindex="0">
          {{ data.daysOfWeek | daysOfWeek }}
        </td>
      </ng-container>
      <ng-container matColumnDef="startTimeLocal">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="fw-600">Start Time</th>
        <td mat-cell *matCellDef="let data" tabindex="0">
          <div class="flex">
            {{ data.startTimeLocal | time }}
          </div>
        </td>
      </ng-container>
      <ng-container matColumnDef="endTimeLocal">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="fw-600">End Time</th>
        <td mat-cell *matCellDef="let data" tabindex="0">
          <div class="flex">
            {{ data.endTimeLocal | time }}
          </div>
        </td>
      </ng-container>
      <ng-container matColumnDef="startDate">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="fw-600">Start Date</th>
        <td mat-cell *matCellDef="let data" tabindex="0">
          <div class="flex">
            {{ data.startDate | date: 'M/d/yyyy' }}
          </div>
        </td>
      </ng-container>
      <ng-container matColumnDef="endDate">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="fw-600">End Date</th>
        <td mat-cell *matCellDef="let data" tabindex="0">
          <div class="flex">
            {{ data.endDate === null ? 'No End' : (data.endDate | date: 'M/d/yyyy') }}
          </div>
        </td>
      </ng-container>
      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let data" tabindex="0">
          <div class="fw-600 color-neutral text-center cursor" [matMenuTriggerFor]="menu">
            <mat-icon>more_vert</mat-icon>
          </div>
          <mat-menu #menu="matMenu" yPosition="above" xPosition="before">
            <button mat-menu-item (click)="editEvent.emit(data.providerResourceLocationAvailabilityId)">
              <p class="fw-600">Edit</p>
            </button>
          </mat-menu>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

      <tr class="mat-row" *matNoDataRow>
        <td *ngIf="!isLoading && error === null" class="mat-cell pl-20 pt-10" [attr.colspan]="displayedColumns.length">
          <p class="flex justify-center align-center mt-10 mb-10">{{ noDataMessage }}</p>
        </td>
        <td *ngIf="!isLoading && error !== null" class="mat-cell pl-20 pt-10" [attr.colspan]="displayedColumns.length">
          <p class="flex justify-center align-center mt-10 mb-10">{{ error }}</p>
        </td>
        <td *ngIf="isLoading" class="mat-cell" [attr.colspan]="displayedColumns.length">
          <div class="flex justify-center align-center mt-10 mb-10">
            <app-progress-spinner [diameter]="100"></app-progress-spinner>
          </div>
        </td>
      </tr>
    </table>
    <mat-paginator
      [disabled]="isLoading"
      [pageSizeOptions]="pageSizeOptions"
      [pageSize]="currentPageSize"
      showFirstLastButtons
      class="pt-10 pb-10 paginator"
      aria-label="Select page of data"
      data-test="pgnProviderLocationAvailabilities">
    </mat-paginator>
  </div>
</div>

import { daysOfWeek } from 'src/app/shared/data/days-of-week';
import { nonProviderResourceColors } from './non-provider-resource-colors';

const maxLengthName = 50;

export const nonProviderResourceLengthValidators = {
  maxLengthName
};

export const nonProviderResourceSettings = {
  minDateAllowed: new Date(),
  interval: 30,
  colors: nonProviderResourceColors,
  daysOfWeek: daysOfWeek
};

import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import * as baseState from 'src/app/core/models/base-state';
import { userSort } from '../../data/user-sort';
import * as usersActions from '../actions/users.actions';
import { User } from 'src/app/user/models/user';

export interface UsersState extends EntityState<User> {
  baseState: baseState.BaseState;
}

export const usersAdapter = createEntityAdapter<User>({
  selectId: user => user.userId
});

export const initialState = usersAdapter.getInitialState({
  baseState: new baseState.BaseState(userSort[0])
});

export const usersFeatureKey = 'users';

export const usersReducer = createReducer(
  initialState,

  on(usersActions.getUsers, state => {
    return {
      ...state,
      baseState: baseState.setGet(state.baseState, false)
    };
  }),

  on(usersActions.getUsersSuccess, (state, { users }) => usersAdapter.addMany(users, state)),

  on(usersActions.getUsersSuccess, (state, { pageNumber, isAtEndOfData, totalRecordCount }) => {
    return {
      ...state,
      baseState: baseState.setGetSuccess(state.baseState, pageNumber, isAtEndOfData, totalRecordCount)
    };
  }),

  on(usersActions.getUsersFailure, (state, { error }) => {
    return {
      ...state,
      baseState: baseState.setError(state.baseState, error)
    };
  }),

  on(
    usersActions.getUsers,
    usersActions.setPageSize,
    usersActions.setPageNumber,
    usersActions.getUsersSearch,
    usersActions.setSortBy,
    usersActions.setSortDirection,
    usersActions.setIsActive,
    state => usersAdapter.removeAll(state)
  ),

  on(usersActions.setPageNumber, (state, { pageNumber }) => {
    return {
      ...state,
      baseState: baseState.setPageNumber(state.baseState, pageNumber)
    };
  }),

  on(usersActions.setPageSize, (state, { pageSize }) => {
    return {
      ...state,
      baseState: baseState.setPageSize(state.baseState, pageSize)
    };
  }),

  on(usersActions.getUsersSearch, (state, { searchTerm }) => {
    return {
      ...state,
      baseState: baseState.setSearch(state.baseState, searchTerm)
    };
  }),

  on(usersActions.setSortDirection, (state, { sortDirection }) => {
    return {
      ...state,
      baseState: baseState.setSortDirection(state.baseState, sortDirection)
    };
  }),

  on(usersActions.setSortBy, (state, { sortBy }) => {
    return {
      ...state,
      baseState: baseState.setSortBy(state.baseState, sortBy)
    };
  }),

  on(usersActions.setIsActive, (state, { isActive }) => {
    return {
      ...state,
      baseState: baseState.setIsActive(state.baseState, isActive)
    };
  }),

  on(usersActions.resetFilters, state => {
    return {
      ...state,
      baseState: baseState.resetFilters(state.baseState, userSort[0])
    };
  })
);

import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { provideNativeDateAdapter } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import moment from 'moment';
import { Color } from 'src/app/core/models/colors';
import { MultiselectAutocompleteComponent } from 'src/app/shared/component/multiselect-autocomplete/multiselect-autocomplete.component';
import { ProgressSpinnerOverlayComponent } from 'src/app/shared/component/progress-spinner-overlay/progress-spinner-overlay.component';
import { ProgressSpinnerComponent } from 'src/app/shared/component/progress-spinner/progress-spinner.component';
import { formatDateWithUtcTimeOffset } from 'src/app/shared/functions/date.functions';
import { MultiSelectAutoCompleteItem } from 'src/app/shared/models/multiselect-autocomplete-item';
import { providerResourceColors } from '../../data/provider-resource-colors';
import { Person } from '../../models/person';
import { ProviderResource } from '../../models/provider-resource';
import { ProviderResourceLocationRequest } from '../../models/provider-resource-location-request';

@Component({
  selector: 'app-add-provider-resource-form',
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MultiselectAutocompleteComponent,
    ProgressSpinnerComponent,
    ProgressSpinnerOverlayComponent,
    ReactiveFormsModule
  ],
  providers: [provideNativeDateAdapter()],
  templateUrl: './add-provider-resource-form.component.html',
  styleUrl: './add-provider-resource-form.component.scss'
})
export class AddProviderResourceFormComponent {
  maxShortNameLength = 20;
  today = new Date();
  selectedLocations: MultiSelectAutoCompleteItem[] | null = null;

  form = new FormGroup({
    provider: new FormControl<number | null>(null, Validators.required),
    color: new FormControl<Color | null>(null, Validators.required),
    shortName: new FormControl<string | null>(null, [
      Validators.required,
      Validators.maxLength(this.maxShortNameLength)
    ]),
    location: new FormControl<MultiSelectAutoCompleteItem[] | null>(null, Validators.required),
    activeDates: new FormGroup({
      endDate: new FormControl<Date | null>(null),
      startDate: new FormControl<Date | null>(this.today, Validators.required)
    })
  });

  providerResource: ProviderResource | null = null;
  providerResourceColors = providerResourceColors;
  error: string[] = null;

  @Input() providers: Person[] | null = null;
  @Input() locations: MultiSelectAutoCompleteItem[] | null = null;
  @Input() set saveError(value: any) {
    if (value !== null && value !== undefined) {
      this.isSaving = false;

      if (value.filter((f: string) => f.toLowerCase().includes('short name')).length > 0) {
        this.form.controls['shortName'].setErrors({ duplicate: true });
      } else {
        this.error = value;
      }
    }
  }
  @Input() isSaving: boolean | null = false;
  @Input() modifiedByUserId: number;
  @Output() cancelAddProviderResourceEvent = new EventEmitter();
  @Output() addProviderResourceEvent = new EventEmitter<{
    providerResource: ProviderResource;
    providerResourceLocationRequest: ProviderResourceLocationRequest;
  }>();

  onMultiSelectChange = (event: any) => {
    this.form.controls['location'].patchValue(event.data);
    this.form.controls['location'].markAsDirty();
  };

  minInactiveDate() {
    const startDate = this.form.controls['activeDates'].get('startDate')?.value;

    if (startDate !== null) {
      return moment(startDate).add(1, 'days').toDate();
    } else {
      return moment(this.today).add(1, 'days').toDate();
    }
  }

  save() {
    this.form.markAllAsTouched();

    this.isSaving = true;

    if (this.form.valid) {
      const providerResource: ProviderResource = {
        personId: this.form.controls['provider'].value,
        name: this.providers.find(f => f.personId === this.form.controls['provider'].value)?.displayName,
        shortName: this.form.controls['shortName'].value,
        hexColorCode: this.form.controls['color'].value.hexCode,
        deactivateOn: formatDateWithUtcTimeOffset(this.form.get('activeDates')?.get('endDate')?.value),
        activateOn: formatDateWithUtcTimeOffset(this.form.get('activeDates')?.get('startDate')?.value),
        modifiedByUserId: this.modifiedByUserId
      };

      const providerResourceLocationRequest: ProviderResourceLocationRequest = {
        providerResourceId: 0,
        locationIds: this.form.controls['location'].value.map(m => m.id),
        modifiedByUserId: this.modifiedByUserId
      };

      this.addProviderResourceEvent.emit({ providerResource, providerResourceLocationRequest });
    } else {
      this.isSaving = false;
    }
  }
}

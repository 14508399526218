import moment from 'moment';
import 'moment-timezone';

export function isFutureDate(dateStr: string | null) {
  const today = new Date();
  if (dateStr !== null) {
    dateStr += ' 12:00:00';
    const date = new Date(dateStr);

    if (date <= today) {
      return false;
    } else {
      return true;
    }
  } else return false;
}

export function formatDateWithoutTime(date: Date | null) {
  if (date === null) {
    return null;
  }

  return moment(date).format('yyyy-MM-DD');
}

export function formatDateWithUtcTimeOffset(date: Date | null) {
  if (date === null) {
    return null;
  }

  const timeZone = moment.tz.guess(); //determine user timezone
  const localTime = moment.tz(timeZone).format('HH:mm:ss'); //determine user time
  const dateTimeString = `${moment(date).utc().format('yyyy-MM-DD')}T${localTime}`;

  return moment.tz(dateTimeString, timeZone).format('YYYY-MM-DDTHH:mm:ssZ');
}

import { SortDirection } from '../enums/sort-direction';
import { DataSort } from './data-sort';

const defaultPageSize = 10;

export class BaseState {
  isLoading?: boolean = false;
  error?: string[] | null = null;
  sortDirection?: SortDirection = SortDirection.Ascending;
  isAtEndOfData?: boolean = false;
  sortBy?: DataSort;
  pageNumber?: number = 1;
  pageSize?: number = defaultPageSize;
  totalRecordCount?: number = 0;
  searchTerm?: string | null = null;
  isActive?: boolean | null = true;

  constructor(sortBy: DataSort | null) {
    this.sortBy = sortBy;
  }
}

export function setGetSuccess(
  baseState: BaseState,
  pageNumber: number,
  isAtEndOfData: boolean,
  totalRecordCount: number
): BaseState {
  const newState = structuredClone(baseState);
  newState.isLoading = false;
  newState.pageNumber = pageNumber;
  newState.isAtEndOfData = isAtEndOfData;
  newState.totalRecordCount = totalRecordCount;

  return newState;
}

export function setGet(baseState: BaseState, refreshFilters: boolean = false): BaseState {
  const newState = structuredClone(baseState);
  newState.isLoading = true;

  if (refreshFilters) {
    newState.pageNumber = 1;
    newState.pageSize = defaultPageSize;
  }
  return newState;
}

export function setIsLoading(baseState, isLoading: boolean): BaseState {
  const newState = structuredClone(baseState);
  newState.isLoading = isLoading;
  newState.error = null;

  return newState;
}

export function setError(baseState: BaseState, error: any): BaseState {
  const newState = structuredClone(baseState);
  newState.isLoading = false;
  newState.error = error;

  return newState;
}

export function setPageNumber(baseState: BaseState, pageNumber: number): BaseState {
  const newState = structuredClone(baseState);
  newState.isLoading = true;
  newState.pageNumber = pageNumber;

  return newState;
}

export function setPageSize(baseState: BaseState, pageSize: number, resetSortDirection: boolean = false): BaseState {
  const newState = structuredClone(baseState);
  newState.isLoading = true;
  newState.pageNumber = 1;
  newState.pageSize = pageSize;
  if (resetSortDirection) newState.sortDirection = SortDirection.Ascending;

  return newState;
}

export function setSortDirection(baseState: BaseState, sortDirection: SortDirection): BaseState {
  const newState = structuredClone(baseState);
  newState.isLoading = true;
  newState.pageNumber = 1;
  newState.sortDirection = sortDirection;

  return newState;
}

export function setSortBy(baseState: BaseState, sortBy: DataSort): BaseState {
  const newState = structuredClone(baseState);
  newState.isLoading = true;
  newState.pageNumber = 1;
  newState.sortBy = sortBy;

  return newState;
}

export function setSearch(baseState: BaseState, searchTerm: string | null): BaseState {
  const newState = structuredClone(baseState);
  newState.isLoading = true;
  newState.pageNumber = 1;
  newState.searchTerm = searchTerm;

  return newState;
}

export function setIsActive(baseState: BaseState, isActive: boolean | null): BaseState {
  const newState = structuredClone(baseState);
  newState.isLoading = true;
  newState.pageNumber = 1;
  newState.isActive = isActive;

  return newState;
}

export function resetFilters(baseState: BaseState, sortBy: DataSort): BaseState {
  const newState = structuredClone(baseState);
  newState.isActive = true;
  newState.searchTerm = null;
  newState.pageNumber = 1;
  newState.pageSize = defaultPageSize;
  newState.sortBy = sortBy;
  newState.sortDirection = SortDirection.Ascending;

  return newState;
}

export function setUpdate(baseState: BaseState): BaseState {
  const newState = structuredClone(baseState);
  newState.isLoading = true;

  return newState;
}
//TODO: TECHDEBT Need Tests

import { Component, inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-manage-overrides',
  standalone: true,
  imports: [],
  templateUrl: './manage-overrides.component.html',
  styleUrl: './manage-overrides.component.scss'
})
export class ManageOverridesComponent {
  private activatedRoute = inject(ActivatedRoute);
  templateName = this.activatedRoute.snapshot.params['templateName'];
}

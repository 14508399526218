import { createEntityAdapter, EntityState, Update } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import * as baseState from 'src/app/core/models/base-state';
import { BaseState } from 'src/app/core/models/base-state';
import { nonProviderResourceSort } from '../../data/non-provider-resource-sort';
import { NonProviderResource } from '../../models/non-provider-resource';
import { NonProviderResourceType } from '../../models/non-provider-resource-type';
import * as nonProviderResourceActions from '../actions/non-provider-resources.actions';

export interface NonProviderResourcesState extends EntityState<NonProviderResource> {
  baseState: baseState.BaseState;
  nonProviderResourceTypes: NonProviderResourceType[] | null;
}

export const nonProviderResourcesAdapter = createEntityAdapter<NonProviderResource>({
  selectId: nonProviderResource => nonProviderResource.nonProviderResourceId
});

export const initialState = nonProviderResourcesAdapter.getInitialState({
  baseState: new BaseState(nonProviderResourceSort[0]),
  nonProviderResourceTypes: null
});

export const nonProviderResourcesFeatureKey = 'non-provider-resources';

export const nonProviderResourcesReducer = createReducer(
  initialState,
  on(nonProviderResourceActions.getNonProviderResources, state => {
    return {
      ...state,
      baseState: baseState.setGet(state.baseState, false)
    };
  }),

  on(nonProviderResourceActions.getNonProviderResourcesSuccess, (state, { nonProviderResources }) =>
    nonProviderResourcesAdapter.addMany(nonProviderResources, state)
  ),

  on(
    nonProviderResourceActions.getNonProviderResourcesSuccess,
    (state, { pageNumber, isAtEndOfData, totalRecordCount }) => {
      return {
        ...state,
        baseState: baseState.setGetSuccess(state.baseState, pageNumber, isAtEndOfData, totalRecordCount)
      };
    }
  ),

  on(nonProviderResourceActions.getNonProviderResourcesFailure, (state, { error }) => {
    return {
      ...state,
      baseState: baseState.setError(state.baseState, error)
    };
  }),

  on(
    nonProviderResourceActions.getNonProviderResources,
    nonProviderResourceActions.setPageSize,
    nonProviderResourceActions.setPageNumber,
    nonProviderResourceActions.getNonProviderResourcesSearch,
    nonProviderResourceActions.setSortBy,
    nonProviderResourceActions.setSortDirection,
    nonProviderResourceActions.setIsActive,
    state => nonProviderResourcesAdapter.removeAll(state)
  ),

  on(nonProviderResourceActions.setPageNumber, (state, { pageNumber }) => {
    return {
      ...state,
      baseState: baseState.setPageNumber(state.baseState, pageNumber)
    };
  }),

  on(nonProviderResourceActions.setPageSize, (state, { pageSize }) => {
    return {
      ...state,
      baseState: baseState.setPageSize(state.baseState, pageSize)
    };
  }),

  on(nonProviderResourceActions.getNonProviderResourcesSearch, (state, { searchTerm }) => {
    return {
      ...state,
      baseState: baseState.setSearch(state.baseState, searchTerm)
    };
  }),

  on(nonProviderResourceActions.setSortDirection, (state, { sortDirection }) => {
    return {
      ...state,
      baseState: baseState.setSortDirection(state.baseState, sortDirection)
    };
  }),

  on(nonProviderResourceActions.setSortBy, (state, { sortBy }) => {
    return {
      ...state,
      baseState: baseState.setSortBy(state.baseState, sortBy)
    };
  }),

  on(nonProviderResourceActions.setIsActive, (state, { isActive }) => {
    return {
      ...state,
      baseState: baseState.setIsActive(state.baseState, isActive)
    };
  }),

  on(nonProviderResourceActions.resetFilters, state => {
    return {
      ...state,
      baseState: baseState.resetFilters(state.baseState, nonProviderResourceSort[0])
    };
  }),

  on(nonProviderResourceActions.updateNonProviderResource, state => {
    return {
      ...state,
      baseState: baseState.setUpdate(state.baseState)
    };
  }),

  on(
    nonProviderResourceActions.addNonProviderResourceSuccess,
    nonProviderResourceActions.updateNonProviderResourceSuccess,
    state => {
      return {
        ...state,
        baseState: baseState.setError(state.baseState, null)
      };
    }
  ),

  on(
    nonProviderResourceActions.addNonProviderResourceFailure,
    nonProviderResourceActions.updateNonProviderResourceFailure,
    (state, { error }) => {
      return {
        ...state,
        baseState: baseState.setError(state.baseState, error)
      };
    }
  ),

  on(nonProviderResourceActions.getNonProviderResourceTypes, state => {
    return {
      ...state,
      nonProviderResourceTypes: null
    };
  }),

  on(nonProviderResourceActions.getNonProviderResourceTypesSuccess, (state, { nonProviderResourceTypes }) => {
    return {
      ...state,
      nonProviderResourceTypes
    };
  }),

  on(nonProviderResourceActions.getNonProviderResourceTypesFailure, (state, { error }) => {
    return {
      ...state,
      baseState: baseState.setError(state.baseState, error)
    };
  }),

  on(nonProviderResourceActions.getNonProviderResourceByIdSuccess, (state, { nonProviderResource }) => {
    const i = state.ids.findIndex((fi: any) => fi == nonProviderResource.nonProviderResourceId);
    if (i < 0) return nonProviderResourcesAdapter.addOne(nonProviderResource, state);

    const updatedNonProviderResource: Update<NonProviderResource> = {
      id: nonProviderResource.nonProviderResourceId,
      changes: {
        ...nonProviderResource
      }
    };

    return nonProviderResourcesAdapter.updateOne(updatedNonProviderResource, state);
  }),

  on(nonProviderResourceActions.getNonProviderResourceById, state => {
    return {
      ...state,
      baseState: baseState.setError(state.baseState, null)
    };
  }),

  on(
    nonProviderResourceActions.getNonProviderResourceByIdFailure,
    nonProviderResourceActions.getNonProviderResourceAvailabilitiesByNonProviderResourceIdFailure,
    (state, { error }) => {
      return {
        ...state,
        baseState: baseState.setError(state.baseState, error)
      };
    }
  ),

  on(
    nonProviderResourceActions.getNonProviderResourceAvailabilitiesByNonProviderResourceIdSuccess,
    (state, { nonProviderResourceId, nonProviderResourceAvailabilities }) => {
      const updatedNonProviderResource: Update<NonProviderResource> = {
        id: nonProviderResourceId,
        changes: {
          nonProviderResourceAvailabilities: {
            dayOfWeekAvailabilities:
              nonProviderResourceAvailabilities.length === 0 ? null : nonProviderResourceAvailabilities,
            isDirty: false
          }
        }
      };

      return nonProviderResourcesAdapter.updateOne(updatedNonProviderResource, state);
    }
  ),

  on(nonProviderResourceActions.getInactivationCheck, state => {
    return {
      ...state,
      baseState: baseState.setIsLoading(state.baseState, true),
      nonProviderResourceTypesInactivationCheck: null
    };
  }),

  on(nonProviderResourceActions.getInactivationCheckSuccess, (state, { nonProviderResourceInactivationCheck }) => {
    return {
      ...state,
      nonProviderResourceInactivationCheck,
      baseState: baseState.setIsLoading(state.baseState, false)
    };
  }),

  on(nonProviderResourceActions.getInactivationCheckFailure, (state, { error }) => {
    return {
      ...state,
      baseState: baseState.setError(state.baseState, error)
    };
  })
);

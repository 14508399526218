import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import { NGXLogger } from 'ngx-logger';
import { catchError, map, mergeMap, of } from 'rxjs';
import { UsersService } from '../../service/users/users.service';
import * as usersActions from '../actions/users.actions';
import * as usersSelector from '../selectors/users.selectors';

@Injectable()
export class UsersEffects {
  constructor(
    private actions$: Actions,
    private usersService: UsersService,
    private store: Store,
    private logger: NGXLogger,
    private router: Router
  ) {}

  getUsers$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        usersActions.getUsers,
        usersActions.setPageNumber,
        usersActions.setPageSize,
        usersActions.getUsersSearch,
        usersActions.setSortBy,
        usersActions.setIsActive
      ),
      concatLatestFrom(() => [
        this.store.select(usersSelector.selectPageNumber),
        this.store.select(usersSelector.selectSortDirection),
        this.store.select(usersSelector.selectSortBy),
        this.store.select(usersSelector.selectPageSize),
        this.store.select(usersSelector.selectSearchTerm),
        this.store.select(usersSelector.selectIsActive)
      ]),
      mergeMap(([, pageNumber, sortDirection, sortBy, pageSize, searchTerm, isActive]) => {
        return this.usersService
          .postSearchUsers(pageNumber, sortDirection, sortBy, pageSize, searchTerm, isActive)
          .pipe(
            map(response => {
              let isAtEndOfData = false;
              const totalRecordCount = response.totalRecordCount;
              let currentPage = pageNumber;
              let users = response.result;

              if (users == null || users.length == 0) {
                users = [];
                currentPage--;
                isAtEndOfData = true;
              }

              return usersActions.getUsersSuccess({
                users,
                pageNumber: currentPage <= 0 ? 1 : currentPage,
                isAtEndOfData,
                totalRecordCount
              });
            }),
            catchError((err: HttpErrorResponse) => {
              this.logger.error(err);
              return of(usersActions.getUsersFailure({ error: err.error?.errors }));
            })
          );
      })
    );
  });
}

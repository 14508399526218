import { createAction, props } from '@ngrx/store';
import { DataSort } from 'src/app/core/models/data-sort';
import { Availability } from '../../../shared/models/availability';
import { ApptLocation } from '../../models/location';
import { LocationAppointmentType } from '../../models/location-appointment-type';
import { LocationInactivationCheck } from '../../models/location-inactivation-check';

export const getLocations = createAction('[Locations] Get Locations');

export const getLocationsSuccess = createAction(
  '[Locations] Get Locations Success',
  props<{
    locations: ApptLocation[];
    pageNumber: number;
    isAtEndOfData: boolean;
    totalRecordCount: number;
  }>()
);

export const getLocationsFailure = createAction('[Locations] Get Locations Failure', props<{ error: string[] }>());

export const getLocationsSearch = createAction('[Locations] Get Locations Search', props<{ searchTerm: any }>());

export const setPageNumber = createAction('[Locations] Set Page Number', props<{ pageNumber: number }>());

export const setPageSize = createAction('[Locations] Set Page Size', props<{ pageSize: number }>());

export const setSortDirection = createAction('[Locations] Set Sort Direction', props<{ sortDirection: number }>());

export const setSortBy = createAction('[Locations] Set Sort By', props<{ sortBy: DataSort }>());

export const setIsActive = createAction('[Locations] Set Is Active', props<{ isActive: boolean }>());

export const navigateToAddLocation = createAction('[Locations] Navigate to Add Location');

export const navigateToLocationsGrid = createAction('[Locations] Navigate to Locations Grid');

export const resetFilters = createAction('[Locations] Reset Filters');

export const addLocation = createAction('[Locations] Add Location', props<{ location: ApptLocation }>());

export const addLocationSuccess = createAction('[Locations] Add Location Success');

export const addLocationFailure = createAction('[Locations] Add Location Failure', props<{ error: string[] }>());

export const updateLocation = createAction('[Locations] Update Location', props<{ location: ApptLocation }>());

export const updateLocationSuccess = createAction('[Locations] Update Location Success');

export const updateLocationFailure = createAction('[Locations] Update Location Failure', props<{ error: string[] }>());

export const getLocationById = createAction('[Locations] Get Location By Id');

export const getLocationByIdSuccess = createAction(
  '[Locations] Get Location By Id Success',
  props<{
    location: ApptLocation;
  }>()
);

export const getLocationByIdFailure = createAction(
  '[Locations] Get Location By Id Failure',
  props<{ error: string[] }>()
);

export const getLocationAppointmentTypesByLocationId = createAction(
  '[Locations] Get Location Appointment Types By Location Id',
  props<{ locationId: number }>()
);

export const getLocationAppointmentTypesByLocationIdSuccess = createAction(
  '[Locations] Get Location Appointment Types By Location Id Success',
  props<{
    locationId: number;
    locationAppointmentTypes: LocationAppointmentType[];
  }>()
);

export const getLocationAppointmentTypesByLocationIdFailure = createAction(
  '[Locations] Get Location Appointment Types By Location Id Failure',
  props<{ error: string[] }>()
);

export const updateLocationAppointmentTypes = createAction(
  '[Locations] Update Location Appointment Types',
  props<{ locationId: number; locationAppointmentTypes: { appointmentTypeIds: number[]; isDirty: boolean } }>()
);

export const updateLocationAppointmentTypesSuccess = createAction(
  '[Locations] Update Location Appointment Types Success'
);

export const updateLocationAppointmentTypesFailure = createAction(
  '[Locations] Update Location Appointment Types Failure',
  props<{ error: string[] }>()
);

export const getLocationAvailabilitiesByLocationId = createAction(
  '[Locations] Get Location Appointment Types By Location Id',
  props<{ locationId: number }>()
);

export const getLocationAvailabilitiesByLocationIdSuccess = createAction(
  '[Locations] Get Location Availabilities By Location Id Success',
  props<{
    locationId: number;
    locationAvailabilities: Availability[];
  }>()
);

export const getLocationAvailabilitiesByLocationIdFailure = createAction(
  '[Locations] Get Location Availabilities By Location Id Failure',
  props<{ error: string[] }>()
);

export const updateLocationAvailabilities = createAction(
  '[Locations] Update Location Availabilities',
  props<{
    locationId: number;
    locationAvailabilities: { dayOfWeekAvailabilities: Availability[]; isDirty: boolean };
  }>()
);

export const updateLocationAvailabilitiesSuccess = createAction('[Locations] Update Location Availabilities Success');

export const updateLocationAvailabilitiesFailure = createAction(
  '[Locations] Update Location Availabilities Failure',
  props<{ error: string[] }>()
);

export const getInactivationCheck = createAction(
  '[Locations] Get Inactivation Check',
  props<{ location: ApptLocation }>()
);

export const getInactivationCheckSuccess = createAction(
  '[Locations] Get Inactivation Check Success',
  props<{ locationInactivationCheck: LocationInactivationCheck; location: ApptLocation }>()
);

export const getInactivationCheckFailure = createAction(
  '[Locations] Get Inactivation Check Failure',
  props<{ error: any }>()
);

export const cancelUpdate = createAction('[Locations] Cancel Update');

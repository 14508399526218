<div class="flex justify-center align-center mr-20 ml-20 mt-10 mb-10">
  <h3 class="align-center flex-2 text-center">Add New Template</h3>
  <span
    id="btnClose"
    class="material-symbols-outlined cursor fs-32 color-neutral"
    tabindex="0"
    (click)="close()"
    (keyup)="close()"
    >close</span
  >
</div>

@if (templateExists) {
  <div class="exists-error color-warn fs-20 w-100p text-center pt-15 pb-15">
    <div>Template already exists for this resource</div>
  </div>
}

<form [formGroup]="form" (submit)="save()" class="mt-50">
  <div class="form-container flex flex-col align-center mb-100 gap-20">
    <mat-form-field id="category" appearance="outline" subscriptSizing="dynamic" floatLabel="always" class="w-60p">
      <mat-label>Category</mat-label>
      <mat-select formControlName="category" class="type-select">
        <mat-option [value]="categories.NonProvider"> Non-Provider </mat-option>
        <mat-option [value]="categories.Provider"> Provider </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field id="search" appearance="outline" subscriptSizing="dynamic" floatLabel="always" class="w-60p">
      <mat-label>Search</mat-label>
      <input
        matInput
        type="text"
        #input
        id="searchInput"
        formControlName="search"
        [matAutocomplete]="auto"
        (input)="filter()"
        (focus)="filter()" />
      @if (this.form.get('search').value !== '' && this.form.get('search').value !== null) {
        <button
          id="clearBtn"
          matSuffix
          mat-icon-button
          aria-label="Clear"
          (click)="clearSearchField()"
          data-test="btnClearSearch">
          <mat-icon>close</mat-icon>
        </button>
      }
      <mat-autocomplete #auto="matAutocomplete">
        @if (filteredOptions !== null && filteredOptions !== undefined && filteredOptions.length > 0) {
          @for (option of filteredOptions; track option) {
            <mat-option [value]="option.name">{{ option.name }}</mat-option>
          }
        } @else {
          <mat-option [disabled]="true"><i>No results matching search</i></mat-option>
        }
      </mat-autocomplete>
    </mat-form-field>
  </div>

  <div class="btn-container flex justify-end align-center mb-20">
    <div>
      <button
        class="btn warn-button"
        id="btnCancel"
        color="warn"
        mat-stroked-button
        type="button"
        data-test="btnCancel"
        (click)="close()">
        Cancel
      </button>
    </div>

    <div class="ml-10">
      <button
        class="btn primary-solid-button"
        id="btnSubmit"
        color="primary"
        mat-raised-button
        type="submit"
        data-test="btnSave"
        [disabled]="templateExists">
        Save
      </button>
    </div>
  </div>
</form>

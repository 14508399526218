import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { provideNativeDateAdapter } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import moment from 'moment';
import { Color } from 'src/app/core/models/colors';
import { MultiselectAutocompleteComponent } from 'src/app/shared/component/multiselect-autocomplete/multiselect-autocomplete.component';
import { ProgressSpinnerOverlayComponent } from 'src/app/shared/component/progress-spinner-overlay/progress-spinner-overlay.component';
import { ProgressSpinnerComponent } from 'src/app/shared/component/progress-spinner/progress-spinner.component';
import { formatDateWithUtcTimeOffset } from 'src/app/shared/functions/date.functions';
import { MultiSelectAutoCompleteItem } from 'src/app/shared/models/multiselect-autocomplete-item';
import { providerResourceColors } from '../../data/provider-resource-colors';
import { ProviderResource } from '../../models/provider-resource';
import { ProviderResourceLocationRequest } from '../../models/provider-resource-location-request';

@Component({
  selector: 'app-edit-provider-resource-form',
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MultiselectAutocompleteComponent,
    ProgressSpinnerComponent,
    ProgressSpinnerOverlayComponent,
    ReactiveFormsModule
  ],
  providers: [provideNativeDateAdapter()],
  templateUrl: './edit-provider-resource-form.component.html',
  styleUrl: './edit-provider-resource-form.component.scss'
})
export class EditProviderResourceFormComponent {
  @Input() set selectedProviderResource(value: ProviderResource | null) {
    if (value !== null && value !== undefined) {
      this.form.reset();
      this.isSaving = false;
      this.providerResource = value;
      this.form.controls['profile'].controls['shortName'].patchValue(value.shortName);

      const chosenColor = this.providerResourceColors.find(el => el.hexCode === value.hexColorCode);
      this.form.controls['profile'].controls['color'].patchValue(chosenColor);

      if (
        value.providerResourceLocations !== undefined &&
        value.providerResourceLocations !== null &&
        value.providerResourceLocations.length > 0
      ) {
        this.selectedLocations = this.locations.filter(ft =>
          value.providerResourceLocations.find(fd => ft.id === fd.locationId)
        );

        this.selectedLocations.forEach(
          fe =>
            (fe.isDisabled =
              value.providerResourceLocationAvailabilities?.filter(f => f.locationId === fe.id).length > 0)
        );
      }

      if (value.activateOn !== null && value.activateOn !== undefined) {
        const strDate = value.activateOn + ' 12:00:00Z';
        this.form.controls['profile'].get('activeDate')?.patchValue(new Date(strDate));
        if (moment(strDate) < moment().startOf('day')) {
          this.form.controls['profile'].get('activeDate')?.disable();
        }
      }

      if (value.deactivateOn !== null && value.deactivateOn !== undefined) {
        const strDate = value.deactivateOn + ' 12:00:00Z';
        this.form.controls['profile'].get('inactiveDate')?.patchValue(new Date(strDate));
      }
    }
  }
  @Input() locations: MultiSelectAutoCompleteItem[] | null = null;
  @Input() set saveError(value: any) {
    if (value !== null && value !== undefined) {
      this.isSaving = false;

      if (value.filter((f: string) => f.toLowerCase().includes('short name')).length > 0) {
        this.form.controls['profile'].controls['shortName'].setErrors({ duplicate: true });
      } else {
        this.error = value;
      }
    }
  }
  @Input() modifiedByUserId: number;
  @Output() editProviderResourceEvent = new EventEmitter<ProviderResource>();
  @Output() editProviderResourceLocationsEvent = new EventEmitter<ProviderResourceLocationRequest>();
  @Output() showWarningDialogEvent = new EventEmitter<string>();

  isSaving: boolean = false;
  providerResource: ProviderResource | null = null;
  maxShortNameLength = 20;
  today = new Date();
  selectedLocations: MultiSelectAutoCompleteItem[] | null = null;
  providerResourceColors = providerResourceColors;
  error: string[] = null;

  form = new FormGroup({
    profile: new FormGroup({
      color: new FormControl<Color | null>(null, Validators.required),
      shortName: new FormControl<string | null>(null, [
        Validators.required,
        Validators.maxLength(this.maxShortNameLength)
      ]),
      activeDate: new FormControl<Date | null>(this.today, Validators.required),
      inactiveDate: new FormControl<Date | null>(null)
    }),
    location: new FormControl<MultiSelectAutoCompleteItem[] | null>(null, Validators.required)
  });

  onMultiSelectChange = (event: any) => {
    this.form.controls['location'].patchValue(event.data);
    this.form.controls['location'].markAsDirty();
  };

  minInactiveDate() {
    const startDate = this.form.controls['profile'].get('activeDate')?.value;

    if (startDate !== null && startDate > this.today) {
      return moment(startDate).add(1, 'days').toDate();
    } else {
      return this.today;
    }
  }

  removeLocation(event: any) {
    const hasAvailability =
      this.providerResource.providerResourceLocationAvailabilities?.filter(f => f.locationId === event.id).length > 0;

    if (hasAvailability) {
      const locationName = this.providerResource.providerResourceLocations.find(
        f => f.locationId === event.id
      )?.locationName;

      this.showWarningDialogEvent.emit(
        `${locationName} cannot be removed. Please edit the availabilities inside the table corresponding to this location. Set the end date to today's date. Then, you may remove the location.`
      );
    } else {
      const i = this.selectedLocations.indexOf(event, 0);
      this.selectedLocations.splice(i, 1);
      this.form.controls['location'].patchValue(this.selectedLocations);
      this.save();
    }
  }

  save() {
    this.isSaving = true;

    if (this.form.valid) {
      if (this.form.controls['profile'].dirty) {
        this.selectedLocations = null;
        const providerResource: ProviderResource = {
          providerResourceId: this.providerResource.providerResourceId,
          personId: this.providerResource.personId,
          name: this.providerResource.name,
          shortName: this.form.controls['profile'].controls['shortName'].value,
          hexColorCode: this.form.controls['profile'].controls['color'].value.hexCode,
          activateOn: formatDateWithUtcTimeOffset(this.form.controls['profile'].get('activeDate')?.value),
          deactivateOn: formatDateWithUtcTimeOffset(this.form.controls['profile'].get('inactiveDate')?.value),
          modifiedByUserId: this.modifiedByUserId,
          version: this.providerResource.version
        };

        this.editProviderResourceEvent.emit(providerResource);
      } else if (this.form.controls['location'].dirty) {
        const providerResourceLocationRequest: ProviderResourceLocationRequest = {
          providerResourceId: this.providerResource.providerResourceId,
          locationIds: this.form.controls['location'].value.map(m => m.id),
          modifiedByUserId: this.modifiedByUserId
        };

        this.editProviderResourceLocationsEvent.emit(providerResourceLocationRequest);
      } else {
        this.isSaving = false;
      }
    } else {
      this.isSaving = false;
    }
  }
}

<ng-container>
  <app-progress-spinner
    id="spinnerEditProviderResource"
    *ngIf="locations === null || modifiedByUserId === null; else editProviderResourceForm">
  </app-progress-spinner>

  <ng-template #editProviderResourceForm>
    <div id="form" class="form-container">
      <form [formGroup]="form" class="mt-30">
        @if (error !== null && isSaving === false) {
          <div id="saveError" class="ml-20 mr-20 mb-20 mt-15 flex justify-center">
            <p>{{ error[0] }}</p>
          </div>
        }
        <div class="mr-20 ml-20 mt-20 flex gap-20" id="form-container">
          <div class="flex flex-col gap-25 flex-2">
            <mat-form-field
              class="flex"
              appearance="outline"
              floatLabel="always"
              subscriptSizing="dynamic"
              formGroupName="profile">
              <mat-label>Short Name</mat-label>
              <input matInput id="shortName" formControlName="shortName" data-test="iptShortName" (blur)="save()" />
              @if (form.controls['profile'].controls['shortName'].hasError('required')) {
                <mat-error id="shortNameRequired">Short name required</mat-error>
              } @else if (form.controls['profile'].controls['shortName'].hasError('maxlength')) {
                <mat-error id="shortNameMax">Must not exceed {{ maxShortNameLength }} characters</mat-error>
              } @else if (form.controls['profile'].controls['shortName'].hasError('duplicate')) {
                <mat-error id="shortNameDuplicateError">Short name already exists</mat-error>
              }
            </mat-form-field>

            <div class="flex flex-col">
              <app-multiselect-autocomplete
                [multiSelectLabel]="'Locations'"
                [isMultiSelectRequired]="true"
                key="options"
                [data]="locations"
                [selectedData]="selectedLocations"
                [hasAutoSave]="true"
                (multiSelectResult)="onMultiSelectChange($event)"
                (removeChipEvent)="removeLocation($event)"
                (saveEvent)="save()">
              </app-multiselect-autocomplete>
              @if (form.controls['location'].touched && form.controls['location'].hasError('required')) {
                <mat-error id="locationRequired" class="fs-14">Location required</mat-error>
              }
            </div>

            <mat-form-field
              class="flex"
              appearance="outline"
              floatLabel="always"
              subscriptSizing="dynamic"
              formGroupName="profile">
              <mat-label>Color</mat-label>
              <mat-select
                hideSingleSelectionIndicator
                formControlName="color"
                data-test="HexColorCode"
                (selectionChange)="save()">
                <mat-select-trigger>
                  <div class="flex justify-between">
                    {{ form.controls['profile'].controls['color'].value?.name }}
                    <div
                      class="color-pill ml-15 mr-10"
                      [style]="'background-color: ' + form.controls['profile'].controls['color'].value?.hexCode"></div>
                  </div>
                </mat-select-trigger>
                @for (color of providerResourceColors; track color) {
                  <mat-option [value]="color">
                    <div class="flex justify-start">
                      <div class="w-20x h-20x mr-8" [style]="'background-color: ' + color.hexCode"></div>
                      {{ color.name }}
                    </div>
                  </mat-option>
                }
              </mat-select>
              @if (form.controls['profile'].controls['color'].hasError('required')) {
                <mat-error id="colorRequired">Color required</mat-error>
              }
            </mat-form-field>

            <mat-form-field
              class="flex date-input"
              appearance="outline"
              floatLabel="always"
              subscriptSizing="dynamic"
              formGroupName="profile">
              <mat-label>Active Date</mat-label>
              <input
                matInput
                [matDatepicker]="picker"
                id="activeDate"
                formControlName="activeDate"
                data-test="dteActivateOn"
                [min]="today"
                (dateChange)="save()" />
              <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>
              @if (form.controls['profile'].get('activeDate')?.hasError('required')) {
                <mat-error id="activeRequiredError">Active date is required</mat-error>
              } @else if (form.controls['profile'].get('activeDate')?.hasError('matDatepickerMin')) {
                <mat-error id="activeDateInvalid">Date cannot be in the past</mat-error>
              }
            </mat-form-field>

            <mat-form-field
              class="flex date-input"
              appearance="outline"
              floatLabel="always"
              subscriptSizing="dynamic"
              formGroupName="profile">
              <mat-label>Inactive Date</mat-label>
              <input
                matInput
                [matDatepicker]="inactivePicker"
                #inactiveDate
                id="inactiveDate"
                formControlName="inactiveDate"
                data-test="dteDeactivateOn"
                [min]="minInactiveDate()"
                (dateChange)="save()" />
              <mat-datepicker-toggle matIconSuffix [for]="inactivePicker"></mat-datepicker-toggle>
              <mat-datepicker #inactivePicker></mat-datepicker>
              @if (form.controls['profile'].get('inactiveDate')?.hasError('matDatepickerMin')) {
                <mat-error id="invalidInactiveDateError"
                  >Date must be on or after {{ minInactiveDate() | date: 'M/d/yyyy' }}</mat-error
                >
              }
            </mat-form-field>
          </div>
        </div>
      </form>
    </div>

    <app-progress-spinner-overlay
      id="spinnerOverlayEditProviderResource"
      [displayProgressSpinner]="isSaving"
      data-test="spinnerEditProviderResource"></app-progress-spinner-overlay>
  </ng-template>
</ng-container>

import { Routes } from '@angular/router';
import { AddAppointmentTypeComponent } from './appointment-types/feature/add-appointment-type/add-appointment-type.component';
import { AppointmentTypesComponent } from './appointment-types/feature/appointment-types/appointment-types.component';
import { UpdateAppointmentTypeComponent } from './appointment-types/feature/update-appointment-type/update-appointment-type.component';
import { authGuard } from './core/guards/auth.guard';
import { ErrorPageComponent } from './error-page/error-page.component';
import { HomeComponent } from './home/home.component';
import { AddLocationComponent } from './locations/feature/add-location/add-location.component';
import { LocationsComponent } from './locations/feature/locations/locations.component';
import { UpdateLocationComponent } from './locations/feature/update-location/update-location.component';
import { AddNonProviderResourceComponent } from './non-provider-resources/feature/add-non-provider-resource/add-non-provider-resource.component';
import { NonProviderResourcesComponent } from './non-provider-resources/feature/non-provider-resources/non-provider-resources.component';
import { UpdateNonProviderResourceComponent } from './non-provider-resources/feature/update-non-provider-resource/update-non-provider-resource.component';
import { OverrideTemplatesComponent } from './override-templates/feature/override-templates/override-templates.component';
import { ManageOverridesComponent } from './override-templates/ui/manage-overrides/manage-overrides.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { AddProviderResourceComponent } from './provider-resources/feature/add-provider-resource/add-provider-resource.component';
import { EditProviderResourceComponent } from './provider-resources/feature/edit-provider-resource/edit-provider-resource.component';
import { ProviderResourcesComponent } from './provider-resources/feature/provider-resources/provider-resources.component';
import { LayoutWithNavComponent } from './shared/component/layout/layout-with-nav/layout-with-nav.component';
import { UsersComponent } from './users/feature/users/users.component';

export const routes: Routes = [
  {
    path: '',
    component: LayoutWithNavComponent,
    children: [
      {
        path: '',
        component: HomeComponent,
        canActivate: [authGuard]
      },
      {
        path: 'appointment-types',
        component: AppointmentTypesComponent,
        canActivate: [authGuard]
      },
      {
        path: 'appointment-types/add-appointment-type',
        component: AddAppointmentTypeComponent,
        canActivate: [authGuard]
      },
      {
        path: 'appointment-types/update-appointment-type/:appointmentTypeId',
        component: UpdateAppointmentTypeComponent,
        canActivate: [authGuard]
      },
      {
        path: 'locations',
        component: LocationsComponent,
        canActivate: [authGuard]
      },
      {
        path: 'locations/add-location',
        component: AddLocationComponent,
        canActivate: [authGuard]
      },
      {
        path: 'locations/update-location/:locationId',
        component: UpdateLocationComponent,
        canActivate: [authGuard]
      },
      {
        path: 'non-provider-resources',
        component: NonProviderResourcesComponent,
        canActivate: [authGuard]
      },
      {
        path: 'non-provider-resources/add-non-provider-resource',
        component: AddNonProviderResourceComponent,
        canActivate: [authGuard]
      },
      {
        path: 'non-provider-resources/update-non-provider-resource/:nonProviderResourceId',
        component: UpdateNonProviderResourceComponent,
        canActivate: [authGuard]
      },
      {
        path: 'provider-resources',
        component: ProviderResourcesComponent,
        canActivate: [authGuard]
      },
      {
        path: 'provider-resources/add-provider-resource',
        component: AddProviderResourceComponent,
        canActivate: [authGuard]
      },
      {
        path: 'provider-resources/edit-provider-resource/:providerResourceId',
        component: EditProviderResourceComponent,
        canActivate: [authGuard]
      },
      {
        path: 'overrides',
        component: OverrideTemplatesComponent,
        canActivate: [authGuard]
      },
      {
        path: 'overrides/manage-overrides/:templateName',
        component: ManageOverridesComponent,
        canActivate: [authGuard]
      },
      {
        path: 'users',
        component: UsersComponent,
        canActivate: [authGuard]
      },
      {
        path: 'error',
        component: ErrorPageComponent,
        canActivate: [authGuard]
      },
      {
        path: '**',
        component: PageNotFoundComponent,
        canActivate: [authGuard]
      }
    ]
  }
];

import { AbstractControl, FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';

export const validateLocationAvailabilities: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
  const group = control as FormGroup;
  let hasValues = false;

  Object.keys(group.controls).forEach((key: string) => {
    const abstractControl = group.controls[key];

    if (abstractControl.get('startTime').value !== null || abstractControl.get('endTime').value !== null) {
      hasValues = true;
    }
  });

  if (!hasValues) return { required: true };

  return null;
};

import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ProgressSpinnerComponent } from 'src/app/shared/component/progress-spinner/progress-spinner.component';
import { DaysOfWeekPipe } from 'src/app/shared/pipes/days-of-week/days-of-week.pipe';
import { TimePipe } from 'src/app/shared/pipes/time/time.pipe';
import { environment } from 'src/environments/environment.development';
import { ProviderResourceLocationAvailability } from '../../models/provider-resource-location-availability';

@Component({
  selector: 'app-provider-resource-location-availabilities-grid',
  standalone: true,
  imports: [
    CommonModule,
    DaysOfWeekPipe,
    MatButtonModule,
    MatIconModule,
    MatMenuModule,
    MatPaginatorModule,
    MatSortModule,
    MatTableModule,
    MatTooltipModule,
    ProgressSpinnerComponent,
    TimePipe
  ],
  templateUrl: './provider-resource-location-availabilities-grid.component.html',
  styleUrl: './provider-resource-location-availabilities-grid.component.scss'
})
export class ProviderResourceLocationAvailabilitiesGridComponent implements AfterViewInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  currentPageSize = environment.pageSize;
  pageSizeOptions = [5, 10, 25, 50, 100];
  noDataMessage = environment.noDataMessage;

  dataSource: MatTableDataSource<ProviderResourceLocationAvailability>;

  @Input() set data(value: ProviderResourceLocationAvailability[] | null) {
    this.dataSource = new MatTableDataSource(value);
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }
  @Input() error: any | null = null;
  @Input() isLoading: boolean | null = false;
  @Output() editEvent = new EventEmitter<number>();

  displayedColumns: string[] = [
    'locationName',
    'daysOfWeek',
    'startTimeLocal',
    'endTimeLocal',
    'startDate',
    'endDate',
    'actions'
  ];

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }
}

<ng-container
  *ngrxLet="{
    appointmentTypeQuickPicks: appointmentTypeQuickPicks$,
    error: error$,
    usStates: usStates$,
    modifiedByUserId: modifiedByUserId$
  } as vm">
  <app-progress-spinner *ngIf="vm.usStates === null && vm.appointmentTypeQuickPicks.length === 0; else addLocationForm">
  </app-progress-spinner>

  <ng-template #addLocationForm>
    <div class="page-title">
      <h3 class="text-center flex-2">Add New Location</h3>
    </div>

    <app-add-location-form
      [appointmentTypeQuickPicks]="vm.appointmentTypeQuickPicks"
      [usStates]="vm.usStates"
      [saveError]="vm.error"
      [modifiedByUserId]="vm.modifiedByUserId"
      (addLocationEvent)="addLocation($event)"
      (closeAddLocationEvent)="cancel()">
    </app-add-location-form>
  </ng-template>
</ng-container>

import { createFeatureSelector, createSelector } from '@ngrx/store';
import { selectParams } from 'src/app/core/router/store/router.selectors';
import * as fromNonProviderResources from '../reducer/non-provider-resources.reducer';

export const nonProviderResourcesFeatureSelector =
  createFeatureSelector<fromNonProviderResources.NonProviderResourcesState>(
    fromNonProviderResources.nonProviderResourcesFeatureKey
  );

const { selectEntities, selectAll } = fromNonProviderResources.nonProviderResourcesAdapter.getSelectors();

export const selectNonProviderResourcesEntities = createSelector(nonProviderResourcesFeatureSelector, selectEntities);

export const selectNonProviderResources = createSelector(nonProviderResourcesFeatureSelector, selectAll);

export const selectError = createSelector(nonProviderResourcesFeatureSelector, state => state.baseState.error);

export const selectIsLoading = createSelector(nonProviderResourcesFeatureSelector, state => state.baseState.isLoading);

export const selectPageNumber = createSelector(
  nonProviderResourcesFeatureSelector,
  state => state.baseState.pageNumber
);

export const selectIsAtEndOfData = createSelector(
  nonProviderResourcesFeatureSelector,
  state => state.baseState.isAtEndOfData
);

export const selectSortDirection = createSelector(
  nonProviderResourcesFeatureSelector,
  state => state.baseState.sortDirection
);

export const selectSortBy = createSelector(nonProviderResourcesFeatureSelector, state => state.baseState.sortBy);

export const selectPageSize = createSelector(nonProviderResourcesFeatureSelector, state => state.baseState.pageSize);

export const selectTotalRecordCount = createSelector(
  nonProviderResourcesFeatureSelector,
  state => state.baseState.totalRecordCount
);

export const selectSearchTerm = createSelector(
  nonProviderResourcesFeatureSelector,
  state => state.baseState.searchTerm
);

export const selectIsActive = createSelector(nonProviderResourcesFeatureSelector, state => state.baseState.isActive);

export const selectNonProviderResourceTypes = createSelector(
  nonProviderResourcesFeatureSelector,
  state => state.nonProviderResourceTypes
);

export const selectNonProviderResource = createSelector(
  selectNonProviderResourcesEntities,
  selectParams,
  (nonProviderResources, { nonProviderResourceId }) => nonProviderResources[nonProviderResourceId]
);

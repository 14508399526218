import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UtilityService } from 'src/app/core/services/utility/utility.service';
import { Person } from 'src/app/provider-resources/models/person';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PersonsService {
  constructor(private utilityService: UtilityService) {}

  endpoint = environment.scheduleApiUrl + '/Persons';

  getNonProviderResources(): Observable<Person[]> {
    const url = this.endpoint + '/NonProviderResources';
    return this.utilityService.getRequest<Person[]>(url);
  }
}

import { createAction, props } from '@ngrx/store';
import { DataSort } from 'src/app/core/models/data-sort';
import { OverrideTemplate } from '../../models/override-template';
import { Resource } from '../../models/resource';

export const getOverrideTemplates = createAction('[Override Templates] Get Override Templates');

export const getOverrideTemplatesSuccess = createAction(
  '[Override Templates] Get Override Templates Success',
  props<{
    overrideTemplates: OverrideTemplate[];
    pageNumber: number;
    isAtEndOfData: boolean;
    totalRecordCount: number;
  }>()
);

export const getOverrideTemplatesFailure = createAction(
  '[Override Templates] Get Override Templates Failure',
  props<{ error: string[] }>()
);

export const getOverrideTemplatesSearch = createAction(
  '[Override Templates] Get Override Templates Search',
  props<{ searchTerm: any }>()
);

export const setPageNumber = createAction('[Override Templates] Set Page Number', props<{ pageNumber: number }>());

export const setPageSize = createAction('[Override Templates] Set Page Size', props<{ pageSize: number }>());

export const setSortDirection = createAction(
  '[Override Templates] Set Sort Direction',
  props<{ sortDirection: number }>()
);

export const setSortBy = createAction('[Override Templates] Set Sort By', props<{ sortBy: DataSort }>());

export const setIsActive = createAction('[Override Templates] Set Is Active', props<{ isActive: boolean }>());

export const navigateToAddOverrideTemplate = createAction('[Override Templates] Navigate to Add OverrideTemplate');

export const navigateToOverrideTemplatesGrid = createAction('[Override Templates] Navigate to OverrideTemplates Grid');

export const resetFilters = createAction('[Override Templates] Reset Filters');

export const openAddOverrideTemplateDialog = createAction('[Override Templates] Open Add Override Template Dialog');

export const selectNewTemplate = createAction(
  '[Override Templates] Select New Template',
  props<{ selectedId: number; overrideTemplateTypeId: number }>()
);

export const getResources = createAction(
  '[Override Templates] Get Resources',
  props<{ overrideTemplateTypeId: number }>()
);

export const getResourcesSuccess = createAction(
  '[Override Templates] Get Resources Success',
  props<{ resources: Resource[] | null }>()
);

export const getResourcesFailure = createAction('[Override Templates] Get Resources Failure', props<{ error: any }>());

export const getAllOverrideTemplates = createAction('[Override Templates] Get All Override Templates');

export const getAllOverrideTemplatesSuccess = createAction(
  '[Override Templates] Get All Override Templates Success',
  props<{
    overrideTemplates: OverrideTemplate[];
  }>()
);

export const getAllOverrideTemplatesFailure = createAction(
  '[Override Templates] Get All Override Templates Failure',
  props<{ error: string[] }>()
);

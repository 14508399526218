<div class="flex justify-center">
  <div class="mb-20 mat-elevation-z8">
    <table
      style="min-width: 750px"
      mat-table
      [dataSource]="dataSource"
      matSort
      matSortDisableClear
      (matSortChange)="onSort($event)"
      [matSortDirection]="matSortDirection"
      [matSortActive]="matSortActive"
      data-test="grdUsers">
      <ng-container matColumnDef="firstName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="fw-600">Name</th>
        <td mat-cell *matCellDef="let data" tabindex="0" [ngClass]="{ italic: !data.isActive }">
          <div class="flex">
            {{ data.firstName }} {{ data.lastName }}
            @if (getIsFutureDate(data.activateOn)) {
              <div class="italic ml-5">(active on {{ formatDate(data.activateOn) }})</div>
            }
          </div>
        </td>
      </ng-container>
      <ng-container matColumnDef="hostMnemonic">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="fw-600">Mnemonic</th>
        <td mat-cell *matCellDef="let data" tabindex="0" [ngClass]="{ italic: !data.isActive }">
          {{ data.hostMnemonic }}
        </td>
      </ng-container>
      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef class="fw-600">Status</th>
        <td mat-cell *matCellDef="let data" tabindex="0">
          <div class="w-100p flex justify-center">
            @if (data.isActive) {
              <div>Active</div>
            } @else {
              <div class="italic">Inactive</div>
            }
          </div>
        </td>
      </ng-container>
      <ng-container matColumnDef="edit">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let data" tabindex="0">
          <div class="fw-600 color-neutral text-center cursor" [matMenuTriggerFor]="menu">
            <mat-icon>more_vert</mat-icon>
          </div>
          <mat-menu #menu="matMenu" yPosition="above" xPosition="before">
            <button mat-menu-item (click)="updateUserEvent.emit(data.userId)">
              @if (data.isActive) {
                <p class="fw-600">Edit</p>
              } @else {
                <p class="fw-600">View</p>
              }
            </button>
          </mat-menu>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

      <tr class="mat-row" *matNoDataRow>
        <td *ngIf="!isLoading && error === null" class="mat-cell pl-20 pt-10" [attr.colspan]="displayedColumns.length">
          <p class="flex justify-center align-center mt-10 mb-10">No Users Found</p>
        </td>
        <td *ngIf="!isLoading && error !== null" class="mat-cell pl-20 pt-10" [attr.colspan]="displayedColumns.length">
          <p class="flex justify-center align-center mt-10 mb-10">{{ error }}</p>
        </td>
        <td *ngIf="isLoading" class="mat-cell" [attr.colspan]="displayedColumns.length">
          <div class="flex justify-center align-center mt-10 mb-10">
            <app-progress-spinner [diameter]="100"></app-progress-spinner>
          </div>
        </td>
      </tr>
    </table>
    <mat-paginator
      [disabled]="isLoading"
      [pageSizeOptions]="pageSizeOptions"
      [pageSize]="currentPageSize"
      [length]="paginatorLength"
      [pageIndex]="currentPage - 1"
      showFirstLastButtons
      (page)="onPage($event)"
      class="pt-10 pb-10 paginator"
      aria-label="Select page of data"
      data-test="pgnUser">
    </mat-paginator>
  </div>
</div>

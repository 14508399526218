import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Sort } from '@angular/material/sort';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { SortDirection } from 'src/app/core/enums/sort-direction';
import { DataSort } from 'src/app/core/models/data-sort';
import { overrideTemplateSort } from '../../data/override-templates-sort';
import { OverrideTemplate } from '../../models/override-template';
import {
  getOverrideTemplates,
  getOverrideTemplatesSearch,
  openAddOverrideTemplateDialog,
  resetFilters,
  setIsActive,
  setPageNumber,
  setPageSize,
  setSortBy,
  setSortDirection
} from '../../store/actions/override-templates.actions';
import * as selectors from '../../store/selectors/override-templates.selectors';
import { OverrideTemplatesFilterBarComponent } from '../../ui/override-templates-filter-bar/override-templates-filter-bar.component';
import { OverrideTemplatesGridComponent } from '../../ui/override-templates-grid/override-templates-grid.component';

@Component({
  selector: 'app-override-templates',
  standalone: true,
  imports: [OverrideTemplatesGridComponent, OverrideTemplatesFilterBarComponent, CommonModule],
  templateUrl: './override-templates.component.html',
  styleUrl: './override-templates.component.scss'
})
export class OverrideTemplatesComponent implements OnInit, OnDestroy {
  overrideTemplates$: Observable<OverrideTemplate[] | null>;
  isLoading$: Observable<boolean>;
  error$: Observable<any>;
  isAtEndOfData$: Observable<boolean>;
  sortDirection$: Observable<SortDirection>;
  sortBy$: Observable<DataSort>;
  totalRecordCount$: Observable<number>;
  currentPage$: Observable<number>;
  pageSize$: Observable<number>;
  searchTerm$: Observable<string>;
  isActive$: Observable<boolean | null>;
  isEditing: boolean = false;

  constructor(
    private store: Store,
    private router: Router
  ) {
    this.overrideTemplates$ = this.store.select(selectors.selectOverrideTemplates);
    this.isLoading$ = this.store.select(selectors.selectIsLoading);
    this.error$ = this.store.select(selectors.selectError);
    this.isAtEndOfData$ = this.store.select(selectors.selectIsAtEndOfData);
    this.sortDirection$ = this.store.select(selectors.selectSortDirection);
    this.sortBy$ = this.store.select(selectors.selectSortBy);
    this.totalRecordCount$ = this.store.select(selectors.selectTotalRecordCount);
    this.currentPage$ = this.store.select(selectors.selectPageNumber);
    this.pageSize$ = this.store.select(selectors.selectPageSize);
    this.searchTerm$ = this.store.select(selectors.selectSearchTerm);
    this.isActive$ = this.store.select(selectors.selectIsActive);
  }

  ngOnInit(): void {
    this.store.dispatch(getOverrideTemplates());
  }

  onPage(pageNumber: number): void {
    this.store.dispatch(setPageNumber({ pageNumber }));
  }

  onPageSize = (pageSize: number) => this.store.dispatch(setPageSize({ pageSize }));

  onSearch(searchTerm: string | null): void {
    this.store.dispatch(getOverrideTemplatesSearch({ searchTerm }));
  }

  onActiveFiltersChange(isActive: boolean): void {
    this.store.dispatch(setIsActive({ isActive }));
  }

  onSort(sort: Sort): void {
    this.store.dispatch(
      setSortDirection({
        sortDirection: sort.direction === 'desc' ? SortDirection.Descending : SortDirection.Ascending
      })
    );

    let dataSort: DataSort | undefined = overrideTemplateSort.find(f => f.value === sort.active);
    if (dataSort === undefined) {
      dataSort = overrideTemplateSort.find(f => f.isDefault);
      this.store.dispatch(setSortBy({ sortBy: dataSort! }));
    } else {
      this.store.dispatch(setSortBy({ sortBy: dataSort }));
    }
  }

  addOverrideTemplate() {
    // this.isEditing = true;
    this.store.dispatch(openAddOverrideTemplateDialog());
  }

  updateOverrideTemplate(overrideTemplateId: number) {
    console.log('edit template ' + overrideTemplateId);
    // this.isEditing = true;
    // this.router.navigate(['override-templates/update-override-template', overrideTemplateId]);
  }

  ngOnDestroy(): void {
    if (!this.isEditing) this.store.dispatch(resetFilters());
  }
}

import { createAction, props } from '@ngrx/store';
import { DataSort } from 'src/app/core/models/data-sort';
import { Availability } from 'src/app/shared/models/availability';
import { NonProviderResource } from '../../models/non-provider-resource';
import { NonProviderResourceType } from '../../models/non-provider-resource-type';
import { NonProviderResourceInactivationCheck } from '../../models/non-provider-resource.inactivation-check';

export const getNonProviderResources = createAction('[Non-Provider Resources] Get Non-Provider Resources');

export const getNonProviderResourcesSuccess = createAction(
  '[Non-Provider Resources] Get Non-Provider Resources Success',
  props<{
    nonProviderResources: NonProviderResource[];
    pageNumber: number;
    isAtEndOfData: boolean;
    totalRecordCount: number;
  }>()
);

export const getNonProviderResourcesFailure = createAction(
  '[Non-Provider Resources] Get Non-Provider Resources Failure',
  props<{ error: any }>()
);

export const getNonProviderResourcesSearch = createAction(
  '[Non-Provider Resources] Get Non-Provider Resources Search',
  props<{ searchTerm: any }>()
);

export const setPageNumber = createAction('[Non-Provider Resources] Set Page Number', props<{ pageNumber: number }>());

export const setPageSize = createAction('[Non-Provider Resources] Set Page Size', props<{ pageSize: number }>());

export const setSortDirection = createAction(
  '[Non-Provider Resources] Set Sort Direction',
  props<{ sortDirection: number }>()
);

export const setSortBy = createAction('[Non-Provider Resources] Set Sort By', props<{ sortBy: DataSort }>());

export const setIsActive = createAction('[Non-Provider Resources] Set Is Active', props<{ isActive: boolean }>());

export const navigateToAddNonProviderResource = createAction(
  '[Non-Provider Resources] Navigate to Add Non-Provider Resource'
);

export const navigateToNonProviderResourcesGrid = createAction(
  '[Non-Provider Resources] Navigate to Non-Provider Resources Grid'
);

export const resetFilters = createAction('[Non-Provider Resources] Reset Fiilters');

export const addNonProviderResource = createAction(
  '[Non-Provider Resources] Add Non-Provider Resource',
  props<{ nonProviderResource: NonProviderResource }>()
);

export const addNonProviderResourceSuccess = createAction('[Non-Provider Resources] Add Non-Provider Resource Success');

export const addNonProviderResourceFailure = createAction(
  '[Non-Provider Resources] Add Non-Provider Resource Failure',
  props<{ error: any }>()
);

export const getNonProviderResourceTypes = createAction('[Non-Provider Resources] Get Non-Provider Resource Types');

export const getNonProviderResourceTypesSuccess = createAction(
  '[Non-Provider Resources] Get Non-Provider Resource Types Success',
  props<{ nonProviderResourceTypes: NonProviderResourceType[] }>()
);

export const getNonProviderResourceTypesFailure = createAction(
  '[Non-Provider Resources] Get Non-Provider Resource Types Failure',
  props<{ error: any }>()
);

export const navigateToUpdateNonProviderResource = createAction(
  '[Non-Provider Resources] Navigate to Update Non-Provider Resource',
  props<{ nonProviderResourceId: number }>()
);

export const getNonProviderResourceById = createAction('[Non-Provider Resources] Get Non-Provider Resource By ID');

export const getNonProviderResourceByIdSuccess = createAction(
  '[Non-Provider Resources] Get Non-Provider Resource By ID Success',
  props<{ nonProviderResource: NonProviderResource }>()
);

export const getNonProviderResourceByIdFailure = createAction(
  '[Non-Provider Resources] Get Non-Provider Resource By ID Failure',
  props<{ error: any }>()
);

export const updateNonProviderResource = createAction(
  '[Non-Provider Resources] Update Non-Provider Resource',
  props<{ nonProviderResourceToUpdate: NonProviderResource }>()
);

export const updateNonProviderResourceSuccess = createAction(
  '[Non-Provider Resources] Update Non-Provider Resource Success'
);

export const updateNonProviderResourceFailure = createAction(
  '[Non-Provider Resources] Update Non-Provider Resource Failure',
  props<{ error: any }>()
);

export const getNonProviderResourceAvailabilitiesByNonProviderResourceId = createAction(
  '[Non-Provider Resources] Get Non-Provider Resource Appointment Types By Non-Provider Resource Id',
  props<{ nonProviderResourceId: number }>()
);

export const getNonProviderResourceAvailabilitiesByNonProviderResourceIdSuccess = createAction(
  '[Non-Provider Resources] Get Non-Provider Resource Availabilities By Non-Provider Resource Id Success',
  props<{
    nonProviderResourceId: number;
    nonProviderResourceAvailabilities: Availability[];
  }>()
);

export const getNonProviderResourceAvailabilitiesByNonProviderResourceIdFailure = createAction(
  '[Non-Provider Resources] Get Non-Provider Resource Availabilities By Non-Provider Resource Id Failure',
  props<{ error: string[] }>()
);

export const updateNonProviderResourceAvailabilities = createAction(
  '[Non-Provider Resources] Update Non-Provider Resource Availabilities',
  props<{
    nonProviderResourceId: number;
    nonProviderResourceAvailabilities: { dayOfWeekAvailabilities: Availability[]; isDirty: boolean };
  }>()
);

export const updateNonProviderResourceAvailabilitiesSuccess = createAction(
  '[Non-Provider Resources] Update Non-Provider Resource Availabilities Success'
);

export const updateNonProviderResourceAvailabilitiesFailure = createAction(
  '[Non-Provider Resources] Update Non-Provider Resource Availabilities Failure',
  props<{ error: string[] }>()
);

export const getInactivationCheck = createAction(
  '[Non-Provider Resources] Get Inactivation Check',
  props<{ nonProviderResource: NonProviderResource }>()
);

export const getInactivationCheckSuccess = createAction(
  '[Non-Provider Resources] Get Inactivation Check Success',
  props<{
    nonProviderResourceInactivationCheck: NonProviderResourceInactivationCheck;
    nonProviderResource: NonProviderResource;
  }>()
);

export const getInactivationCheckFailure = createAction(
  '[Non-Provider Resources] Get Inactivation Check Failure',
  props<{ error: any }>()
);

export const cancelUpdate = createAction('[Non-Provider Resources] Cancel Update');

import { daysOfWeek } from 'src/app/shared/data/days-of-week';
import { locationColors } from './location-colors';

const maxLengthName = 50;
const maxLengthShortName = 20;
const maxLengthAddress = 50;
const maxLengthCity = 30;
const maxLengthZip = 10;
const maxLengthContact = 20;
const today = new Date();
const minInactiveDate = new Date(today.setDate(today.getDate() + 30));

export const locationLengthValidators = {
  maxLengthAddress,
  maxLengthCity,
  maxLengthContact,
  maxLengthName,
  maxLengthShortName,
  maxLengthZip
};

export const locationSettings = {
  today: new Date(),
  minInactiveDate,
  interval: 30,
  colors: locationColors,
  daysOfWeek: daysOfWeek
};

import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormsModule, ReactiveFormsModule, ValidationErrors, Validators } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { MatNativeDateModule, MatOption, provideNativeDateAdapter } from '@angular/material/core';
import { MatDatepicker, MatDatepickerInput, MatDatepickerToggle } from '@angular/material/datepicker';
import { MatError, MatFormField, MatLabel } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { DxDateBoxModule, DxValidatorModule } from 'devextreme-angular';
import moment from 'moment';
import { NgxMaskDirective, provideNgxMask } from 'ngx-mask';
import { MultiselectAutocompleteComponent } from 'src/app/shared/component/multiselect-autocomplete/multiselect-autocomplete.component';
import { ProgressSpinnerOverlayComponent } from 'src/app/shared/component/progress-spinner-overlay/progress-spinner-overlay.component';
import { ProgressSpinnerComponent } from 'src/app/shared/component/progress-spinner/progress-spinner.component';
import { isTimeValid } from 'src/app/shared/functions/availability.functions';
import { MultiSelectAutoCompleteItem } from 'src/app/shared/models/multiselect-autocomplete-item';
import { UsState } from 'src/app/shared/models/state';
import { locationLengthValidators, locationSettings } from '../../data/location-form';
import { createLocationForm, createLocationToSave } from '../../functions/location-form.functions';
import { ApptLocation } from '../../models/location';

@Component({
  selector: 'app-add-location-form',
  standalone: true,
  imports: [
    CommonModule,
    DxDateBoxModule,
    DxValidatorModule,
    FormsModule,
    MatButton,
    MatDatepicker,
    MatDatepickerInput,
    MatDatepickerToggle,
    MatError,
    MatFormField,
    MatInputModule,
    MatLabel,
    NgxMaskDirective,
    MatNativeDateModule,
    MatOption,
    MatSelectModule,
    MultiselectAutocompleteComponent,
    ProgressSpinnerComponent,
    ProgressSpinnerOverlayComponent,
    ReactiveFormsModule
  ],
  providers: [provideNgxMask(), provideNativeDateAdapter()],
  templateUrl: './add-location-form.component.html',
  styleUrl: './add-location-form.component.scss'
})
export class AddLocationFormComponent {
  form = createLocationForm(true);
  fieldValidators = locationLengthValidators;
  locationSettings = locationSettings;
  isSaving: boolean = false;
  error: string[] | null = null;
  apptTypeMultiSelectItems: MultiSelectAutoCompleteItem[];
  location: ApptLocation | null = null;
  selectedAppointmentTypeQuickPicks: MultiSelectAutoCompleteItem[] | null = null;

  @Input() set saveError(value: any) {
    if (value !== null && value !== undefined) {
      this.isSaving = false;

      if (value.filter((f: string) => f.toLowerCase().includes('location name')).length > 0) {
        this.form.controls['locationProfile'].controls['locationName'].setErrors({ duplicate: true });
      } else if (value.filter((f: string) => f.toLowerCase().includes('location short name')).length > 0) {
        this.form.controls['locationProfile'].controls['locationShortName'].setErrors({ duplicate: true });
      } else {
        this.error = value;
      }
    }
  }
  @Input() usStates: UsState[] | null;
  @Input() appointmentTypeQuickPicks: MultiSelectAutoCompleteItem[] | null = null;
  @Input() modifiedByUserId: number;

  @Output() closeAddLocationEvent = new EventEmitter();
  @Output() addLocationEvent = new EventEmitter<ApptLocation>();

  constructor() {
    this.form.controls['availability'].markAsDirty();
    this.form.controls['locationProfile'].controls['activeDates'].get('startDate')?.patchValue(new Date());
    this.form.controls['locationProfile'].controls['activeDates'].get('startDate')?.addValidators(Validators.required);
  }

  onMultiSelectChange = (event: any) => {
    this.form.controls['appointmentTypeQuickPicks'].patchValue(event.data);
    this.form.controls['appointmentTypeQuickPicks'].markAsDirty();
  };

  isValid = (errors: ValidationErrors): boolean => isTimeValid(errors);

  minInactiveDate() {
    const startDate = this.form.controls['locationProfile'].controls['activeDates'].get('startDate')?.value;
    if (startDate === null) {
      return this.locationSettings.minInactiveDate;
    } else {
      if (startDate < this.locationSettings.minInactiveDate) {
        return this.locationSettings.minInactiveDate;
      } else {
        return moment(startDate).add(1, 'days').toDate();
      }
    }
  }

  save() {
    this.isSaving = true;

    if (this.form.valid) {
      const location = createLocationToSave(this.form, null, null, this.modifiedByUserId);

      this.addLocationEvent.emit(location);
    } else {
      this.isSaving = false;
    }
  }
}

import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, ValidationErrors, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatNativeDateModule, provideNativeDateAdapter } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { DxDateBoxModule, DxValidatorModule } from 'devextreme-angular';
import moment from 'moment';
import { Color } from 'src/app/core/models/colors';
import { locationColors } from 'src/app/locations/data/location-colors';
import { ApptLocation } from 'src/app/locations/models/location';
import { ProgressSpinnerOverlayComponent } from 'src/app/shared/component/progress-spinner-overlay/progress-spinner-overlay.component';
import { ProgressSpinnerComponent } from 'src/app/shared/component/progress-spinner/progress-spinner.component';
import {
  createAvailabilityFormGroup,
  getAvailabilities,
  isTimeValid
} from 'src/app/shared/functions/availability.functions';
import { formatDateWithUtcTimeOffset } from 'src/app/shared/functions/date.functions';
import { dateRangeValidator } from 'src/app/shared/functions/validators/date-range.validator';
import {
  nonProviderResourceLengthValidators,
  nonProviderResourceSettings
} from '../../data/non-provider-resource-form';
import { NonProviderResource } from '../../models/non-provider-resource';
import { NonProviderResourceType } from '../../models/non-provider-resource-type';

@Component({
  selector: 'app-add-non-provider-resource-form',
  standalone: true,
  imports: [
    CommonModule,
    DxDateBoxModule,
    DxValidatorModule,
    FormsModule,
    MatButtonModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatInputModule,
    MatNativeDateModule,
    MatSelectModule,
    ProgressSpinnerComponent,
    ProgressSpinnerOverlayComponent,
    ReactiveFormsModule
  ],
  providers: [provideNativeDateAdapter()],
  templateUrl: './add-non-provider-resource-form.component.html',
  styleUrl: './add-non-provider-resource-form.component.scss'
})
export class AddNonProviderResourceFormComponent {
  fieldValidators = nonProviderResourceLengthValidators;
  resourceSettings = nonProviderResourceSettings;

  form = new FormGroup({
    profile: new FormGroup({
      name: new FormControl<string | null>(null, [
        Validators.required,
        Validators.maxLength(this.fieldValidators.maxLengthName)
      ]),
      color: new FormControl<Color | null>(null, Validators.required),
      type: new FormControl<number | null>(null, Validators.required),
      location: new FormControl<number | null>(null, Validators.required),
      activeDates: new FormGroup(
        {
          endDate: new FormControl<Date | null>(null),
          startDate: new FormControl<Date | null>(null, Validators.required)
        },
        { validators: dateRangeValidator }
      )
    }),
    availability: createAvailabilityFormGroup(true)
  });

  minDateAllowed = new Date();
  colors = locationColors;
  resource: NonProviderResource;
  isSaving: boolean = false;
  error: string[] | null = null;

  @Input() set saveError(value: any) {
    if (value !== null && value !== undefined) {
      this.isSaving = false;

      if (value.filter((f: string) => f.toLowerCase().includes('resource name')).length > 0) {
        this.form.controls['profile'].controls['name'].setErrors({ duplicate: true });
      } else {
        this.error = value;
      }
    }
  }

  @Input() locations: ApptLocation[] | null;
  @Input() modifiedByUserId: number;
  @Input() nonProviderResourceTypes: NonProviderResourceType[] | null;
  @Output() closeAddNonProviderResourceEvent = new EventEmitter();
  @Output() saveNonProviderResourceEvent = new EventEmitter<NonProviderResource>();

  constructor() {
    this.form.controls['availability'].markAsDirty();
    this.form.controls['profile'].controls['location'].valueChanges.subscribe(() => {
      this.form.controls['profile'].controls['name'].setErrors({ duplicate: null });
      this.form.controls['profile'].controls['name'].updateValueAndValidity({ emitEvent: false });
    });
    this.form.controls['profile'].controls['activeDates'].get('startDate')?.setValue(new Date());
  }

  isValid = (errors: ValidationErrors): boolean => isTimeValid(errors);

  minInactiveDate() {
    const startDate = this.form.controls['profile'].controls['activeDates'].get('startDate')?.value;

    if (startDate !== null) {
      return moment(startDate).add(1, 'days').toDate();
    } else {
      return moment(this.minDateAllowed).add(1, 'days').toDate();
    }
  }

  save() {
    this.isSaving = true;

    if (this.form.valid) {
      const resource: NonProviderResource = {
        name: this.form.controls['profile'].controls['name'].value,
        hexColorCode: this.form.controls['profile'].controls['color'].value.hexCode,
        nonProviderResourceTypeLookupId: this.form.controls['profile'].controls['type'].value,
        locationId: this.form.controls['profile'].controls['location'].value,
        deactivateOn: formatDateWithUtcTimeOffset(
          this.form.controls['profile'].controls['activeDates'].get('endDate')?.value
        ),
        activateOn: formatDateWithUtcTimeOffset(
          this.form.controls['profile'].controls['activeDates'].get('startDate')?.value
        ),
        modifiedByUserId: this.modifiedByUserId,
        nonProviderResourceAvailabilities: {
          dayOfWeekAvailabilities: getAvailabilities(this.form.controls['availability']),
          isDirty: this.form.controls['availability'].dirty
        }
      };

      this.saveNonProviderResourceEvent.emit(resource);
    } else {
      this.isSaving = false;
    }
  }
}

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UtilityService } from 'src/app/core/services/utility/utility.service';
import { environment } from 'src/environments/environment';
import { ProviderResourceLocationAvailability } from '../../models/provider-resource-location-availability';

@Injectable({
  providedIn: 'root'
})
export class ProviderResourceLocationAvailabilitiesService {
  endpoint = environment.scheduleApiUrl + '/ProviderResourceLocationAvailabilities';

  constructor(private utilityService: UtilityService) {}

  getProviderResourceLocationAvailabilitiesByProviderResourceId(
    providerResourceId: number
  ): Observable<ProviderResourceLocationAvailability[]> {
    const url = `${this.endpoint}?providerResourceId=${providerResourceId}`;
    return this.utilityService.getRequest<ProviderResourceLocationAvailability[]>(url);
  }
}

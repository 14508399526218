import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export const dateRangeValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
  const startDate = control.get('startDate')?.value;
  const endDate = control.get('endDate')?.value;

  if (
    (control.get('startDate').hasError('required') || control.get('startDate').hasError('matDatepickerMin')) &&
    !control.get('endDate').hasError('matDatepickerMin')
  ) {
    control.get('endDate')?.setErrors(null);
  }

  if (
    control.get('endDate').hasError('matDatepickerMin') &&
    !control.get('startDate').hasError('required') &&
    !control.get('startDate').hasError('matDatepickerMin')
  ) {
    control.get('startDate')?.setErrors(null);
  }

  if (
    !control.get('startDate').hasError('required') &&
    !control.get('startDate').hasError('matDatepickerMin') &&
    !control.get('endDate').hasError('matDatepickerMin')
  ) {
    if (startDate === null || endDate === null || startDate < endDate) {
      control.get('startDate')?.setErrors(null);
      control.get('endDate')?.setErrors(null);
      return null;
    } else {
      control.get('endDate')?.setErrors({ invalidDate: true });
      return { invalidRange: true };
    }
  } else {
    return null;
  }
};

<div class="flex gap-12 align-center justify-between mb-20">
  <div class="flex gap-12 align-center flex-2">
    <mat-form-field class="flex-2" appearance="outline" floatLabel="always" subscriptSizing="dynamic">
      <mat-label>Provider Search</mat-label>
      <input
        matInput
        type="text"
        [(ngModel)]="searchTerm"
        (ngModelChange)="textChange($event)"
        data-test="iptSearch"
        placeholder="Enter Name" />
      @if (searchTerm) {
        <button id="clearBtn" matSuffix mat-icon-button aria-label="Clear" (click)="clear()" data-test="btnClearSearch">
          <mat-icon>close</mat-icon>
        </button>
      }
    </mat-form-field>

    <button
      id="filtersBtn"
      class="btn primary-button fw-600"
      mat-stroked-button
      type="button"
      [matMenuTriggerFor]="menu"
      data-testid="filterMenuBtn">
      <mat-icon>tune</mat-icon> {{ filterCount === 0 ? 'Filters' : 'Filters (' + filterCount + ')' }}
    </button>
    <mat-menu #menu="matMenu" class="mt-10 ml-10" id="wrapper">
      <div class="w-260x" (click)="$event.stopPropagation()" (keydown)="$event.stopPropagation()" tabindex="{0}">
        <div id="menuHeader" class="flex justify-between fs-16 fw-700 ml-15 mr-15 mt-10 mb-10">
          <p>Filter by</p>
          <p
            id="resetFilters"
            class="cursor"
            (click)="resetFilters()"
            (keydown)="resetFilters()"
            role="button"
            tabindex="{0}">
            Reset filters
          </p>
        </div>
        <mat-divider class="mb-5 mt-5"></mat-divider>
        <div id="filters" class="ml-15 mr-15 mt-8 mb-20">
          <div id="menuSectionHeader" class="fs-16">Status</div>
          <ul>
            @for (filter of activeFilterOptions; track filter.name) {
              <li>
                <mat-checkbox
                  color="primary"
                  [checked]="filter.selected"
                  (change)="filterChange($event.checked, filter.name)"
                  >{{ filter.name }}</mat-checkbox
                >
              </li>
            }
          </ul>
        </div>
      </div>
    </mat-menu>
  </div>

  <div class="flex-1 flex justify-end">
    <button
      id="add-type-btn"
      class="fs-14 btn primary-solid-button"
      mat-raised-button
      color="primary"
      type="button"
      data-test="btnAddProvider"
      (click)="addProviderEvent.emit()">
      Add Provider
    </button>
  </div>
</div>
